import React from "react";
import {Pagination} from "react-bootstrap";

export interface PagerProps {
    disabled: boolean,
    pageCount: number,
    currentPage: number,
    onChange: any,
}

export const Pager = function (props: PagerProps) {
    const pages = [];

    const goToPage = function (idx: number) {
        if(props.onChange) props.onChange(idx);
    }

    if(props.pageCount < 2) {
        return <></>;
    }

    if(props.pageCount > 20) {
        if(props.currentPage > 9) {
            pages.push(<Pagination.First onClick={() => goToPage(0) } disabled={props.disabled} />)
            pages.push(<Pagination.Ellipsis onClick={()=>goToPage(props.currentPage - 10)} disabled={props.disabled} />)
        }

        if(props.currentPage > 0) pages.push(<Pagination.Prev onClick={()=>goToPage(props.currentPage-1)} disabled={props.disabled} /> );

        const ofs = Math.floor(props.currentPage / 10) * 10 ;
        for(let i = ofs; i < ofs+10; i++) {
            pages.push(<Pagination.Item onClick={()=>goToPage(i)} active={i === props.currentPage} disabled={props.disabled}>{i+1}</Pagination.Item>)
        }

        if(props.currentPage+1 < props.pageCount) pages.push(<Pagination.Next onClick={()=>goToPage(props.currentPage+1)} disabled={props.disabled} /> );

        if(props.currentPage < props.pageCount-10) {
            pages.push(<Pagination.Ellipsis onClick={()=>goToPage(props.currentPage + 10)} disabled={props.disabled} />)
            pages.push(<Pagination.Last onClick={() => goToPage(props.pageCount - 1)} disabled={props.disabled}/>)
        }
    } else {
        if(props.currentPage > 0) pages.push(<Pagination.Prev onClick={()=>goToPage(props.currentPage-1)} disabled={props.disabled} /> );
        for(let i = 0; i < props.pageCount; i++) {
            pages.push(<Pagination.Item onClick={()=>goToPage(i)} active={i === props.currentPage} disabled={props.disabled}>{i+1}</Pagination.Item>);
        }
        if(props.currentPage+1 < props.pageCount) pages.push(<Pagination.Next onClick={()=>goToPage(props.currentPage+1)} disabled={props.disabled} /> );
    }


    return (<Pagination className={"justify-content-center"} >{pages}</Pagination>);
}
