import React from "react";
import {BaseReportInfo, PropertyValue} from "../../types";
import {TimePickerComponent} from "./TimePickerComponent";
import {ReadOnlyDatePickerComponent} from "./ReadOnlyDatePickerComponent";
import moment from "moment";
import {mapToObject} from "../../common";

export interface ReportBaseInfoComponentProps {
    reportInfo: BaseReportInfo;
    shifts: PropertyValue[];
    addMiddleShift?: boolean;
    hideManCount?: boolean;
    onChange: any;
}

interface ReportBaseInfoComponentState {
    formValues: Map<string, any>;
    showFromPicker: boolean;
    showToPicker: boolean;
}

export class ReportBaseInfoComponent extends React.Component<ReportBaseInfoComponentProps, ReportBaseInfoComponentState> {
    constructor(props: any) {
        super(props);

        this.state = {
            formValues: new Map<string, any>(),
            showFromPicker: false,
            showToPicker: false,
        }

        this.state.formValues.set('report_date', this.props.reportInfo?.report_date);
        this.state.formValues.set('shift_id', this.props.reportInfo?.shift_id);
        this.state.formValues.set('from_time', this.props.reportInfo?.from_time);
        this.state.formValues.set('to_time', this.props.reportInfo?.to_time);
        this.state.formValues.set('man_cnt', this.props.reportInfo?.man_cnt);

        this.handleFormChange = this.handleFormChange.bind(this);
        this.handleFormChange(null);
    }

    handleFormChange(event: any) {
        const fv = this.state.formValues;
        if (event) {
            const target = event.target;
            const value = target.type === 'checkbox' ? target.checked : target.value;
            const name = target.name;

            fv.set(name, value);
            this.setState({formValues: fv});
        }
        this.props.onChange(mapToObject(fv));
    }

    render() {
        return (
            <form className="form-inline mb-3">
                <div className={"row w-100 mr-0"}>
                    <div className={"col-3  readonly-date-picker"}>
                        <label className={"justify-content-start"}>Отчет за дата:</label>

                        <ReadOnlyDatePickerComponent
                            value={this.state.formValues.get("report_date")}
                            onChange={
                                (newDate: any) => {
                                    const _date = (moment(newDate).format('DD.MM.YYYY'));
                                    this.setState({formValues: this.state.formValues.set("report_date", _date)})
                                    this.handleFormChange(null);
                                }
                            }
                        />
                    </div>
                    <div className={"col-3"}>
                        <label className={"justify-content-start"}>Работна смяна:</label>
                        <select className="form-control w-100" name="shift_id" required
                                value={this.state.formValues?.get('shift_id')}
                                onChange={this.handleFormChange}>
                            <option value="-1">-- Изберете --</option>
                            {
                                this.props.shifts.map(
                                    (i: PropertyValue, idx: number) =>
                                        <option value={i.id} key={idx}>{i.value}</option>
                                )
                            }
                            {
                                this.props.addMiddleShift &&
                                <option value="99">МЕЖДИННА СМЯНА</option>
                            }
                        </select>
                    </div>
                    <div className={"col-4 row"}>
                        {
                            this.state.formValues.get("shift_id") == '99' &&
                            <>
                                <div className="col-6">
                                    <label className="form-label justify-content-start w-100 text-nowrap">
                                        Начало:</label>
                                    <input className="form-control w-100" type="text" name="from_time" readOnly={true}
                                           required
                                           value={this.state.formValues?.get('from_time')}
                                           onChange={this.handleFormChange}
                                           onClick={() => this.setState({showFromPicker: !this.state.showFromPicker, showToPicker: false})}
                                    />
                                    {
                                        this.state.showFromPicker && <TimePickerComponent
                                            onSelect={(time: string) => this.setState({showFromPicker: false, formValues: this.state.formValues.set('from_time', time)})}
                                            value={this.state.formValues?.get('from_time')}/>
                                    }
                                </div>
                                <div className={"col-6"}>
                                    <label className="form-label justify-content-start w-100 text-nowrap">
                                        Край:</label>
                                    <input className="form-control w-100" type="text" name="to_time" readOnly={true}
                                           required
                                           value={this.state.formValues?.get('to_time')}
                                           onChange={this.handleFormChange}
                                           onClick={() => this.setState({showToPicker: !this.state.showToPicker, showFromPicker: false})}
                                    />
                                    {
                                        this.state.showToPicker && <TimePickerComponent
                                            onSelect={(time: string) => this.setState({showToPicker: false, formValues: this.state.formValues.set('to_time', time)})}
                                            value={this.state.formValues?.get('to_time')}/>
                                    }
                                </div>
                            </>
                        }
                    </div>
                    <div className={"col-2"}>
                        {
                            !this.props.hideManCount &&
                            <>
                                <label className="form-label justify-content-start w-100 text-nowrap">
                                    Брой служители:</label>
                                <input className="form-control" type="number" name="man_cnt"
                                       required
                                       value={this.state.formValues?.get('man_cnt')}
                                       onChange={this.handleFormChange}
                                />
                            </>
                        }
                    </div>
                </div>
            </form>
        )
    }
}
