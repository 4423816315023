import React from "react";
import {API_CALL} from "../../types";
import {ReportsPageProps} from "../../pages/ReportsPage";
import {API} from "../../api";
import {isLoading, isSuccess, mapToObject} from "../../common";
import moment, {Moment} from "moment";
import {BricksProducedChart} from "./BricksProducedChart";
import {BricksWasteChart} from "./BricksWasteChart";


interface BricksWasteReportPageState {
    loading: boolean,
    periodFrom: string,
    periodTo: string,
    shiftId?: number,
    data: any | null
}


export class BricksWasteReportPage extends React.Component<ReportsPageProps, BricksWasteReportPageState> {

    constructor(props: ReportsPageProps) {
        super(props);
        this.state = {
            periodFrom: props.periodFrom,
            periodTo: props.periodTo,
            shiftId: props.shiftId,
            loading: false,
            data: null
        }

        this.loadData = this.loadData.bind(this);
        this.processData = this.processData.bind(this);
    }

    async componentDidMount() {
        this.loadData();
    }

    loadData() {
        this.setState({data: null});

        API.calcBricksWasteReport(
            (apiCall: API_CALL) => {
                this.setState({loading: isLoading(apiCall)});

                if (isSuccess(apiCall)) {
                    console.log(apiCall.data.data);
                    this.processData(apiCall.data.data.report);
                }
            }, {
                periodFrom: this.props.periodFrom,
                periodTo: this.props.periodTo,
                shift: this.props.shiftId,
            }
        )
    }

    processData(data: any) {
        this.setState({data: data});
    }

    componentDidUpdate(prevProps: Readonly<ReportsPageProps>, prevState: Readonly<BricksWasteReportPageState>, snapshot?: any) {
        if (
            (this.state.periodFrom !== this.props.periodFrom) ||
            (this.state.periodTo !== this.props.periodTo) ||
            (this.state.shiftId !== this.props.shiftId)
        ) {
            this.setState({
                periodFrom: this.props.periodFrom,
                periodTo: this.props.periodTo,
                shiftId: this.props.shiftId
            });
            this.loadData();
        }
    }

    render() {
        return (
            <>
                <div className="row">
                    <div className="col">
                        {
                            this.state.loading &&
                            <div className={"m-3"}>Зареждам данните ...</div>
                        }
                    </div>
                </div>

                {
                    this.state.data &&
                    <>
                        <BricksWasteChart   chartFor={'extruder'}
                                            title={"Брой тухли брак в <b>Екструдер</b>"}
                                            periodFrom={this.state.periodFrom}
                                            periodTo={this.state.periodTo}
                                            shiftId={this.state.shiftId}
                                            data={this.state.data.extruder}/>

                        <BricksWasteChart   chartFor={'ordering'}
                                            title={"Брой тухли брак по причини в <b>Редене</b>"}
                                            periodFrom={this.state.periodFrom}
                                            periodTo={this.state.periodTo}
                                            shiftId={this.state.shiftId}
                                            data={this.state.data.ordering}/>

                        <BricksWasteChart   chartFor={'unloading'}
                                            title={"Брой тухли брак по причини в <b>Разтоварване</b>"}
                                            periodFrom={this.state.periodFrom}
                                            periodTo={this.state.periodTo}
                                            shiftId={this.state.shiftId}
                                            data={this.state.data.unloading}/>
                    </>

                }

            </>
        );
    }

}
