import React, {useState} from "react";
import {City, Client} from "../../types";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

export interface ClientsListProps {
    filter: any,
    loading: boolean;
    clients: Client[];
    onEditClient: (client: Client) => any;
    onViewClientAccount: (client: Client) => any;
    onRemoveClient: (client: Client) => any;
    onSelect?: (client: Client, city: City) => any;
    onApplyFilter: (filter: Map<string, any>) => any;
}

export function ClientsList(props: ClientsListProps) {
    const [formValues, setFormValues] = useState(props.filter);

    const handleFormChange = function (event: any) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        formValues.set(name, value);
        setFormValues(new Map<string, any>(formValues));
    }

    const clearFilter = function () {
        formValues.set('name', '');
        formValues.set('cities', '');
        setFormValues(new Map<string, any>(formValues));
        props.onApplyFilter(formValues);
    }

    const renderData = function () {

        const filter = <tr className={"table-filter-row"}>
            <td>
                <input className={"w-100"} name={"name"} value={formValues.get("name")} onChange={handleFormChange}/>
            </td>
            <td>
                <input className={"w-100"} name={"cities"} value={formValues.get("cities")} onChange={handleFormChange}/>
            </td>
            <td colSpan={3}>

            </td>
            <td className={"text-right"}>
                <button type={"button"} className={"btn btn-secondary btn-sm"} onClick={() => {
                    props.onApplyFilter(formValues)
                }}>
                    <FontAwesomeIcon icon={"search"}/>
                </button>
                &nbsp;
                <button type={"button"} className={"btn btn-secondary btn-sm"} onClick={() => clearFilter()}>
                    <FontAwesomeIcon icon={"times"}/>
                </button>
            </td>
        </tr>;

        if (props.clients.length == 0) return [filter].concat([<tr>
            <td colSpan={5}><strong>Няма намерени клиенти.</strong></td>
        </tr>]);

        return [filter].concat(props.clients.map(
            item => <tr>
                <td>{item.name}</td>
                <td>
                    <ul className={"nav nav-pills nav-stacked"}>
                        {item.cities.map((i, idx) => <li key={idx}>
                            <a className={"btn btn-outline-secondary btn-sm"}
                               onClick={() => {
                                   if (props.onSelect) props.onSelect(item, i);
                               }}>
                                {i.city} ({i.phone})
                            </a>
                        </li>)}
                    </ul>
                </td>
                <td>
                    <div className={"p-1 w-100 text-right text-light mr-1 " + (item.balance_bank < 0 ? "bg-danger" : "bg-success")}>
                        {item.balance_bank} лв
                    </div>
                </td>
                <td>
                    <div className={"p-1 w-100 text-right text-light mr-1 " + (item.balance_cash < 0 ? "bg-danger" : "bg-success")}>
                        {item.balance_cash} лв
                    </div>
                </td>
                <td>
                    <div className={"p-1 w-100 text-right text-light mr-1 " + (item.balance_palet < 0 ? "bg-danger" : "bg-success")}>
                        {item.balance_palet} бр
                    </div>
                </td>
                <td className={"text-right"}>
                    <span className="btn btn-sm btn-primary btn-light" onClick={() => props.onViewClientAccount(item)}><FontAwesomeIcon icon="hand-holding-usd"/></span> &nbsp;
                    <span className="btn btn-sm btn-primary btn-light" onClick={() => props.onEditClient(item)}><FontAwesomeIcon icon="pen"/></span> &nbsp;
                    <span className="btn btn-sm btn-primary btn-light" onClick={() => props.onRemoveClient(item)}><FontAwesomeIcon icon="trash-alt"/></span>
                </td>
            </tr>
        ));
    }

    return (
        <table className={"table table-sm table-hover table-no-padding"}>
            <thead>
            <tr className={"bg-secondary text-light"}>
                <th style={{minWidth: '8em'}}>Клиент</th>
                <th>Град</th>
                <th className={"w-120px text-right"}>Баланс<br/>БАНКА</th>
                <th className={"w-120px text-right"}>Баланс<br/>КЕШ</th>
                <th className={"w-120px text-right"}>Баланс<br/>ПАЛЕТИ</th>
                <th className={"w-180px"}></th>
            </tr>
            </thead>
            <tbody>
            {
                props.loading ?
                    <tr>
                        <td colSpan={5}>
                            <div className="spinner-border" role="status">
                                <span className="sr-only">Loading...</span>
                            </div>
                        </td>
                    </tr>
                    :
                    renderData()
            }
            </tbody>
        </table>
    );
}
