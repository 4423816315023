import {AccountOperation, OPERATION_TYPE} from "../../types";
import React, {useState} from "react";
import {ReadOnlyDatePickerComponent} from "../common/ReadOnlyDatePickerComponent";
import moment from "moment";

export interface EditAccountOperationProps {
    operation: AccountOperation;
    onSave: (operation: AccountOperation) => any;
    onRemove: () => any;
    onCancel: () => any;
}

export function EditAccountOperation(props: EditAccountOperationProps) {
    const initMap = function () {
        const m = new Map<string, any>();
        m.set('sales_id', props.operation.sales_id);
        m.set('timestamp', props.operation.timestamp);
        m.set('description', props.operation.description);
        m.set('amount', props.operation.amount);
        return m;
    }
    const [formValues, setFormValues] = useState(initMap());

    const saveItem = function () {
        props.onSave(
            {
                id: props.operation.id,
                sales_id: props.operation.sales_id,
                timestamp: formValues.get('timestamp'),
                account_type: props.operation.account_type,
                operation: formValues.get('amount') > 0 ? OPERATION_TYPE.DEPOSIT : OPERATION_TYPE.WITHDRAW,
                description: formValues.get('description'),
                amount: Math.abs(formValues.get('amount')),
                phone: formValues.get('phone')
            } as AccountOperation
        );
    }

    const removeItem = function () {
        props.onRemove();
    }

    const cancelItem = function () {
        props.onCancel();
    }

    const handleFormChange = function (event: any) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        formValues.set(name, value);
        setFormValues(new Map<string, any>(formValues));
    }

    return (
        <>
            <div className="row mb-3">
                <div className="col text-nowrap readonly-date-picker">
                    <label>Дата:</label>
                    <ReadOnlyDatePickerComponent
                        classes={"ml-0 pl-0"}
                        value={formValues.get('timestamp')}
                        onChange={(date: Date) => {
                            formValues.set('timestamp', moment(date).format('DD.MM.YYYY'));
                            setFormValues(new Map<string, any>(formValues));
                            console.log(formValues);
                        }}
                    />
                </div>
            </div>
            <div className="row mb-3">
                <div className="col">
                    <label>Описание:</label>
                    <input className="form-control" type="text" name="description" required
                           value={formValues.get('description')}
                           onChange={handleFormChange}/>
                </div>
            </div>
            <div className="row mb-3">
                <div className="col">
                    <label>Сума:</label>
                    <input className="form-control" type="text" name="amount" required
                           onFocus={(event) => event.target.select()}
                           value={formValues.get('amount')}
                           onChange={handleFormChange}/>
                </div>
            </div>
            <div className="row mb-3">
                {
                    props.operation.sales_id ?
                        <div className="col text-right">
                            <button type={"button"} className={"btn btn-danger"} onClick={cancelItem}>Затвори</button>
                            &nbsp;
                        </div>
                        :
                        <>
                            <div className="col-auto">
                                <button type={"button"} className={"btn btn-primary"} onClick={saveItem}>Запази</button>
                                &nbsp;
                                <button type={"button"} className={"btn btn-secondary"} onClick={cancelItem}>Откажи</button>
                            </div>
                            <div className="col text-right">
                                <button type={"button"} className={"btn btn-danger"} onClick={removeItem}>Изтрий</button>
                                &nbsp;
                            </div>
                        </>
                }
            </div>
        </>
    )
}
