import React from "react";
import {$enum} from "ts-enum-util";
import {API_CALL, PropertyValue, REPAIR_TYPE} from "../../types";
import {BlockTitleComponent} from "../common/BlockTitleComponent";
import {ReportsPageProps} from "../../pages/ReportsPage";
import {API} from "../../api";
import {isLoading, isSuccess, minutesToHours, zeroToEmptyStringAsCurrency} from "../../common";
import moment, {Moment} from "moment";


interface BricksSalesReportPageState {
    loading: boolean,
    periodFrom: string,
    periodTo: string,
    data: any,
}

export class BricksSalesReportPage extends React.Component<ReportsPageProps, BricksSalesReportPageState> {

    constructor(props: ReportsPageProps) {
        super(props);
        this.state = {
            periodFrom: props.periodFrom,
            periodTo: props.periodTo,
            loading: false,
            data: null,
        }

        this.loadData = this.loadData.bind(this);
        this.processData = this.processData.bind(this);
    }

    async componentDidMount() {
        this.loadData();
    }

    loadData() {
        API.calcBricksSalesReport(
            (apiCall: API_CALL) => {
                this.setState({loading: isLoading(apiCall)});

                if (isSuccess(apiCall)) {
                    console.log(apiCall.data.data);
                    this.processData(apiCall.data.data.report);
                }
            }, {
                periodFrom: this.props.periodFrom,
                periodTo: this.props.periodTo,
            }
        )
    }

    processData(data: any) {
        this.setState({data: data.items});
    }

    componentDidUpdate(prevProps: Readonly<ReportsPageProps>, prevState: Readonly<BricksSalesReportPageState>, snapshot?: any) {
        if (
            (this.state.periodFrom !== this.props.periodFrom) ||
            (this.state.periodTo !== this.props.periodTo)
        ) {
            this.setState({
                periodFrom: this.props.periodFrom,
                periodTo: this.props.periodTo
            });
            this.loadData();
        }
    }


    renderReport(data: any) {
        let ftotalPalets = 0;
        let ftotalBricks = 0;
        let ftotalLogistics = 0;
        let ftotalPrice = 0;
        let totalPalets = 0;
        let totalBricks = 0;
        let totalLogistics = 0;
        let totalPrice = 0;

        console.log(data);

        return (
            <>
                <BlockTitleComponent title={data.brick}/>
                {
                    data.sales.length == 0 ?
                        <div><strong>Няма продажби за този период</strong></div>
                        :
                        <table className={"table table-sm"}>
                            <thead>
                            <tr>
                                <th>Дата</th>
                                <th>Фирма</th>
                                <th>Тип</th>
                                <th>Вид</th>
                                <th className={"text-right"}>Палети (бр)</th>
                                <th className={"text-right"}>Тухли (бр)</th>
                                <th className={"text-right"}>Транспорт (лв)</th>
                                <th className={"text-right"}>Сума (лв)</th>
                                <th className={"text-right"}>СЦТ (лв)</th>
                            </tr>
                            </thead>
                            <tbody>
                            {
                                data.sales.map((sale: any) => {

                                        sale.bricksQty = sale.bricksQty * 1;
                                        sale.paletsQty = sale.paletsQty * 1;
                                        sale.logistics = sale.logistics * 1;
                                        sale.price = sale.price * 1;

                                        totalPalets += sale.paletsQty;
                                        totalBricks += sale.bricksQty;
                                        totalLogistics += sale.logistics;
                                        totalPrice += sale.price;

                                        if (sale.saleType === "bricks_w_env") {
                                            ftotalPalets += sale.paletsQty;
                                            ftotalBricks += sale.bricksQty;
                                            ftotalLogistics += sale.logistics;
                                            ftotalPrice += sale.price;
                                        }

                                        return <tr className={sale.isPayed ? "" : "bg-light text-danger"}>
                                            <td>{sale.date}</td>
                                            <td>{sale.client}</td>
                                            <td>{sale.payType === "BANKA" ? 'Банка' : 'Кеш'}</td>
                                            <td>{sale.saleType === "bricks_w_env" ? 'Ф' : 'КБ'}</td>
                                            <td className={"text-right"}>{sale.paletsQty}</td>
                                            <td className={"text-right"}>{sale.bricksQty}</td>
                                            <td className={"text-right"}>{zeroToEmptyStringAsCurrency(sale.logistics)}</td>
                                            <td className={"text-right"}>{zeroToEmptyStringAsCurrency(sale.price)}</td>
                                            <td className={"text-right"}>{zeroToEmptyStringAsCurrency((sale.price / sale.bricksQty).toString())}</td>
                                        </tr>
                                    }
                                )
                            }

                            <tr className={"bg-success text-light font-weight-bold"} style={{borderTop: "solid 3px"}} >
                                <td colSpan={4} className={"text-right"}>Всичко с фактура</td>
                                <td className={"text-right"}>{ftotalPalets}</td>
                                <td className={"text-right"}>{ftotalBricks}</td>
                                <td className={"text-right"}>{zeroToEmptyStringAsCurrency(ftotalLogistics.toString())}</td>
                                <td className={"text-right"}>{zeroToEmptyStringAsCurrency(ftotalPrice.toString())}</td>
                                <td className={"text-right"}>{zeroToEmptyStringAsCurrency((ftotalPrice / ftotalBricks).toString())}</td>
                            </tr>
                            <tr className={"bg-success text-light font-weight-bold"}>
                                <td colSpan={4} className={"text-right"}>Всичко с касов бон</td>
                                <td className={"text-right"}>{totalPalets - ftotalPalets}</td>
                                <td className={"text-right"}>{totalBricks - ftotalBricks}</td>
                                <td className={"text-right"}>{zeroToEmptyStringAsCurrency((totalLogistics - ftotalLogistics).toString())}</td>
                                <td className={"text-right"}>{zeroToEmptyStringAsCurrency((totalPrice - ftotalPrice).toString())}</td>
                                <td className={"text-right"}>{zeroToEmptyStringAsCurrency(((totalPrice - ftotalPrice) / (totalBricks - ftotalBricks)).toString())}</td>
                            </tr>
                            <tr className={"bg-info text-light font-weight-bold"}>
                                <td colSpan={4} className={"text-right"}>Всичко</td>
                                <td className={"text-right"}>{totalPalets}</td>
                                <td className={"text-right"}>{totalBricks}</td>
                                <td className={"text-right"}>{zeroToEmptyStringAsCurrency(totalLogistics.toString())}</td>
                                <td className={"text-right"}>{zeroToEmptyStringAsCurrency(totalPrice.toString())}</td>
                                <td className={"text-right"}>{zeroToEmptyStringAsCurrency((totalPrice / totalBricks).toString())}</td>
                            </tr>
                            {/*<tr className={"bg-info text-light font-weight-bold"}>*/}
                            {/*    <td colSpan={4} className={"text-right"}>Средна цена на тухла за периода</td>*/}
                            {/*    <td colSpan={4}></td>*/}
                            {/*    <td className={"text-right"}>{zeroToEmptyStringAsCurrency((totalPrice / totalBricks).toString())}</td>*/}
                            {/*</tr>*/}
                            </tbody>
                        </table>
                }
                <div>&nbsp;</div>
            </>
        )
    }

    renderReports() {
        return this.state.data.map((brick: any) => this.renderReport(brick));
    }

    render() {
        return (
            <>
                <div className="row">
                    <div className="col">
                        {
                            this.state.loading &&
                            <div className={"m-3"}>Зареждам данните ...</div>
                        }
                    </div>
                </div>

                {
                    !this.state.loading && this.state.data && this.renderReports()
                }

            </>
        );
    }
}
