import React from "react";
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import {API_CALL, PropertyValue, REPAIR_TYPE} from "../../types";
import {isLoading, isSuccess} from "../../common";
import {BlockTitleComponent} from "../common/BlockTitleComponent";
import {API} from "../../api";
import {ReportsPageProps} from "../../pages/ReportsPage";
import {Moment} from "moment";

const options = {
    chart: {
        type: 'column',
        height: '600em',
    },
    title: {
        align: 'left',
        useHTML: true,
        text: 'Производствен отчет за период <b></b>',
    },
    subtitle: {
        floating: true,
        useHTML: true,
        align: 'left',
        text: ''
    },
    colors: ['#2f7ed8', '#f50000', '#f2f200', '#00f2f2'],
    tooltip: {
        enabled: false,
    },
    xAxis: {
        categories: ['Мелене(глина, шлам)', 'Екструдиране', 'Печка(пелети)', 'Редене', 'Пещ(пелети)', 'Разтоварване'],
        title: {
            text: null
        }
    },
    yAxis: {
        min: 0,
        max: 120,
        title: {
            text: 'Производителност (%)',
            align: 'high'
        },
        labels: {
            overflow: 'justify'
        },
        plotLines: [{value: 100, width: 2, color: 'rgba(0, 0, 0)', label: {useHTML: true, text: 'Стандарт', align: 'right'}}],
    },
    plotOptions: {
        series: {
            dataLabels: {
                enabled: true,
                inside: false,
                overflow: 'none',
                crop: true,
                backgroundColor: 'rgba(0,0,0,0.8)',
                borderColor: 'rgba(0,0,0,0.9)',
                color: 'rgba(255,255,255,0.75)',
                borderWidth: .5,
                borderRadius: 5,
                y: -10,
                style: {
                    fontFamily: 'Helvetica, sans-serif',
                    fontSize: '11px',
                    fontWeight: 'normal',
                    textShadow: 'none'
                },
                formatter: function (): any {
                    // @ts-ignore
                    return this.point.label;
                }
            }
        }
    },
    legend: {
        align: 'right',
        verticalAlign: 'top',
        borderWidth: 1,
        backgroundColor: '#FFFFFF',
        shadow: true
    },
    credits: {
        enabled: false
    },
    series: [{
        name: 'Производство',
        data: [null, null, null, null, null, null]
    }, {
        name: 'Разход',
        data: [null, null, null, null, null, null]
    }, {
        name: 'Брак',
        data: [null, null, null, null, null, null]
    }]
}

interface ProductionReportsPageState {
    loading: boolean,
    options: any,
    periodFrom: string,
    periodTo: string,
    shiftId?: number,
}

interface RepairItem {
    type: REPAIR_TYPE,
    fromTime: string,
    toTime: string,
    comments: string,
}

export class ProductionReportPage extends React.Component<ReportsPageProps, ProductionReportsPageState> {

    constructor(props: ReportsPageProps) {
        super(props);
        this.state = {
            loading: false,
            periodFrom: props.periodFrom,
            periodTo: props.periodTo,
            shiftId: props.shiftId,
            options: {...options}
        }

        this.loadData = this.loadData.bind(this);
        this.processData = this.processData.bind(this);
    }

    async componentDidMount() {
        console.log('componentDidMount');
        this.loadData();
    }


    loadData() {
        API.calcProductionReport(
            (apiCall: API_CALL) => {
                this.setState({loading: isLoading(apiCall)});

                if (isSuccess(apiCall)) {
                    console.log(apiCall.data.data);
                    this.processData(apiCall.data.data.report);
                }
            }, {
                periodFrom: this.props.periodFrom,
                periodTo: this.props.periodTo,
                shift: this.props.shiftId,
            }
        )
    }

    processData(data: any) {
        let maxY = 125;
        let grindingClay = 0;
        let grindingSludge = 0;
        if (data.grindings.clay > 0) {
            grindingClay = data.grindings.clay > 0 ? Math.round(data.grindings.clay / data.grindings.standart * 100) : 0;
            grindingSludge = data.grindings.sludge > 0 ? Math.round(data.grindings.sludge / data.grindings.standart_sludge * 100) : 0;
        }

        // debugger
        if (grindingClay > maxY) maxY = grindingClay + 10;
        if (data.extruder.percent > maxY) maxY = data.extruder.percent + 10;
        if (data.extruder.waste_percent > maxY) maxY = data.extruder.waste_percent + 10;
        if (data.ordering.percent > maxY) maxY = data.ordering.percent + 10;
        if (data.ordering.waste_percent > maxY) maxY = data.ordering.waste_percent + 10;
        if (data.oven.percent > maxY) maxY = data.oven.percent + 10;
        if (data.oven.peleti_percent > maxY) maxY = data.oven.peleti_percent + 10;
        if (data.oven.peleti_heater_percent > maxY) maxY = data.oven.peleti_heater_percent + 10;
        if (data.unloading.percent > maxY) maxY = data.unloading.percent + 10;
        if (data.unloading.waste_percent > maxY) maxY = data.unloading.waste_percent + 10;
        if (data.unloading.not_good_percent > maxY) maxY = data.unloading.not_good_percent + 10;
        maxY += 75;

        const productionLabel = function () {
            return 'Произв.глина: ' + grindingClay + '%' + '<br/>Глина: ' + data.grindings.clay + 'кг<br/>Произв.шлам: ' +
                grindingSludge + '%<br/>Шлам: ' + data.grindings.sludge + 'кг<br/>' + 'Съотн: 1:' +
                (isNaN(Math.round(data.grindings.clay / data.grindings.sludge)) ? 0 : (data.grindings.clay / data.grindings.sludge).toFixed(1));
        }
        const extruderLabel = function () {
            return 'Натовареност: ' + data.extruder.percent + '%' + '<br/>Тухли: ' + data.extruder.bricks + 'бр<br/>' +
                Object.keys(data.extruder.byType).filter(
                    key => data.extruder.byType[key] > 0
                ).map(
                    key => key + ': ' + data.extruder.byType[key] + 'бр'
                ).join('<br/>') +
                '<br/>Брак: ' + data.extruder['waste'] + 'бр - ' +
                (isNaN(Math.round((data.extruder['waste'] / data.extruder['bricks']) * 100)) ? 0 : ((data.extruder['waste'] / data.extruder['bricks']) * 100).toFixed(1)) + '%';
        }
        const ovenLabel = function () {
            return 'Произв: ' + data.oven.percent + '%' + '<br/>Тухли: ' + data.oven.total_bricks + 'бр' +
                '<br/>Пещ произв: ' + data.oven.peleti_percent + '%' + '<br/>Пелети: ' + data.oven.peleti_total + 'кг' +
                '<br/>Печка произв: ' + data.oven.peleti_heater_percent + '%' + '<br/>Пелети: ' + data.oven.peleti_heater_total + 'кг';
        }
        const unloadingLabel = function () {
            return 'Произв: ' + data.unloading.percent + '%' + '<br/>Тухли: ' + data.unloading.total_bricks + 'бр' +
            '<br/>Брак: ' + data.unloading.total_waste + 'бр - ' +
            (isNaN(Math.round((data.unloading['total_waste'] / data.unloading['total_bricks']) * 100)) ?
                0 :
                ((data.unloading['total_waste'] / data.unloading['total_bricks']) * 100).toFixed(1)) + '%<br/>' +
            'II кач.: ' + data.unloading.total_not_good + 'бр. - ' +
            (isNaN(Math.round((data.unloading['total_not_good'] / data.unloading['total_bricks']) * 100)) ?
                0 :
                ((data.unloading['total_not_good'] / data.unloading['total_bricks']) * 100).toFixed(1)) + '%'
        }

        const orderingLabel = function () {
            return 'Произв: ' + data.ordering.percent + '%' + '<br/>Тухли: ' + data.ordering.total_bricks + 'бр' +
            '<br/>Брак: ' + data.ordering.total_waste + 'бр - ' +
            (isNaN(Math.round((data.ordering.total_waste / data.ordering.total_bricks) * 100)) ?
                0 :
                (((data.ordering.total_waste / data.ordering.total_bricks) * 100).toFixed(1)) + '%')
        }

        // @ts-ignore
        const newOptions = {
            ...options,
            title: {
                text: 'Производствен отчет за период <b>' +
                    this.props.periodFrom + ' - ' +
                    this.props.periodTo + '</b>',
            },
            subtitle: {
                text: 'Брой хора: <b>' +
                    (data.grindings?.mans +
                        data.extruder?.mans +
                        data.heater?.mans +
                        data.ordering?.mans +
                        data.oven?.mans +
                        data.unloading?.mans) +
                    '</b>'
            },
            yAxis: {
                max: maxY,
            },
            xAxis: {
                categories: [
                    ((data.grindings?.user !== "") ? '<strong>'+data.grindings?.user+'</strong><br/>' : '') + 'Мелене(глина, шлам)<br/>Брой хора: ' + data.grindings?.mans,
                    ((data.extruder?.user !== "") ? '<strong>'+data.extruder?.user+'</strong><br/>' : '') + 'Екструдиране<br/>Брой хора: ' + data.extruder?.mans,
                    ((data.heater?.user !== "") ? '<strong>'+data.heater?.user+'</strong><br/>' : '') + 'Печка(пелети)<br/>Брой хора: ' + data.heater?.mans,
                    ((data.ordering?.user !== "") ? '<strong>'+data.ordering?.user+'</strong><br/>' : '') + 'Редене<br/>Брой хора: ' + data.ordering?.mans,
                    ((data.oven?.user !== "") ? '<strong>'+data.oven?.user+'</strong><br/>' : '') + 'Пещ(пелети)<br/>Брой хора: ' + data.oven?.mans,
                    ((data.unloading?.user !== "") ? '<strong>'+data.unloading?.user+'</strong><br/>' : '') + 'Разтоварване<br/>Брой хора: ' + data.unloading?.mans
                ],
            },
            series: [
                {
                    name: 'Производство',
                    data: [
                        {
                            y: grindingClay,
                            label: (grindingClay >= grindingSludge) ? productionLabel() : null //grindingClay + '%'
                        },
                        {
                            y: data.extruder.percent,
                            label: data.extruder.percent >= data.extruder.waste_perc ? extruderLabel() : null
                        },
                        null,
                        {
                            y: data.ordering.percent,
                            label: data.ordering.percent > data.ordering.waste_percent ? orderingLabel() : null
                            // label: data.ordering.percent > data.ordering.waste_percent ? orderingLabel() : data.ordering.percent + '%'
                        },
                        {
                            y: data.oven.percent,
                            label: ((data.oven.percent >= data.oven.peleti_percent) && (data.oven.percent >= data.oven.peleti_heater_percent)) ? ovenLabel() : null
                        },
                        {
                            y: data.unloading.percent,
                            label: (data.unloading.percent >= data.unloading.waste_percent) ? unloadingLabel() : null
                        }]
                },
                {
                    name: 'Разход (глина, пелети)',
                    data: [
                        {
                            y: grindingSludge,
                            label: grindingClay < grindingSludge ? productionLabel() : null
                            // label: grindingSludge < grindingClay ?data.extruder['waste_perc'] + '%' : productionLabel()
                        },
                        null,
                        {
                            y: data.heater?.percent,
                            label: 'Разх.норма: ' + data.heater?.percent + '%' + '<br/>Пелети: ' + data.heater?.kg + 'кг'
                        },
                        null,
                        {
                            y: data.oven.peleti_percent,
                            label: ((data.oven.peleti_percent >= data.oven.percent) && (data.oven.peleti_percent >= data.oven.peleti_heater_percent))  ? ovenLabel() : null
                            // label: data.oven.percent >= data.oven.peleti_percent ? data.oven.peleti_percent + '%' : ovenLabel()
                        },
                        null
                    ]
                },
                {
                    name: 'Брак',
                    data: [
                        null,
                        {
                            y: data.extruder['waste_perc'],
                            label: data.extruder.percent >= data.extruder.waste_perc ? null : extruderLabel()
                            // label: data.extruder.percent >= data.extruder.waste_perc ? (data.extruder.waste_perc + '%') : extruderLabel()
                        },
                        null,
                        {
                            y : data.ordering.waste_percent,
                            label: data.ordering.waste_percent > data.ordering.percent ? orderingLabel() : null,
                            // label: data.ordering.waste_percent > data.ordering.percent ? orderingLabel() : data.ordering.waste_percent + '%',
                        },
                        {
                            color: '#f50000',
                            y: data.oven.peleti_heater_percent,
                            label: ((data.oven.peleti_heater_percent >= data.oven.peleti_percent) && (data.oven.peleti_heater_percent >= data.oven.percent))
                                ? ovenLabel() : null
                            // label: data.oven.percent >= data.oven.peleti_percent ? data.oven.peleti_percent + '%' : ovenLabel()
                        },
                        {
                            y: data.unloading.waste_percent,
                            label: (data.unloading.waste_percent > data.unloading.percent) ? unloadingLabel() : null
                        }
                    ]
                },
                {
                    name: 'II кач',
                    data: [null,
                        null,
                        null,
                        null,
                        null,
                        {
                            y: data.unloading.not_good_percent,
                            label: data.unloading.not_good_percent + '%'
                        }
                    ]
                }
            ]
        };

        this.setState({'options': newOptions});
    }

    componentDidUpdate(prevProps: Readonly<ReportsPageProps>, prevState: Readonly<ProductionReportsPageState>, snapshot?: any) {
        if (
            (this.state.periodFrom !== this.props.periodFrom) ||
            (this.state.periodTo !== this.props.periodTo) ||
            (this.state.shiftId !== this.props.shiftId)
        ) {
            this.setState({
                periodFrom: this.props.periodFrom,
                periodTo: this.props.periodTo,
                shiftId: this.props.shiftId
            });
            this.loadData();
        }
    }

    render() {
        return (
            <>
                <div className="row">
                    <div className="col">
                        {
                            this.state.loading &&
                            <div className={"m-3"}>Зареждам данните ...</div>
                        }
                    </div>
                </div>

                <BlockTitleComponent title={"Производителност за период"}/>
                <HighchartsReact
                    highcharts={Highcharts}
                    options={this.state.options}
                    allowChartUpdate
                />
            </>
        )
    }

}
