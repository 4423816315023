import React from "react";
import {FACTORY} from "../factories";
import {API} from "../api";
import {API_CALL, AppModule, NET_STATUS, UserModule} from "../types";
import {isError, isLoading, isSuccess, mapToObject, showErrorsInToast, translateError} from "../common";
import {Link, Redirect} from "react-router-dom";
import {PATHS} from "../paths";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Modal} from 'react-bootstrap';
import {faArrowLeft} from "@fortawesome/free-solid-svg-icons";

interface AccountPageState {
    api: API_CALL,
    formValues: Map<string, any>,
    modules: AppModule[],
    user: any,
    dialogConfirmDeleteVisible: boolean,
    working: boolean,
}

export class AccountPage extends React.Component<any, AccountPageState> {
    accountId: number | null = null;

    constructor(props: any, match: any) {
        super(props);
        this.state = {
            api: FACTORY.createApiCall(NET_STATUS.LOADING),
            formValues: new Map<string, any>(),
            modules: [],
            user: null,
            dialogConfirmDeleteVisible: false,
            working: false,
        }
        this.accountId = props.match.params.accountId;
        this.handleFormChange = this.handleFormChange.bind(this);
        this.doDeleteReport = this.doDeleteReport.bind(this);
    }

    componentDidMount() {
        this.loadModules();
    }

    loadModules() {
        const me = this;
        API.getAppModules(
            (apiCall: API_CALL) => {
                if (isSuccess(apiCall)) {
                    this.setState({modules: apiCall.data.data.modules});
                    me.loadAccount();
                }
            }
        )
    }

    loadAccount() {
        const id = this.accountId || -1;
        if (!isNaN(id)) {
            API.getAccount(
                (apiCall: API_CALL) => {
                    if (isSuccess(apiCall)) {
                        const formValues = new Map<string, any>();
                        formValues.set('name', apiCall.data.data.user.name);
                        formValues.set('key', apiCall.data.data.user.key);
                        formValues.set('email', apiCall.data.data.user.email);
                        formValues.set('position', apiCall.data.data.user.position);
                        formValues.set('is_admin', apiCall.data.data.user.is_admin === 'Y');
                        apiCall.data.data.user.modules.forEach(
                            (item: UserModule) => {
                                formValues.set('mr_' + item.module.id, item.access_rights === 'READ');
                                formValues.set('mw_' + item.module.id, item.access_rights === 'WRITE');
                            }
                        );
                        this.setState({api: apiCall, user: apiCall.data?.data?.user, formValues: formValues});
                    }
                }, id
            )
        } else {
            const _api = {...this.state.api, status: NET_STATUS.SUCCESS};
            this.setState({api: _api, user: null});
        }
    }

    handleFormChange(event: any) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState(
            prevState => {
                return {formValues: prevState.formValues.set(name, value)}
            }
        )
    }

    private doSave() {
        API.saveAccount(
            (apiCall: API_CALL) => {
                this.setState({working: isLoading(apiCall)});
                if (isSuccess(apiCall)) {
                    window.location.href = PATHS.accounts + API.getAPITokenAsQuery();
                }
                if (isError(apiCall)) {
                    showErrorsInToast(apiCall, "Данните не бяха запазени поради следните грешки:", translateError);
                }
            }, {...mapToObject(this.state.formValues), userId: this.state.user ? this.state.user.id : -1}
        )

    }

    doDeleteReport() {
        this.setState({dialogConfirmDeleteVisible: false});
        API.deleteAccount(
            (apiCall: API_CALL) => {
                if (isSuccess(apiCall)) {
                    window.location.href = PATHS.accounts + API.getAPITokenAsQuery();
                }
            }, this.state.user.id
        );
    }


    render() {
        if (isError(this.state.api)) return (<Redirect to={PATHS.accounts}/>);

        return (
            <div className="container">
                <div className="card shadow">
                    <div className="card-header text-center row">
                        <div className="col-2 text-left">
                            {
                                isLoading(this.state.api) ?
                                    <div className="spinner-border" role="status">
                                        <span className="sr-only">Loading...</span>
                                    </div>
                                    :
                                    <Link to={PATHS.accounts + API.getAPITokenAsQuery()}><span className="btn btn-secondary"><FontAwesomeIcon icon={faArrowLeft}/></span></Link>
                            }
                        </div>
                        <h2 className="col">{this.state.user?.name || 'Нов служител'}</h2>
                        <div className="col-2">
                        </div>
                    </div>

                    <div className="card-body p-5">
                        {
                            (isSuccess(this.state.api) || (!this.state.user)) &&
                            <>
                                <form style={{maxWidth: "30rem"}}>
                                    <div className="form-group row">
                                        <label>Име на служителя:</label>
                                        <input className="form-control" type="text" name="name" onChange={this.handleFormChange}
                                               value={this.state.formValues.get('name') || ''}/>
                                    </div>
                                    <div className="form-group row">
                                        <label>Длъжност:</label>
                                        <input className="form-control" type="text" name="position" onChange={this.handleFormChange}
                                               value={this.state.formValues.get('position') || ''}/>
                                    </div>
                                    <div className="form-group row">
                                        <label>
                                            <input type="checkbox" name="is_admin" onChange={this.handleFormChange}
                                                   value={'Y'}
                                                   checked={this.state.formValues.get('is_admin') || false}/> Потребителя е администратор? {this.state.formValues.get('is_admin')}</label>
                                    </div>
                                    {
                                        this.state.user &&
                                        <div className="form-group row">
                                            <label>
                                                <input type="checkbox" name="newPassword" onChange={this.handleFormChange}
                                                       value={this.state.formValues.get('newPassword') || ''}/> Нова парола?</label>
                                        </div>
                                    }
                                    {
                                        (this.state.formValues.get('newPassword') || (!this.state.user)) &&
                                        <div className="form-group row">
                                            <label>Парола:</label>
                                            <input className="form-control" type="text" name="password" onChange={this.handleFormChange}
                                                   value={this.state.formValues.get('password') || ''}/>
                                        </div>
                                    }
                                    <div>
                                        <label>Права за достъп:</label>
                                        <table className="table">
                                            <tr>
                                                <th>Модул</th>
                                                <th>Четене</th>
                                                <th>Писане</th>
                                            </tr>
                                            {
                                                this.state.modules?.map(
                                                    (i: any) => <tr key={i.id}>
                                                        <td>{i.name}</td>
                                                        <td>
                                                            <input type="checkbox"
                                                                   name={"mr_" + i.id}
                                                                   checked={this.state.formValues.get("mr_" + i.id) || false}
                                                                   onChange={this.handleFormChange}/>
                                                        </td>
                                                        <td>
                                                            <input type="checkbox"
                                                                   name={"mw_" + i.id}
                                                                   checked={this.state.formValues.get("mw_" + i.id) || false}
                                                                   onChange={this.handleFormChange}/>
                                                        </td>
                                                    </tr>
                                                )
                                            }
                                        </table>
                                    </div>
                                    <div className="form-group row">
                                        <label>Линк за директен вход:</label>
                                        <div className={"w-100 bg-secondary p-3 text-light"}>{window.location.host + PATHS.login + '?key=' + this.state.formValues.get('key') || ''}</div>
                                    </div>
                                </form>
                            </>
                        }
                    </div>

                    <div className="card-footer">
                        {
                            this.state.working ?
                                <>
                                    <div className="spinner-border" role="status">
                                        <span className="sr-only">Loading...</span>
                                    </div>
                                </>
                                :
                                <>
                                    <button type="button" onClick={this.doSave.bind(this)} className="btn btn-primary mr-1">Запази промените</button>
                                    {
                                        this.state.user &&
                                        <button type="button" className="btn btn-secondary"
                                                onClick={() => this.setState({dialogConfirmDeleteVisible: true})}
                                        >Изтрий служителя</button>
                                    }
                                </>
                        }
                    </div>

                </div>

                    <Modal
                        show={this.state.dialogConfirmDeleteVisible}
                        onHide={() => this.setState({dialogConfirmDeleteVisible: false})}
                    >
                        <Modal.Header closeButton>
                            <Modal.Title>Внимание</Modal.Title>
                        </Modal.Header>

                        <Modal.Body>
                            <p>Моля, потвърдете изтриването!</p>
                        </Modal.Body>

                        <Modal.Footer>
                            {
                                this.state.working ?
                                    <div className="spinner-border" role="status">
                                        <span className="sr-only">Loading...</span>
                                    </div>
                                    :
                                    <>
                                        <button type={"button"} className={"btn btn-secondary"} onClick={() => this.setState({dialogConfirmDeleteVisible: false})}>Откажи</button>
                                        <button type={"button"} className={"btn btn-primary"} onClick={this.doDeleteReport}>Изтрий</button>
                                    </>
                            }
                        </Modal.Footer>
                    </Modal>

            </div>
        );
    }
}
