import React from "react";
import {PATHS} from "../paths";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Link} from "react-router-dom";
import {UserNameComponent} from "../components/common/UserNameComponent";
import {API} from "../api";
import {API_CALL} from "../types";
import {isSuccess} from "../common";

export function HeaderComponent(props: any) {

    const logout = function () {
        API.getUserLogout(
            (api: API_CALL) => {
                if (isSuccess(api)) {
                    API.user = null;
                    window.location.href = "/login";
                }
            }
        )
    }

    return (
        <>
            <Link to={PATHS.home + API.getAPITokenAsQuery()} className={"text-dark"}>
                {/*<img src={"/logo.svg"} style={{height: "3em"}}/>*/}
            </Link>
            <span>
            {
                window.location.href.indexOf(PATHS.login) === -1 &&
                <>
                    <UserNameComponent/>
                    &nbsp;&nbsp;
                    <a className={"btn btn-sm btn-outline-primary m-0 p-0 pl-1 pr-1"} href={"/home" + API.getAPITokenAsQuery()}>
                        <FontAwesomeIcon icon="home"/>
                    </a>
                    &nbsp;&nbsp;
                    <a className={"btn btn-sm btn-outline-primary m-0 p-0 pl-1 pr-1"} href={"#"} onClick={logout}>
                        <FontAwesomeIcon icon="sign-out-alt" size="sm"/> Изход
                    </a>
                </>
            }
            </span>
        </>
    )
}
