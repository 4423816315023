import React from "react";
import {$enum} from "ts-enum-util";
import {API_CALL, PARAMETERS, PropertyValue, REPAIR_TYPE} from "../../types";
import {BlockTitleComponent} from "../common/BlockTitleComponent";
import {ReportsPageProps} from "../../pages/ReportsPage";
import {API} from "../../api";
import {isLoading, isSuccess, minutesToHours} from "../../common";
import moment, {Moment} from "moment";
import {Link} from "react-router-dom";
import {PATHS} from "../../paths";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowLeft} from "@fortawesome/free-solid-svg-icons";
import {ReadOnlyDatePickerComponent} from "../common/ReadOnlyDatePickerComponent";

const options = {
    chart: {
        type: 'column',
    },
    title: {
        align: 'left',
        useHTML: true,
        text: 'Изразходвано време за ремонти за период <b></b>',
    },
    subtitle: {
        floating: true,
        useHTML: true,
        align: 'left',
        text: ''
    },
    colors: ['#2f7ed8', '#f50000', '#f2f200'],
    tooltip: {
        enabled: false,
    },
    xAxis: {
        categories: ['Мелене(глина)', 'Екструдиране', 'Печка(пелети)', 'Редене', 'Пещ(пелети)', 'Разтоварване'],
        title: {
            text: null
        }
    },
    yAxis: {
        min: 0,
        max: 120,
        title: {
            text: 'Производителност (%)',
            align: 'high'
        },
        labels: {
            overflow: 'justify'
        },
        plotLines: [{value: 100, width: 2, color: 'rgba(0, 0, 0)', label: {useHTML: true, text: 'Стандарт', align: 'right'}}],
    },
    plotOptions: {
        series: {
            dataLabels: {
                enabled: true,
                align: 'left',
                border: true,
                borderWidth: 1,
                shadow: true,
                verticalAlign: 'top',
                useHTML: true,
                x: 10,
                y: 10,
                formatter: function (): any {
                    // @ts-ignore
                    return this.point.label;
                }
            }
        }
    },
    legend: {
        align: 'right',
        verticalAlign: 'top',
        borderWidth: 1,
        backgroundColor: '#FFFFFF',
        shadow: true
    },
    credits: {
        enabled: false
    },
    series: [{
        name: 'Производство',
        data: [null, null, null, null, null, null]
    }, {
        name: 'Разход',
        data: [null, null, null, null, null, null]
    }, {
        name: 'Брак',
        data: [null, null, null, null, null, null]
    }]
}


interface CommentsReportPageState {
    _loading: number,
    loading: boolean,
    formValues: Map<string, any>,
    shifts: PropertyValue[],
    comments: any,
}

export class CommentsReportPage extends React.Component<any, CommentsReportPageState> {

    constructor(props: ReportsPageProps) {
        super(props);
        this.state = {
            _loading: 0,
            loading: false,
            formValues: new Map<string, any>(),
            shifts: [],
            comments: null,
        }

        this.handleFormChange = this.handleFormChange.bind(this);
        this.periodToday = this.periodToday.bind(this);
        this.selectDate = this.selectDate.bind(this);
        this.loadData = this.loadData.bind(this);
        this.processData = this.processData.bind(this);
    }

    async componentDidMount() {
        this.loadParameters();
    }

    periodToday() {
        this.selectDate('periodFrom', new Date());
        this.selectDate('periodTo', new Date());
        this.loadData();
    }

    handleFormChange(event: any) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        const fv = this.state.formValues;
        fv.set(name, value);
        this.setState({formValues: fv});
    }

    selectDate(dateFieldName: string, newDate: Date) {
        this.setState({formValues: this.state.formValues.set(dateFieldName, moment(newDate).format('DD.MM.YYYY'))});
    }


    loadParameters() {
        API.getParameterValues(
            (apiCall: API_CALL) => {
                if (isSuccess(apiCall)) {
                    this.setState({
                        shifts: apiCall.data.data.items?.values
                    });
                    this.periodToday();
                }
            }, PARAMETERS.SHIFTS
        );
    }

    loadData() {
        const fv = this.state.formValues;

        API.calcCommentsReport(
            (apiCall: API_CALL) => {
                this.setState({loading: isLoading(apiCall)});

                if (isSuccess(apiCall)) {
                    console.log(apiCall.data.data);
                    this.processData(apiCall.data.data.report);
                }
            }, {
                periodFrom: fv.get('periodFrom'),
                periodTo: fv.get('periodTo'),
                shift: fv.get('shiftId'),
            }
        )
    }

    processData(data: any) {
        this.setState({comments: data});
    }

    renderReport(title: string, items: any[]) {
        return (
            <>
                <BlockTitleComponent title={title}/>
                {
                    items.length == 0 ?
                        <div><strong>Няма бележки за този период</strong></div>
                        :
                        <table className={"table"}>
                            <thead>
                            <tr>
                                <th>Дата</th>
                                <th>Смяна</th>
                                <th>Бележка</th>
                            </tr>
                            </thead>
                            <tbody>
                            {
                                items.map((item) => <tr>
                                    <td>{item.date}</td>
                                    <td>{item.shift}</td>
                                    <td>{item.comment}</td>
                                </tr>)
                            }
                            </tbody>
                        </table>
                }
                <div>&nbsp;</div>
            </>
        )
    }


    render() {
        return (
            <>
                <div className="container" style={{maxWidth: '100%'}}>

                    <div className="card shadow">

                        <div className="card-header text-center row">
                            <div className="col-2 text-left">
                                {
                                    this.state.loading ?
                                        <div className="spinner-border" role="status">
                                            <span className="sr-only">Loading...</span>
                                        </div>
                                        :
                                        <Link to={PATHS.home + API.getAPITokenAsQuery()}><span className="btn btn-secondary"><FontAwesomeIcon icon={faArrowLeft}/></span></Link>
                                }

                            </div>
                            <h2 className="col">Бележки по процеси</h2>
                            <div className="col-2">
                            </div>
                        </div>

                        <div className="card-body">
                            <BlockTitleComponent title={"Параметри на справката"}/>
                            <div className={"row mb-5"}>

                                <div className={"col-auto ml-2 mr-2"}>
                                    <form className="form-inline">
                                        <div className={"text-nowrap row lh-2em "}>
                                            <div className={"col-12 w-120px"}>От дата:&nbsp;</div>
                                            <div className={"col-auto w-160px"}>
                                                <ReadOnlyDatePickerComponent
                                                    value={this.state.formValues.get('periodFrom')}
                                                    onChange={(newDate: Date) => this.selectDate('periodFrom', newDate)}
                                                />
                                            </div>
                                        </div>
                                    </form>
                                </div>
                                <div className={"col-auto mr-2"}>
                                    <form className="form-inline">
                                        <div className={"text-nowrap row lh-2em "}>
                                            <div className={"col-12 w-120px"}>До дата:&nbsp;</div>
                                            <div className={"col-auto w-160px"}>
                                                <ReadOnlyDatePickerComponent
                                                    value={this.state.formValues.get('periodTo')}
                                                    onChange={(newDate: Date) => this.selectDate('periodTo', newDate)}
                                                />
                                            </div>
                                        </div>
                                    </form>
                                </div>
                                {
                                    !this.state.loading &&
                                    <div className={"col-auto readonly-date-picker"} style={{paddingTop: 7}}>
                                        <label className={"justify-content-start"}>Работна смяна:</label>
                                        <select className="form-control w-100" name="shiftId" required
                                                value={this.state.formValues?.get('shiftId')}
                                                onChange={this.handleFormChange}>
                                            <option value="">Всички смени</option>
                                            {
                                                this.state.shifts.map(
                                                    (i: PropertyValue, idx: number) =>
                                                        <option value={idx} key={idx}>{i.value}</option>
                                                )
                                            }
                                            <option value="99">МЕЖДИННА СМЯНА</option>
                                        </select>
                                    </div>
                                }
                                <div className={"col-auto readonly-date-picker"}>
                                    {
                                        this.state.loading ?
                                            <>
                                                <label className={"col-form-label w-100"}>&nbsp; </label>
                                                <div className="spinner-border" role="status">
                                                    <span className="sr-only">Loading...</span>
                                                </div>
                                            </>
                                            :
                                            <>
                                                <label className={"col-form-label w-100"}>&nbsp; </label>
                                                <button className="btn btn-primary" onClick={this.loadData}>Калкулирай</button>
                                            </>
                                    }
                                </div>
                            </div>

                            <div className="row">
                                <div className="col">
                                    {
                                        this.state.loading &&
                                        <div className={"m-3"}>Зареждам данните ...</div>
                                    }
                                </div>
                            </div>

                            {
                                !this.state.loading && this.state.comments &&
                                <>
                                    {this.renderReport('Мелене', this.state.comments.grinding)}
                                    {this.renderReport('Екструдер', this.state.comments.extruder)}
                                    {this.renderReport('Печка', this.state.comments.heater)}
                                    {this.renderReport('Редене', this.state.comments.ordering)}
                                    {this.renderReport('Пещ', this.state.comments.oven)}
                                    {this.renderReport('Разтоварване', this.state.comments.unloading)}
                                    {this.renderReport('Ремонти', this.state.comments.repairs)}
                                </>
                            }

                            <div className="card-footer">
                                {
                                    <>

                                    </>
                                }
                            </div>
                        </div>

                    </div>
                </div>
            </>
        );
    }
}
