import React from "react";

export interface BlockTitleProps {
    title: string
}

export const BlockTitleComponent = function (props: BlockTitleProps) {
    return (
        <div className="col-12 card-subtitle border m-0 mb-2 p-2 bg-secondary text-light"
             dangerouslySetInnerHTML={{ __html: props.title }}></div>
    )
}
