import {API_CALL, City, Client, Driver, Logistic, PAY_TYPE, PropertyValue, Sale, SALE_TYPE, SaleItem} from "../../types";
import React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {BlockTitleComponent} from "../common/BlockTitleComponent";
import moment from "moment";
import {Dropdown, Form, Modal} from "react-bootstrap";
import DropdownToggle from "react-bootstrap/DropdownToggle";
import {API} from "../../api";
import {isError, isLoading, isSuccess, mapToObject, showErrorsInToast, translateError} from "../../common";
import {ClientsPage} from "../../pages/ClientsPage";
import {LogisticsPage} from "../../pages/LogisticsPage";
import {ReadOnlyDatePickerComponent} from "../common/ReadOnlyDatePickerComponent";


interface SaleDetailsProps {
    sale: Sale,
    onAfterSave: any,
    onCancel: any,
    brick_types: PropertyValue[],
    pelet_types: PropertyValue[],
    bag_types: PropertyValue[],
}

interface SaleDetailsState {
    client_filter: Map<string, any>,
    logistic_filter: Map<string, any>,
    formValues: Map<string, any>,
    showClientList: boolean,
    showLogisticList: boolean,
    working: boolean,
}

export class SaleDetailsModalComponent extends React.Component<SaleDetailsProps, SaleDetailsState> {
    QtyInPalet: PropertyValue[] = [
        {id: 150, value: '150', description: ''},
        {id: 180, value: '180', description: ''},
        {id: 330, value: '330', description: ''},
        {id: 456, value: '456', description: ''},
    ];

    constructor(props: SaleDetailsProps) {
        super(props);

        this.state = {
            client_filter: new Map<string, any>(),
            logistic_filter: new Map<string, any>(),
            formValues: new Map<string, any>(),
            showClientList: false,
            showLogisticList: false,
            working: false,
        }

        this.state.formValues.set('sale_type', this.props.sale.sale_type || SALE_TYPE.bricks_w_env);
        this.state.formValues.set('sales_date', moment().format('DD.MM.Y'));
        this.state.formValues.set('returned', 0);
        this.state.formValues.set('total_pelet', 0);
        this.state.formValues.set('store_manager', 'СВИЛЕН ДАМЯНОВ');
        this.state.formValues.set('pay_type', PAY_TYPE.BANKA);

        if (this.props.sale.id) {
            this.state.formValues.set('sales_number', this.props.sale.sales_number);

            this.state.client_filter.set('name', this.props.sale.client);
            this.state.client_filter.set('cities', this.props.sale.client_city);

            this.state.logistic_filter.set('name', this.props.sale.currier);
            this.state.logistic_filter.set('driver', this.props.sale.driver);

            if (props.sale?.request_data) {
                const requestValues = Object.entries(JSON.parse(props.sale.request_data.replaceAll('"null"', '""')));

                for (let [key, value] of requestValues) {
                    if (key === "sale_type") {
                        value = value || Object.values(SALE_TYPE)[1];
                        this.state.formValues.set(key, Object.values(SALE_TYPE)[Object.keys(SALE_TYPE).indexOf(value as string)]);
                    } else this.state.formValues.set(key, value || '');
                }
            } else {
                for (const [key, value] of Object.entries(this.props.sale)) {
                    let v = value;
                    if (key === "sale_type") {
                        v = Object.values(SALE_TYPE)[Object.keys(SALE_TYPE).indexOf(value as string)] || SALE_TYPE.bricks_w_env;
                    }
                    this.state.formValues.set(key, v ? v : '');
                }
                let idx = 0;
                this.props.sale.items?.forEach(
                    (i: SaleItem) => {
                        i.total = i.palet_qty * i.qty_in_palet;
                        if (isNaN(i.total)) i.total = 0;
                        for (const [key, value] of Object.entries(i)) {
                            this.state.formValues.set(key + "_" + idx, value || '');
                        }
                        idx++;
                    }
                )
            }

            this.state.formValues.set('payed_by_avans', this.props.sale.payed_by_avans == 1);

            this.calculatePelet(this.state.formValues);
        }

        this.handleFormChange = this.handleFormChange.bind(this);
        this.onSelectClient = this.onSelectClient.bind(this);
        this.onSelectLogistic = this.onSelectLogistic.bind(this);
        this.renderBrickTypeSelect = this.renderBrickTypeSelect.bind(this);
        this.renderPeletTypeSelect = this.renderPeletTypeSelect.bind(this);
        this.renderBagTypeSelect = this.renderBagTypeSelect.bind(this);
        this.renderQtyInPaletSelect = this.renderQtyInPaletSelect.bind(this);
        this.renderInvDataRow = this.renderInvDataRow.bind(this);
        this.cancel = this.cancel.bind(this);
        this.calcOthersTotal = this.calcOthersTotal.bind(this);
        this.calcPriceTotal = this.calcPriceTotal.bind(this);
    }

    calcPriceTotal() {
        const fv = this.state.formValues;
        let total = 0;
        for(let i = 0; i < 5; i++) {
            const price = parseFloat(this.state.formValues.get("item_price_" + i));
            if (price > 0) {
                fv.set("item_price_" + i, price.toFixed(2));
                total += price;
            }
        }
        // fv.set('inv_price', total.toFixed(2));
        this.setState({formValues: fv});
    }

    calcOthersTotal(idx: number) {
        let qty = this.state.formValues.get('others_qty_' + idx) * 1;
        if(isNaN(qty)) qty = 0;

        let price = this.state.formValues.get('others_price_' + idx) * 1;
        if(isNaN(price)) price = 0;

        const total = qty * price;
        this.state.formValues.set('others_total_' + idx, total);

        return total;
    }

    handleFormChange(event: any) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        const fv = this.state.formValues;
        fv.set(name, value);

        console.log(name, value);

        for (let idx = 0; idx < 5; idx++) {
            if ((name.localeCompare('palet_qty_' + idx) === 0) || (name.localeCompare('qty_in_palet_' + idx)) === 0) {
                const v1 = fv.get('palet_qty_' + idx) * 1;
                let v2 = fv.get('qty_in_palet_' + idx) * 1;
                if (v2 === -1) v2 = 0;

                if(v1 === 0) {
                    v2 = 0;
                    fv.set('palet_qty_' + idx, '');
                    fv.set('qty_in_palet_' + idx, '');
                }

                let total = v1 * v2;
                if (isNaN(total)) total = 0;
                fv.set('total_' + idx, total);
            }


        }
        this.calculatePelet(fv);
        this.setState({formValues: fv});
    }

    calculatePelet(fv: Map<string, any>) {
        let total = 0;
        for (let idx = 0; idx < 5; idx++) {
            const bagQty = fv.get('bag_qty_' + idx) * 1;
            const bagTypeIdx = fv.get('bag_type_' + idx) * 1;
            const bagType = (bagTypeIdx > -1) ? this.props.bag_types[bagTypeIdx] : null;
            const peletTypeIdx = fv.get('pelet_type_' + idx) * 1;
            const peletType = (peletTypeIdx > -1) ? this.props.pelet_types[peletTypeIdx] : null;

            let total_pelet = 0;
            if (bagType && peletType && !isNaN(bagQty)) {
                total_pelet = bagQty * peletType.description[bagType.description.peletPropertyName];
            }

            if (isNaN(total_pelet)) total_pelet = 0;
            fv.set('total_pelet_' + idx, total_pelet);
            total += total_pelet;
        }

        fv.set('total_pelet', total);
    }

    save() {
        API.saveExpeditionNote(
            (apiCall: API_CALL) => {

                this.setState({working: isLoading(apiCall)});

                if (isSuccess(apiCall)) {
                    this.props.onAfterSave(apiCall);
                }

                if (isError(apiCall)) {
                    showErrorsInToast(apiCall, "Данните не бяха запазени поради следните грешки:", translateError);
                }
            }, {
                ...mapToObject(this.state.formValues),
                sale_type: Object.keys(SALE_TYPE)[Object.values(SALE_TYPE).indexOf(this.state.formValues.get('sale_type') as SALE_TYPE)],
                saleId: this.props.sale.id || -1,
                payed_by_avans: this.state.formValues.get('payed_by_avans') ? 1 : 0
            }
        );
    }

    delete() {
        API.saveExpeditionNote(
            (apiCall: API_CALL) => {

                this.setState({working: isLoading(apiCall)});

                if (isSuccess(apiCall)) {
                    this.props.onAfterSave(apiCall);
                }

                if (isError(apiCall)) {
                    // this.setState({errors: })
                    console.log(apiCall);
                }
            }, {...mapToObject(this.state.formValues), saleId: this.props.sale.id || -1}
        );
    }

    cancel() {
        if (this.props.onCancel) {
            this.props.onCancel();
        }
    }

    renderSelect(key: string, values: PropertyValue[], isNumber = false) {
        return (
            <select name={key} value={this.state.formValues.get(key)} onChange={this.handleFormChange}
                    className={"form-control form-control-sm p-0 m-0"} dir={(isNumber ? 'rtl' : '')}>
                <option value={-1}></option>
                {
                    values.map(
                        (i: PropertyValue, idx: number) => <option key={idx} value={i.id}>{i.value}</option>
                    )
                }
            </select>
        )
    }

    renderBrickTypeSelect(key: string) {
        return this.renderSelect(key, this.props.brick_types);
    }

    renderBagTypeSelect(key: string) {
        return this.renderSelect(key, this.props.bag_types);
    }

    renderPeletTypeSelect(key: string) {
        return this.renderSelect(key, this.props.pelet_types);
    }

    renderQtyInPaletSelect(key: string) {
        return this.renderSelect(key, this.QtyInPalet, true);
    }

    onSelectClient(client: Client, city: City) {
        const filter = new Map<string, any>();
        filter.set('name', client.name);
        filter.set('cities', city.city);

        const fv = this.state.formValues;
        fv.set('client', client.name);
        fv.set('client_city', city.city);
        fv.set('client_phone', city.phone);

        this.setState({formValues: fv, client_filter: filter, showClientList: !this.state.showClientList});
    }

    onSelectLogistic(logistic: Logistic, driver: Driver) {
        const filter = new Map<string, any>();
        filter.set('name', logistic.name);
        filter.set('driver', driver.name);

        const fv = this.state.formValues;
        fv.set('currier', logistic.name);
        fv.set('driver', driver.name);
        fv.set('driver_phone', driver.phone);
        fv.set('license_plate', driver.reg_nomer);

        this.setState({formValues: fv, logistic_filter: filter, showLogisticList: !this.state.showLogisticList});
    }

    renderInvDataRow() {
        return <table className={"table table-sm table-bordered table-no-padding w-100"}>
            <thead className={"text-center"}>
            <tr>
                <th>Тип плащане:</th>
                <th>Фактура No:</th>
                <th>Всичко цена</th>
                <th>Вкл.транспорт</th>
                <th>Платено дата</th>
                <th>Платено сума</th>
            </tr>
            </thead>
            <tbody>
            <tr>
                <td>
                    <select name={"pay_type"}
                            value={this.state.formValues.get("pay_type")}
                            className={"form-control w-100"}
                            onChange={this.handleFormChange}>
                        <option value={PAY_TYPE.BANKA}>БАНКА</option>
                        <option value={PAY_TYPE.CASH}>КЕШ</option>
                    </select>
                </td>
                <td>
                    <input type="text" className={"form-control w-100"}
                           name={"inv_number"}
                           value={this.state.formValues.get("inv_number")}
                           onChange={this.handleFormChange}
                    />
                </td>
                <td>
                    <input type="text" className={"form-control w-100"}
                           name={"inv_price"}
                           value={this.state.formValues.get("inv_price")}
                           onChange={this.handleFormChange}
                    />
                </td>
                <td>
                    <div className="input-group flex-nowrap w-100">
                        <input type="text" className={"form-control w-100"}
                               name={"price"}
                               value={this.state.formValues.get("price")}
                               onChange={this.handleFormChange}
                        />
                    </div>
                </td>
                <td className={"w-180px"}>

                    <form className="form-inline">
                        <div className={"text-nowrap row lh-2em "}>
                            <div className={"col-auto w-160px"}>
                                <ReadOnlyDatePickerComponent
                                    classes={"ml-0 pl-0"}
                                    value={this.state.formValues.get('inv_payed_date')}
                                    onChange={(date: Date) => {
                                        this.setState({formValues: this.state.formValues.set('inv_payed_date', moment(date).format('DD.MM.YYYY'))});
                                    }}
                                    hideDayControls={true}
                                />
                            </div>
                        </div>
                    </form>
                </td>
                <td>
                    <input type="text" className={"form-control w-100"}
                           name={"inv_payed"}
                           value={this.state.formValues.get("inv_payed")}
                           onChange={this.handleFormChange}
                    />
                </td>
            </tr>
            </tbody>
        </table>
    }

    render() {
        return (
            <>
                <div className={"modal overflow-auto"} style={{display: "block"}}>
                    <div className="modal-dialog modal-xl">
                        <div className="modal-content bg-light shadow">
                            <div className="modal-header">
                                <h5 className="modal-title">Експедиционна бележка {this.state.formValues.get('sales_number')}</h5>
                                <span className={"pointer"} onClick={this.cancel}><FontAwesomeIcon icon="window-close"/></span>
                            </div>
                            <div className="modal-body">

                                <form>
                                    <div className={"row"}>

                                        <div className="mb-3 pr-0 col-2">
                                            <form className="form-inline">
                                                <div className={"text-nowrap row lh-2em "}>
                                                    <div className={"col-auto w-120px"}>Дата:&nbsp;</div>
                                                    <div className={"col-auto w-160px"}>
                                                        <ReadOnlyDatePickerComponent
                                                            classes={"ml-0 pl-0"}
                                                            value={this.state.formValues.get('sales_date')}
                                                            onChange={(date: Date) => {
                                                                this.setState({formValues: this.state.formValues.set('sales_date', moment(date).format('DD.MM.YYYY'))});
                                                            }}
                                                            hideDayControls={true}
                                                        />
                                                    </div>
                                                </div>
                                            </form>
                                        </div>

                                        <div className="input-group mb-3 pr-0 col-2">
                                            <label className="form-label">Вид</label>
                                            <div className="input-group">
                                                <Form.Control as="select"
                                                              value={this.state.formValues.get('sale_type')}
                                                              onChange={(v) => {
                                                                  this.setState({formValues: this.state.formValues.set('sale_type', v.target.value)})
                                                              }}
                                                >
                                                    <option value={SALE_TYPE.bricks_w_env}>{SALE_TYPE.bricks_w_env}</option>
                                                    <option value={SALE_TYPE.bricks_wo_env}>{SALE_TYPE.bricks_wo_env}</option>
                                                    <option value={SALE_TYPE.peleti_w_env}>{SALE_TYPE.peleti_w_env}</option>
                                                    <option value={SALE_TYPE.peleti_wo_env}>{SALE_TYPE.peleti_wo_env}</option>
                                                    <option value={SALE_TYPE.others}>{SALE_TYPE.others}</option>
                                                </Form.Control>
                                            </div>
                                        </div>

                                        <div className="input-group mb-3 pr-0 col-3">
                                            <label className="form-label">Клиент</label>
                                            <div className="input-group">
                                                <input type="text" className="form-control" name={"client"} onChange={this.handleFormChange}
                                                       value={this.state.formValues.get("client")}/>

                                                <Dropdown show={this.state.showClientList}>
                                                    <DropdownToggle onClick={() => {
                                                        this.setState({showClientList: !this.state.showClientList})
                                                    }}>
                                                    </DropdownToggle>
                                                </Dropdown>

                                                {
                                                    this.state.showClientList &&
                                                    <Modal
                                                        show={true}
                                                        centered
                                                        onHide={() => {
                                                            this.setState({showClientList: !this.state.showClientList})
                                                        }}
                                                        size={"xl"}
                                                    >
                                                        <Modal.Header closeButton>
                                                            <Modal.Title>Изберете клиент</Modal.Title>
                                                        </Modal.Header>

                                                        <Modal.Body>
                                                            <div className={"p-3"}>
                                                                <ClientsPage
                                                                    filter={this.state.client_filter}
                                                                    onSelect={this.onSelectClient}
                                                                    onClose={() => this.setState({showClientList: !this.state.showClientList})}
                                                                />
                                                            </div>
                                                        </Modal.Body>
                                                    </Modal>
                                                }

                                            </div>
                                        </div>

                                        <div className="mb-3 pr-0 col-3">
                                            <label className="form-label">Град</label>
                                            <input type="text" className="form-control" name={"client_city"} onChange={this.handleFormChange}
                                                   value={this.state.formValues.get("client_city")}/>
                                        </div>

                                        <div className="col-2 mb-2">
                                            <label className="form-label">Телефон</label>
                                            <input type="text" className="form-control" name={"client_phone"} onChange={this.handleFormChange}
                                                   value={this.state.formValues.get("client_phone")}/>
                                        </div>

                                    </div>

                                    {
                                        (this.state.formValues.get('sale_type') === SALE_TYPE.bricks_w_env ||
                                            this.state.formValues.get('sale_type') === SALE_TYPE.bricks_wo_env) &&

                                        <div className={"row"}>
                                            <div className={"col"}>
                                                <table className={"table table-hover table-sm table-no-padding"}>
                                                    <thead className={"bg-secondary text-light"}>
                                                    <tr>
                                                        <th className={"w-100px text-right"}>No:</th>
                                                        <th>Наименование на стоката</th>
                                                        <th className={"w-180px text-right"}>Палети</th>
                                                        <th className={"w-180px text-right"}>Брой в палет</th>
                                                        <th className={"w-180px text-right"}>Количество(бр.)</th>
                                                        <th className={"w-180px text-right"}>Цена</th>
                                                    </tr>
                                                    </thead>
                                                    <tbody>
                                                    {
                                                        [0, 1, 2, 3, 4].map(
                                                            idx =>
                                                                <tr key={idx}>
                                                                    <td className={"text-right"}>{idx + 1}&nbsp;</td>
                                                                    <td>
                                                                        {this.renderBrickTypeSelect('property_id_' + idx)}
                                                                    </td>
                                                                    <td>
                                                                        {
                                                                            // (this.state.formValues.get('property_id_' + idx) !== '999') &&
                                                                            <input type="number" className="form-control form-control-sm text-right"
                                                                                   name={"palet_qty_" + idx}
                                                                                   value={this.state.formValues.get("palet_qty_" + idx)}
                                                                                   onChange={this.handleFormChange}/>
                                                                        }
                                                                    </td>
                                                                    <td className={"text-right"}>
                                                                        {
                                                                            ((this.state.formValues.get('property_id_' + idx) !== '999') ||
                                                                            (this.state.formValues.get("palet_qty_" + idx) *1 > 0)) &&
                                                                            this.renderQtyInPaletSelect('qty_in_palet_' + idx)
                                                                        }
                                                                    </td>
                                                                    <td className={"text-right"}>
                                                                        {
                                                                            (this.state.formValues.get('property_id_' + idx) !== '999') ||
                                                                            (this.state.formValues.get("palet_qty_" + idx) *1 > 0) ?
                                                                                <span className={"pr-3"}
                                                                                      style={{lineHeight: "32px"}}>{this.state.formValues.get("total_" + idx)}</span>
                                                                                :
                                                                                <input type="number" className="form-control form-control-sm text-right p-0 m-0"
                                                                                       name={"total_" + idx}
                                                                                       value={this.state.formValues.get("total_" + idx)}
                                                                                       onChange={this.handleFormChange}/>
                                                                        }
                                                                    </td>
                                                                    <td>
                                                                        <input type="number" className="form-control form-control-sm text-right"
                                                                               name={"item_price_" + idx}
                                                                               value={this.state.formValues.get("item_price_" + idx)}
                                                                               onBlur={() => this.calcPriceTotal()}
                                                                               onChange={this.handleFormChange}/>
                                                                    </td>
                                                                </tr>
                                                        )
                                                    }
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    }

                                    {
                                        (this.state.formValues.get('sale_type') === SALE_TYPE.peleti_w_env ||
                                            this.state.formValues.get('sale_type') === SALE_TYPE.peleti_wo_env) &&

                                        <div className={"row"}>
                                            <div className={"col"}>
                                                <table className={"table table-hover table-sm table-no-padding"}>
                                                    <thead className={"bg-secondary text-light"}>
                                                    <tr>
                                                        <th className={"w-100px text-right"}>No:</th>
                                                        <th>Пелети</th>
                                                        <th>М/Е</th>
                                                        <th className={"w-180px text-right"}>Брой</th>
                                                        <th className={"w-180px text-right"}>Килограми</th>
                                                        <th className={"w-180px text-right"}>Цена</th>
                                                    </tr>
                                                    </thead>
                                                    <tbody>
                                                    {
                                                        [0, 1, 2, 3, 4].map(
                                                            idx =>
                                                                <tr key={idx}>
                                                                    <td className={"text-right"}>{idx + 1}&nbsp;</td>
                                                                    <td>
                                                                        {this.renderPeletTypeSelect('pelet_type_' + idx)}
                                                                    </td>
                                                                    <td className={"text-right"}>
                                                                        {this.renderBagTypeSelect('bag_type_' + idx)}
                                                                    </td>
                                                                    <td>
                                                                        <input type="number" className="form-control form-control-sm text-right"
                                                                               name={"bag_qty_" + idx}
                                                                               value={this.state.formValues.get("bag_qty_" + idx)}
                                                                               onChange={this.handleFormChange}/>
                                                                    </td>
                                                                    <td className={"text-right"}>{this.state.formValues.get("total_pelet_" + idx)}&nbsp;</td>
                                                                    <td>
                                                                        <input type="number" className="form-control form-control-sm text-right"
                                                                               name={"item_price_" + idx}
                                                                               value={this.state.formValues.get("item_price_" + idx)}
                                                                               onBlur={() =>{
                                                                                   this.setState(
                                                                                       {formValues: this.state.formValues.set("item_price_" + idx,
                                                                                               parseFloat(this.state.formValues.get("item_price_" + idx)).toFixed(2))})}
                                                                               }
                                                                               onChange={this.handleFormChange}/>
                                                                    </td>
                                                                </tr>
                                                        )
                                                    }


                                                    {/*<tr className={"bg-secondary text-light "}>*/}
                                                    {/*    <td></td>*/}
                                                    {/*    <td colSpan={3}>Всичко пелети (кг)</td>*/}
                                                    {/*    <td className={"text-right"}>{this.state.formValues.get('total_pelet')}</td>*/}
                                                    {/*</tr>*/}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    }
                                    {
                                        this.state.formValues.get('sale_type') === SALE_TYPE.others &&
                                        <div className={"row"}>
                                            <div className={"col"}>
                                                <table className={"table table-hover table-sm table-no-padding"}>
                                                    <thead className={"bg-secondary text-light"}>
                                                    <tr>
                                                        <th className={"w-100px text-right"}>No:</th>
                                                        <th>Артикул</th>
                                                        <th className={"w-120px"}>М/Е</th>
                                                        <th className={"w-120px text-right"}>Количество</th>
                                                        <th className={"w-120px text-right"}>Ед.Цена</th>
                                                        <th className={"w-120px text-right"}>Общо</th>
                                                    </tr>
                                                    </thead>
                                                    <tbody>
                                                    {
                                                        [0, 1, 2, 3, 4].map(
                                                            idx =>
                                                                <tr key={idx}>
                                                                    <td className={"text-right"}>{idx + 1}&nbsp;</td>
                                                                    <td>
                                                                        <input className="form-control form-control-sm"
                                                                               name={"others_" + idx}
                                                                               value={this.state.formValues.get("others_" + idx)}
                                                                               onChange={this.handleFormChange}/>
                                                                    </td>
                                                                    <td>
                                                                        <input className="form-control form-control-sm"
                                                                               name={"others_me_" + idx}
                                                                               value={this.state.formValues.get("others_me_" + idx)}
                                                                               onChange={this.handleFormChange}/>
                                                                    </td>
                                                                    <td>
                                                                        <input type="number" className="form-control form-control-sm text-right"
                                                                               name={"others_qty_" + idx}
                                                                               value={this.state.formValues.get("others_qty_" + idx)}
                                                                               onChange={this.handleFormChange}/>
                                                                    </td>
                                                                    <td className={"text-right"}>
                                                                        <input type="number" className="form-control form-control-sm text-right"
                                                                               name={"others_price_" + idx}
                                                                               value={this.state.formValues.get("others_price_" + idx)}
                                                                               onChange={this.handleFormChange}/>
                                                                    </td>
                                                                    <td className={"text-right"}>{this.calcOthersTotal(idx)}</td>
                                                                </tr>
                                                        )
                                                    }
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    }

                                    <div className={"row"}>
                                        <div className={"col"}>
                                            <table className={"table table-hover table-sm table-no-padding"}>
                                                <tbody>
                                                <tr className={"bg-secondary text-light"}>
                                                    <td className={"w-100px text-right"}></td>
                                                    <td colSpan={3}>Върнати палети</td>
                                                    <td className={"w-180px text-right"}>
                                                        <input type="number" className="form-control form-control-sm text-right"
                                                               name={"returned"}
                                                               value={this.state.formValues.get("returned")}
                                                               onChange={this.handleFormChange}/>
                                                    </td>
                                                </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>


                                    <div className={"row"}>
                                        <div className="input-group mb-3 pr-0 col-3">
                                            <label className="form-label">Фирма превозвач:</label>
                                            <div className="input-group">
                                                <input type="text" className="form-control" name={"currier"} onChange={this.handleFormChange}
                                                       value={this.state.formValues.get("currier")}/>

                                                <Dropdown show={this.state.showLogisticList}>
                                                    <DropdownToggle onClick={() => {
                                                        this.setState({showLogisticList: !this.state.showLogisticList})
                                                    }}>
                                                    </DropdownToggle>
                                                </Dropdown>

                                                {
                                                    this.state.showLogisticList &&
                                                    <Modal
                                                        show={true}
                                                        centered
                                                        onHide={() => {
                                                            this.setState({showLogisticList: !this.state.showLogisticList})
                                                        }}
                                                        size={"xl"}
                                                    >
                                                        <Modal.Header closeButton>
                                                            <Modal.Title>Изберете транспортна фирма</Modal.Title>
                                                        </Modal.Header>

                                                        <Modal.Body>
                                                            <div className={"p-3"}>
                                                                <LogisticsPage
                                                                    filter={this.state.logistic_filter}
                                                                    onSelect={this.onSelectLogistic}
                                                                    onClose={() => this.setState({showLogisticList: !this.state.showLogisticList})}
                                                                />
                                                            </div>
                                                        </Modal.Body>
                                                    </Modal>
                                                }

                                            </div>
                                        </div>

                                        <div className="mb-3 col-3">
                                            <label className="form-label">Стоката е получена от:</label>
                                            <input type="text" className="form-control" name={"driver"} onChange={this.handleFormChange}
                                                   value={this.state.formValues.get("driver")}/>
                                        </div>

                                        <div className="mb-3 col-2">
                                            <label className="form-label">Телефон</label>
                                            <input type="text" className="form-control" name={"driver_phone"} onChange={this.handleFormChange}
                                                   value={this.state.formValues.get("driver_phone")}/>
                                        </div>

                                        <div className="mb-3 col-2">
                                            <label className="form-label">Кола номер</label>
                                            <input type="text" className="form-control" name={"license_plate"} onChange={this.handleFormChange}
                                                   value={this.state.formValues.get("license_plate")}/>
                                        </div>

                                        <div className="input-group mb-3 col-2">
                                            <label className="form-label">Началник склад</label>
                                            <div className="input-group">
                                                <input type="text" className="form-control" name={"store_manager"} onChange={this.handleFormChange}
                                                       value={this.state.formValues.get("store_manager")}/>
                                            </div>
                                        </div>

                                    </div>

                                    <div className={"row"}>
                                        <BlockTitleComponent title={"ПРИЕМО-ПРЕДАВАТЕЛЕН ПРОТОКОЛ"}/>
                                        <div className="col-4 mb-2">
                                            <label className="form-label">Натоварил стоката:</label>
                                            <input type="text" className="form-control" name={"loader_name"} onChange={this.handleFormChange}
                                                   value={this.state.formValues.get("loader_name")}/>
                                        </div>

                                        <div className="mb-3 col-4">
                                            <label className="form-label">Предал стоката</label>
                                            <input type="text" className="form-control" name={"provider_name"} onChange={this.handleFormChange}
                                                   value={this.state.formValues.get("provider_name")}/>
                                        </div>
                                    </div>
                                    <div className={"row"}>
                                        <BlockTitleComponent title={"ТРАНСПОРТ И ФАКТУРИРАНЕ"}/>
                                        <div className="col">
                                            {this.renderInvDataRow()}
                                        </div>
                                    </div>


                                </form>

                            </div>
                            <div className="modal-footer">
                                {
                                    this.state.working ?
                                        <div className="spinner-border" role="status">
                                            <span className="sr-only">Loading...</span>
                                        </div>
                                        :
                                        <>
                                            <div className="form-check form-check-inline mr-3">
                                                <input className="form-check-input" type="checkbox" name={"payed_by_avans"} id={"payed_by_avans"}
                                                       onChange={this.handleFormChange}
                                                       checked={(this.state.formValues.get("payed_by_avans") == true) || (this.state.formValues.get("payed_by_avans") == "1")}/>
                                                <label className="form-check-label" htmlFor={"payed_by_avans"}>
                                                    &nbsp;Платено от аванс&nbsp;&nbsp;
                                                </label>
                                            </div>

                                            <button type="button" className="btn btn-primary" onClick={this.save.bind(this)}>Запази</button>
                                            <button type="button" className="btn btn-secondary" onClick={this.cancel.bind(this)}>Откажи</button>
                                        </>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}
