import React from "react";
import {API_CALL, OrderingReport, PARAMETERS, PAY_TYPE, PropertyValue, Sale, SaleItem} from "../types";
import {bagTypeTitle, isError, isLoading, isSuccess, showErrorsInToast, showMsgInToast, translateError, zeroToEmptyString, zeroToEmptyStringAsCurrency} from "../common";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import moment, {Moment} from "moment";
import {API} from "../api";
import {Link} from "react-router-dom";
import {PATHS} from "../paths";
import {faArrowLeft} from "@fortawesome/free-solid-svg-icons";
import {ReadOnlyDatePickerComponent} from "../components/common/ReadOnlyDatePickerComponent";
import {BlockTitleComponent} from "../components/common/BlockTitleComponent";
import {ExpNotesList} from "../components/daily-reports/ExpNotesList";
import {BankExpensesEditModal} from "../components/bank-exp/BankExpensesEditModal";

interface BankExpensesPageProps {
    selectedDate?: Moment;
}

interface BankExpensesPageState {
    loading: boolean,
    editMode: boolean,
    editTypeId: number,
    editYear: number,
    editMonth: number,
    editValue: number,
    expenses: any,
    types: any,
    formValues: Map<string, any>,
}

export class BankExpensesPage extends React.Component<BankExpensesPageProps, BankExpensesPageState> {
    yearsList: number[] = [];
    monthsList: string[] = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'];
    monthsCount: number[] = [6, 12, 24, 36, 48];

    constructor(props: any) {
        super(props);

        for (let i = new Date().getFullYear(); i > 2018; i--) this.yearsList.push(i);

        const fv = new Map<string, any>();
        fv.set('fromYear', moment().year());
        fv.set('fromMonth', 12);
        fv.set('monthCount', 12);

        this.state = {
            loading: true,
            editMode: false,
            editYear: 0,
            editMonth: 0,
            editTypeId: 0,
            editValue: 0,
            expenses: {},
            types: {},
            formValues: fv,
        }

        this.handleFormChange = this.handleFormChange.bind(this);
        this.loadReports = this.loadReports.bind(this);
        this.editValue = this.editValue.bind(this);
        this.saveValue = this.saveValue.bind(this);
    }

    componentDidMount() {
        this.loadReports();
    }

    loadReports() {
        API.getBankExpenses(
            (apiCall: API_CALL) => {
                this.setState({loading: isLoading(apiCall)});

                if (isError(apiCall)) showErrorsInToast(apiCall, 'Грешка', translateError);

                if (isSuccess(apiCall)) {
                    this.setState({expenses: apiCall.data.data.expenses, types: apiCall.data.data.types});
                }
            },
            this.state.formValues.get('fromYear'),
            this.state.formValues.get('fromMonth'),
            this.state.formValues.get('monthCount')
        );
    }

    handleFormChange(event: any) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState(
            prevState => {
                return {formValues: prevState.formValues.set(name, value)}
            }
        )
    }

    editValue(expName: string, y: string, m: string, value: string) {
        this.setState({
            editMode: true,
            editYear: Number(y),
            editMonth: Number(m),
            editTypeId: Number(expName),
            editValue: Number(value)
        })
    }

    saveValue(newValue: number) {
        this.setState({editMode: false});
        API.setBankExpenses(
            (apiCall: API_CALL) => {
                this.setState({loading: isLoading(apiCall)});

                if (isSuccess(apiCall)) {
                    this.loadReports();
                }
            },
            {
                'exp_type': this.state.editTypeId,
                'exp_year': this.state.editYear,
                'exp_month': this.state.editMonth,
                'exp_value': newValue
            }
        );
    }

    render() {

        const renderHeader = function (expenses: any) {
            if (!expenses || Object.keys(expenses).length === 0) return '';

            const types = Object.keys(expenses);
            const years = Object.keys(expenses[types[0]]);
            const firstType = expenses[types[0]];

            return (
                <>
                    <thead className={"text-center"}>
                    <tr>
                        <th rowSpan={2}>Група</th>
                        {
                            years.map(
                                (y: any) => <th key={y} colSpan={Object.keys(firstType[y]).length}>{y}</th>
                            )
                        }

                        <th rowSpan={2}>Всичко</th>
                    </tr>
                    <tr>
                        {
                            years.map(
                                (y: any) => {
                                    return Object.keys(firstType[y]).sort((a, b) => {
                                        return a.localeCompare(b);
                                    }).map(
                                        (m: any) => <th key={y + '.' + m}>{m}</th>
                                    )
                                }
                            )
                        }
                    </tr>
                    </thead>
                </>
            )
        }

        const renderExpYears = function (expenses: any, type: any, editValue: any, me: BankExpensesPage) {
            let expTotal = 0;
            const cells: any = [];
            const typeId = type.id;;
            const exp = expenses[typeId];
            const years = Object.keys(exp);
            const parent = type.parent_id === 0 ? null : me.state.types[type.parent_id];

            years.forEach(
                (y: any) => {
                    const months = Object.keys(exp[y]);
                    months.sort((a, b) => a.localeCompare(b))
                        .forEach(
                            (m: any) => {
                                cells.push(
                                    <td className={"text-right"} key={'cell_' + y + '.' + m}
                                        onClick={() => (parent === null ? {} : editValue(type.id, y, m, exp[y][m]))}>
                                        {zeroToEmptyStringAsCurrency(exp[y][m])}
                                    </td>);
                                expTotal += exp[y][m] * 1;
                            }
                        )
                }
            );
            cells.push(<td className={"font-weight-bold text-right"}>{zeroToEmptyStringAsCurrency(expTotal.toString())}</td>)
            return cells;
        }

        const getRowClasses = function (_type: any) {
            if(_type.id === 999) return "bg-secondary text-light font-weight-bold";

            if(_type.parent_id == 0) return "bg-secondary-light font-weight-bold";

            return "";
        }

        return (
            <div className="container" style={{maxWidth: "100%"}}>

                <div className="card shadow">

                    <div className="card-header text-center row">
                        <div className="col-2 text-left">
                            {
                                this.state.loading ?
                                    <div className="spinner-border" role="status">
                                        <span className="sr-only">Loading...</span>
                                    </div>
                                    :
                                    <Link to={PATHS.home + API.getAPITokenAsQuery()}>
                                        <span className="btn btn-secondary"><FontAwesomeIcon icon={faArrowLeft}/></span></Link>
                            }

                        </div>
                        <h2 className="col">Разходи по банка</h2>
                        <div className="col-2">
                        </div>
                    </div>

                    <div className="card-body">
                        <div className="row">
                            <div className="col">
                                {
                                    <>
                                        <div className="card bg-light p-3 mb-3">
                                            <form className="form-inline">
                                                <div className={"text-nowrap row lh-2em "}>
                                                    <div className={"col-auto w-140px"}>От месец/година:&nbsp;</div>
                                                    <div className={"col-auto w-180px"}>
                                                        <select className="form-control"
                                                                onChange={e => this.state.formValues.set('fromMonth', Number(e.target.value))}>
                                                            {
                                                                this.monthsList.map(m =>
                                                                    <option value={m} key={m}
                                                                            selected={Number(m) === this.state.formValues.get('fromMonth')}>{m}</option>
                                                                )
                                                            }
                                                        </select>
                                                        <select className="form-control"
                                                                onChange={e => this.state.formValues.set('fromYear', Number(e.target.value))}>
                                                            {this.yearsList.map(y =>
                                                                <option value={y} key={y}
                                                                        selected={Number(y) === this.state.formValues.get('fromYear')}>{y}</option>)}
                                                        </select>
                                                    </div>
                                                    <div className={"col-auto w-160px"}>Брой месеци назад:&nbsp;</div>
                                                    <div className={"col-auto w-120px"}>
                                                        <select className="form-control"
                                                                onChange={e => this.state.formValues.set('monthCount', Number(e.target.value))}>
                                                            {
                                                                this.monthsCount.map(m =>
                                                                    <option value={m} key={m}
                                                                            selected={Number(m) === this.state.formValues.get('monthCount')}>{m}</option>
                                                                )
                                                            }
                                                        </select>
                                                    </div>
                                                    <div className={"col-auto w-120px"}>
                                                        <button className="btn btn-primary" onClick={() => this.loadReports()} type={"button"}>Покажи</button>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </>
                                }


                                <div className={"w-100 overflow-auto"}>
                                    <table className={"table table-bordered w-100 " +
                                    (this.state.formValues.get('monthCount') > 24 ? 'table-sm' : '')}
                                           style={{opacity: (this.state.loading ? 0.3 : 1) }}
                                    >
                                        {
                                            renderHeader(this.state.expenses)
                                        }

                                        <tbody>
                                        {
                                            Object.values(this.state.types).map(
                                                (_type: any) => {
                                                    const exp = this.state.expenses[_type.id];

                                                    return (
                                                        <tr
                                                            className={getRowClasses(_type)}>
                                                            <td>{_type.name}</td>
                                                            {
                                                                renderExpYears(this.state.expenses, _type, this.editValue, this)
                                                            }
                                                        </tr>
                                                    )
                                                }
                                            )
                                        }
                                        </tbody>
                                    </table>
                                </div>

                            </div>

                        </div>

                    </div>

                    <div className="card-footer">

                    </div>
                </div>

                {
                    this.state.editMode &&
                    <BankExpensesEditModal
                        typeId={this.state.editTypeId}
                        year={this.state.editYear}
                        month={this.state.editMonth}
                        value={this.state.editValue}
                        onSave={(newValue: number) => {
                            this.saveValue(newValue)
                        }}
                        onClose={() => this.setState({editMode: false})}
                    />
                }

            </div>
        );
    }

}
