import React from "react";
import {API_CALL, Driver, Logistic} from "../types";
import {Link} from "react-router-dom";
import {PATHS} from "../paths";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowLeft} from "@fortawesome/free-solid-svg-icons";
import {API} from "../api";
import {isError, isLoading, isSuccess, mapToObject, showErrorsInToast} from "../common";
import {Pager} from "../components/common/Pager";
import {ConfirmDeleteModal} from "../common/ConfirmDeleteModal";
import {LogisticsList} from "../components/logistics/LogisticsList";
import {EditLogisticModal} from "../components/logistics/EditLogisticModal";


interface LogisticsPageProps {
    filter?: any;
    onSelect?: (logistic: Logistic, driver: Driver) => any;
    onClose?: () => any;
}

interface LogisticsPageState {
    logistics: Logistic[],
    pageCount: number,
    currentPage: number,
    loading: boolean,
    formValues: Map<string, any>;
    editLogistic: Logistic | null;
    dialogConfirmDeleteVisible: boolean;
    filter: Map<string, any>;
}

export class LogisticsPage extends React.Component<LogisticsPageProps, LogisticsPageState> {
    private selectedLogistic: Logistic | null = null;

    constructor(props: any) {
        super(props);
        this.state = {
            logistics: [],
            pageCount: 0,
            currentPage: 0,
            loading: false,
            formValues: new Map<string, any>(),
            filter: new Map<string, any>(props.filter),
            editLogistic: null,
            dialogConfirmDeleteVisible: false,
        };

        this.handleFormChange = this.handleFormChange.bind(this);
        this.clearFilter = this.clearFilter.bind(this);
        this.applyFilter = this.applyFilter.bind(this);
        this.createNewLogistic = this.createNewLogistic.bind(this);
        this.saveLogistic = this.saveLogistic.bind(this);
        this.editLogistic = this.editLogistic.bind(this);
        this.removeLogistic = this.removeLogistic.bind(this);
        this.doRemoveLogistic = this.doRemoveLogistic.bind(this);
    }


    handleFormChange(event: any) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState(
            prevState => {
                return {formValues: prevState.formValues.set(name, value)}
            }
        );
    }


    componentDidMount() {
        this.loadData();
    }

    loadData(pageIdx: number = 0) {
        API.getLogistics(
            (apiCall: API_CALL) => {
                this.setState({loading: isLoading(apiCall)});
                if (isSuccess(apiCall)) {
                    this.setState({
                        logistics: apiCall.data.data.logistics.map(
                            (i: any) => {
                                return {...i, drivers: JSON.parse(i.drivers)} as Driver
                            }
                        ),
                        pageCount: apiCall.data.data.pages,
                        currentPage: apiCall.data.data.page,
                    });
                }
            }, mapToObject(this.state.filter), pageIdx
        );
    }

    clearFilter() {
        const fv = this.state.formValues;
        fv.set("sales_number", '');
        fv.set("sales_date", '');
        fv.set("client", '');
        fv.set("client_city", '');
        fv.set("store_manager", '');
        fv.set("currier", '');

        this.setState({formValues: this.state.formValues.set('sales_number', '')});
    }

    saveLogistic(logistic: Logistic) {
        this.setState({editLogistic: null});
        this.loadData(this.state.currentPage);
    }

    createNewLogistic() {
        this.setState({editLogistic: {id: -1, name: '', drivers: []}});
    }

    editLogistic(logistic: Logistic) {
        this.setState({editLogistic: logistic});
    }

    removeLogistic(logistic: Logistic) {
        this.selectedLogistic = logistic;
        this.setState({dialogConfirmDeleteVisible: true});
    }

    doRemoveLogistic(logistic: Logistic | null) {
        if (logistic)
            API.deleteLogistic(
                (apiCall: API_CALL) => {
                    const _isLoading = isLoading(apiCall);
                    this.setState({loading: _isLoading, dialogConfirmDeleteVisible: _isLoading});
                    if (isError(apiCall)) {
                        showErrorsInToast(apiCall, 'Грешка');
                    }
                    if (isSuccess(apiCall)) this.loadData(this.state.currentPage);
                }, logistic.id
            );
    }

    onPageChange(newPage: number) {
        this.setState({currentPage: newPage});
        this.loadData(newPage);
    }

    applyFilter(filter: Map<string, any>) {
        this.state.filter.set('name', filter.get('name') || '');
        this.state.filter.set('drivers', filter.get('driver') || '');
        this.loadData(0);
    }

    render() {

        return (
            <>
                <div className="container">
                    <div className="card shadow w-100">
                        <div className="card-header text-center row">
                            <div className="col-2 text-left">
                                {
                                    this.props.onClose ?
                                        <a onClick={this.props.onClose}>
                                            <span className="btn btn-secondary"><FontAwesomeIcon icon={faArrowLeft}/></span>
                                        </a>
                                        :
                                        <Link to={PATHS.home + API.getAPITokenAsQuery()}>
                                            <span className="btn btn-secondary"><FontAwesomeIcon icon={faArrowLeft}/></span></Link>
                                }
                            </div>
                            <h2 className="col">Транспортни фирми</h2>
                            <div className="col-2">
                                <a className={"btn btn-secondary"} onClick={this.createNewLogistic}><FontAwesomeIcon icon={"plus"}/> Добави</a>
                            </div>
                        </div>

                        <div className="card-body">
                            <div className={"row"}>
                                <div className={"col"}>
                                    <LogisticsList
                                        filter={this.props.filter}
                                        loading={this.state.loading}
                                        logistics={this.state.logistics}
                                        onEditLogistic={this.editLogistic}
                                        onRemoveLogistic={this.removeLogistic}
                                        onSelect={this.props.onSelect}
                                        onApplyFilter={this.applyFilter}
                                    />
                                </div>
                            </div>
                            {
                                this.state.pageCount > 0 &&
                                <div className={"row"}>
                                    <div className={"col"}>
                                        <Pager currentPage={this.state.currentPage}
                                               pageCount={this.state.pageCount}
                                               onChange={this.onPageChange.bind(this)}
                                               disabled={this.state.loading}
                                        />
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                </div>

                {
                    this.state.editLogistic &&
                    <EditLogisticModal
                        logistic={this.state.editLogistic}
                        onSave={logistic => this.saveLogistic(logistic)}
                        onClose={() => {
                            this.setState({editLogistic: null})
                        }}/>
                }

                {
                    this.state.dialogConfirmDeleteVisible &&
                    <ConfirmDeleteModal
                        working={this.state.loading}
                        onConfirm={() => this.doRemoveLogistic(this.selectedLogistic)}
                        onReject={() => this.setState({dialogConfirmDeleteVisible: false})}
                    />
                }
            </>
        );
    }

}
