import React from "react";
import {API_CALL, Client, PARAMETERS, PAY_TYPE, PropertyValue, Sale, SALE_TYPE} from "../types";
import {Link} from "react-router-dom";
import {PATHS} from "../paths";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowLeft} from "@fortawesome/free-solid-svg-icons";
import {SaleDetailsModalComponent} from "../components/sales/SaleDetailsModalComponent";
import {API} from "../api";
import {isError, isLoading, isSuccess, mapToObject, nullsToEmptyString, showErrorsInToast, translateError} from "../common";
import {Pager} from "../components/common/Pager";
import moment from "moment";
import {Dropdown, Tab, Tabs} from "react-bootstrap";
import {ReadOnlyDatePickerComponent} from "../components/common/ReadOnlyDatePickerComponent";
import {ConfirmDeleteModal} from "../common/ConfirmDeleteModal";
import {EditClientAccountModal} from "../components/clients/EditClientAccountModal";

interface SalesPageState {
    sales: Sale[],
    saleType: SALE_TYPE | null,
    pageCount: number,
    currentPage: number,
    editSale: Sale | null,
    bricks: PropertyValue[],
    pelet_types: PropertyValue[];
    bag_types: PropertyValue[];
    loading: number,
    working: boolean,
    formValues: Map<string, any>;
    dialogConfirmDeleteVisible: boolean;
    editClientAccount: Client | null;
}

export class SalesPage extends React.Component<any, SalesPageState> {
    selectedSale: number = -1;
    only_not_paid: boolean = false;

    constructor(props: any) {
        super(props);
        this.state = {
            editSale: null,
            saleType: null,
            sales: [],
            pageCount: 0,
            currentPage: 0,
            bricks: [],
            pelet_types: [],
            bag_types: [],
            loading: 0,
            working: false,
            formValues: new Map<string, any>(),
            dialogConfirmDeleteVisible: false,
            editClientAccount: null,
        };
        this.state.formValues.set("sales_number", '');
        this.handleFormChange = this.handleFormChange.bind(this);
        this.renderData = this.renderData.bind(this);
        this.clearFilter = this.clearFilter.bind(this);
        this.createNewSale = this.createNewSale.bind(this);
        this.viewClientAccount = this.viewClientAccount.bind(this);
        this.showNotPaid = this.showNotPaid.bind(this);

        this.clearFilter(true);
    }


    handleFormChange(event: any) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState(
            prevState => {
                return {formValues: prevState.formValues.set(name, value)}
            }
        )
    }


    componentDidMount() {
        this.loadParams();
        this.loadData();
    }

    loadParams() {
        this.setState({loading: this.state.loading + 3});
        API.getParameterValues(
            (apiCall: API_CALL) => {
                if (isSuccess(apiCall)) {
                    const bricks: PropertyValue[] = apiCall.data.data.items?.values;
                    bricks.push({
                        id: 999,
                        value: 'ТУХЛА ВТОРО КАЧЕСТВО',
                        description: {
                            shortName: 'II качество'
                        }
                    });
                    this.setState({
                        loading: this.state.loading - 1,
                        bricks: bricks
                    });
                }
            }, PARAMETERS.BRICKS
        );
        API.getParameterValues(
            (apiCall: API_CALL) => {
                if (isSuccess(apiCall)) {
                    this.setState({
                        loading: this.state.loading - 1,
                        pelet_types: apiCall.data.data.items?.values
                    });
                }
            }, PARAMETERS.PELET_TYPE
        );
        API.getParameterValues(
            (apiCall: API_CALL) => {
                if (isSuccess(apiCall)) {
                    this.setState({
                        loading: this.state.loading - 1,
                        bag_types: apiCall.data.data.items?.values
                    });
                }
            }, PARAMETERS.BAG_TYPE
        )
    }

    loadData(saleType: SALE_TYPE | null = null, pageIdx: number = 0) {
        this.setState({working: true, saleType: saleType});
        API.getExpeditionNote(
            (apiCall: API_CALL) => {
                if (isSuccess(apiCall)) {
                    const sales = apiCall.data.data.sales.map((sale: Sale) => nullsToEmptyString(sale));
                    this.setState({
                        working: false,
                        sales: sales,
                        pageCount: apiCall.data.data.pages,
                        currentPage: apiCall.data.data.page,
                    });
                }
            }, mapToObject(this.state.formValues), saleType, pageIdx, 10, this.only_not_paid
        );
    }

    clearFilter(setCurrentDate: boolean) {
        const fv = this.state.formValues;
        fv.set("sales_number", '');
        fv.set("sales_date", setCurrentDate ? moment().format('DD.MM.YYYY') : '');
        fv.set("client", '');
        fv.set("client_city", '');
        fv.set("inv_number", '');

        this.setState({formValues: this.state.formValues.set('sales_number', '')});
        this.loadData();
    }

    viewClientAccount(client_id: number | null) {
        if(client_id)
        API.getClient(
            (apiCall: API_CALL) => {
                this.setState({working: isLoading(apiCall)});
                if (isSuccess(apiCall)) {
                    this.setState({editClientAccount: apiCall.data.data.client});
                }
                if (isError(apiCall)) {
                    showErrorsInToast(apiCall, 'Грешка', translateError);
                }
            },
            client_id
        );
    }

    getSalesTypeName(s: SALE_TYPE | null) {
        if(s)
        switch (s.toString()) {
            case 'bricks_wo_env' : return 'ТБ'
            case 'bricks_w_env' : return 'ТФ'
            case 'peleti_w_env' : return 'ПФ'
            case 'peleti_wo_env' : return 'ПБ'
        }

        return 'х';
    }

    renderData() {
        const trs: any[] = [];

        trs.push(<tr key={0}>
            <td>
                <input type="text"
                       className="w-120px form-control"
                       name="sales_number"
                       value={this.state.formValues?.get("sales_number")}
                       onChange={this.handleFormChange}/>
            </td>
            <td>
                <div className={"w-10 text-nowrap readonly-date-picker"}>
                    <ReadOnlyDatePickerComponent
                        hideIcon={true}
                        hideDayControls={true}
                        classes={"pl-0 ml-0"}
                        value={this.state.formValues.get("sales_date")}
                        onChange={(date: Date) => {
                            this.setState({formValues: this.state.formValues.set("sales_date", moment(date).format('DD.MM.YYYY'))});
                            this.loadData();
                        }}
                    />
                </div>
            </td>
            <td><input className={"w-100 form-control"} name={"client"} value={this.state.formValues.get("client")} onChange={this.handleFormChange}/></td>
            <td><input className={"w-120px form-control"} name={"client_city"} value={this.state.formValues.get("client_city")} onChange={this.handleFormChange}/></td>
            <td></td>
            <td><input className={"w-120px form-control"} name={"inv_number"} value={this.state.formValues.get("inv_number")} onChange={this.handleFormChange}/></td>
            <td></td>
            <td></td>
            <td className={"text-right w-120px"}>
                <button type={"button"} className={"btn btn-secondary btn-sm"} onClick={() => {
                    this.loadData()
                }}>
                    <FontAwesomeIcon icon={"search"}/>
                </button>
                &nbsp;
                <button type={"button"} className={"btn btn-secondary btn-sm"} onClick={() => this.clearFilter(false)}>
                    <FontAwesomeIcon icon={"times"}/>
                </button>
            </td>
        </tr>)

        if (this.state.sales.length === 0) {
            trs.push(<tr key={1}>
                <td colSpan={9}>Няма нищо за показване</td>
            </tr>)
        } else {
            this.state.sales.forEach(
                (s, idx) => trs.push(<tr key={idx + 1} className={(s.payed_by_avans == 0 && s.inv_price !== s.inv_payed) ? "text-danger" : ""}>
                    <td>{this.getSalesTypeName(s.sale_type)}:{s.sales_number}</td>
                    <td>{s.sales_date}</td>
                    <td>{s.client}</td>
                    <td>{s.client_city}</td>
                    <td>{s.inv_price == 0 ? '' : (s.pay_type === PAY_TYPE.CASH ? 'КЕШ' : 'БАНКА')}</td>
                    <td>{s.inv_number}</td>
                    <td>{s.inv_price == 0 ? '' : s.inv_price}</td>
                    <td>{s.inv_payed == 0 ? '' : s.inv_payed}</td>
                    <td className={"text-right w-180px text-nowrap"}>
                        {
                            s.client_id && s.client_id > 0 &&
                            <button className={"btn btn-secondary btn-sm"}
                                    onClick={() => this.viewClientAccount(s.client_id)}>
                                <FontAwesomeIcon icon="hand-holding-usd"/>
                            </button>
                        }
                        &nbsp;
                        <button type={"button"} className={"btn btn-secondary btn-sm"} onClick={() => {
                            this.printExpNote(s.id)
                        }}>
                            <FontAwesomeIcon icon={"print"}/>
                        </button>
                        &nbsp;
                        <button type={"button"} className={"btn btn-secondary btn-sm"} onClick={() => {
                            this.setState({editSale: s})
                        }}>
                            <FontAwesomeIcon icon={"pen"}/>
                        </button>
                        &nbsp;
                        <button type={"button"} className={"btn btn-secondary btn-sm"} onClick={() => {
                            this.deleteExpNote(s.id)
                        }}>
                            <FontAwesomeIcon icon={"trash-alt"}/>
                        </button>
                    </td>
                </tr>)
            );
        }

        return (<>{trs}</>)
    }

    createNewSale(sale_type: SALE_TYPE) {
        const newSale: Sale = {sale_type: sale_type} as Sale;
        this.setState({editSale: newSale})
    }

    afterSaved() {
        this.setState({editSale: null})
        this.loadData(this.state.saleType);
    }

    deleteExpNote(expNoteId: number | null) {
        if(expNoteId) {
            this.selectedSale = expNoteId;
            this.setState({dialogConfirmDeleteVisible: true});
        }
    }

    doDeleteSale(expNoteId: number) {
        API.deleteExpeditionNote(
            (apiCall: API_CALL) => {
                const _isLoading = isLoading(apiCall);
                this.setState({working: _isLoading, dialogConfirmDeleteVisible: _isLoading});
                if (isSuccess(apiCall)) this.loadData();
            }, expNoteId
        );
    }

    printExpNote(expNoteId: number | null) {
        if(expNoteId)
        API.printExpeditionNote(
            (apiCall: API_CALL) => {
                if (isLoading(apiCall)) {
                    this.setState({working: true});
                } else {
                    this.setState({working: false});

                    const win = window.open();
                    if (win) {
                        win.document.write(apiCall.data.data);
                        win.document.close();
                    }
                }
            }, expNoteId
        );
    }

    showNotPaid() {

    }

    onPageChange(newPage: number) {
        this.setState({currentPage: newPage});
        this.loadData(this.state.saleType, newPage);
    }

    render() {

        return (
            <>
                <div className="container" style={{maxWidth: "80%"}}>
                    <div className="card shadow w-100" >
                        <div className="card-header text-center row">
                            <div className="col-2 text-left">
                                {
                                    this.state.loading > 0 ?
                                        <div className="spinner-border" role="status">
                                            <span className="sr-only">Loading...</span>
                                        </div>
                                        :
                                        <Link to={PATHS.home + API.getAPITokenAsQuery()}>
                                            <span className="btn btn-secondary"><FontAwesomeIcon icon={faArrowLeft}/></span></Link>
                                }

                            </div>
                            <h2 className="col">Продажби</h2>
                            <div className="col-2">
                                <Dropdown>
                                    <Dropdown.Toggle variant="secondary" id="dropdown-basic">
                                        <FontAwesomeIcon icon={"plus"}/> Добави
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu>
                                        <Dropdown.Item onClick={() => this.createNewSale(SALE_TYPE.bricks_w_env)}>Продажба на {SALE_TYPE.bricks_w_env}</Dropdown.Item>
                                        <Dropdown.Item onClick={() => this.createNewSale(SALE_TYPE.bricks_wo_env)}>Продажба на тухли с касов бон</Dropdown.Item>
                                        <Dropdown.Item onClick={() => this.createNewSale(SALE_TYPE.peleti_w_env)}>Продажба на пелети с фактура</Dropdown.Item>
                                        <Dropdown.Item onClick={() => this.createNewSale(SALE_TYPE.peleti_wo_env)}>Продажба на пелети с касов бон</Dropdown.Item>
                                        <Dropdown.Item onClick={() => this.createNewSale(SALE_TYPE.others)}>Други продажби</Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </div>
                        </div>

                        <div className="card-body">
                            <h4 className="card-title">Експедиционни бележки</h4>
                            <div className={"row mb-3"}>
                                <div className={"col-auto"}>
                                    <Tabs
                                        variant={"pills"}
                                        activeKey={this.state.saleType ? this.state.saleType : "null"}
                                        onSelect={(k: any) => this.loadData(k === "null" ? null : k, 0)}
                                    >
                                        <Tab eventKey={"null"} title="Всички"></Tab>
                                        <Tab eventKey={SALE_TYPE.bricks_w_env} title={SALE_TYPE.bricks_w_env}></Tab>
                                        <Tab eventKey={SALE_TYPE.bricks_wo_env} title={SALE_TYPE.bricks_wo_env}></Tab>
                                        <Tab eventKey={SALE_TYPE.peleti_w_env} title={SALE_TYPE.peleti_w_env}></Tab>
                                        <Tab eventKey={SALE_TYPE.peleti_wo_env} title={SALE_TYPE.peleti_wo_env}></Tab>
                                        <Tab eventKey={SALE_TYPE.others} title={SALE_TYPE.others}></Tab>
                                    </Tabs>
                                </div>
                                <div className={"col pt-2"}>
                                    <input type="checkbox"
                                           name={"only_not_paid"}
                                           checked={this.only_not_paid}
                                           onChange={() => {
                                               this.only_not_paid = !this.only_not_paid;
                                               this.loadData();
                                           }}/> Покажи неплатените
                                </div>
                                <div className={"col-auto text-right"}>
                                    <Link to={PATHS.daily_reports + API.getAPITokenAsQuery()} className={"btn btn-secondary"}>Дневни отчети</Link>
                                </div>
                            </div>
                            <div className={"row"}>
                                <div className={"col"}>
                                    <table className={"table table-sm table-hover"}>
                                        <thead>
                                        <tr className={"bg-secondary text-light"}>
                                            <th>№</th>
                                            <th>Дата</th>
                                            <th>Клиент</th>
                                            <th>Град</th>
                                            <th>Тип</th>
                                            <th>Фактура #</th>
                                            <th>Стойност(лв)</th>
                                            <th>Платено(лв)</th>
                                            <th></th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {
                                            (this.state.loading > 0 || this.state.working) ?
                                                <tr>
                                                    <td colSpan={8}>
                                                        <div className="spinner-border" role="status">
                                                            <span className="sr-only">Loading...</span>
                                                        </div>
                                                    </td>
                                                </tr>
                                                :
                                                this.renderData()
                                        }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div className={"row"}>
                                <div className={"col"}>
                                    <Pager currentPage={this.state.currentPage}
                                           pageCount={this.state.pageCount}
                                           onChange={this.onPageChange.bind(this)}
                                           disabled={this.state.working}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                    {
                        this.state.editSale &&
                        <SaleDetailsModalComponent
                            sale={this.state.editSale}
                            onAfterSave={this.afterSaved.bind(this)}
                            onCancel={() => {
                                this.setState({editSale: null})
                            }}
                            brick_types={this.state.bricks}
                            pelet_types={this.state.pelet_types}
                            bag_types={this.state.bag_types}
                        />
                    }


                    {
                        this.state.dialogConfirmDeleteVisible &&
                        <ConfirmDeleteModal
                            working={this.state.working}
                            onConfirm={() => this.doDeleteSale(this.selectedSale)}
                            onReject={() => this.setState({dialogConfirmDeleteVisible: false})}
                        />
                    }

                    {
                        this.state.editClientAccount &&
                        <EditClientAccountModal client={this.state.editClientAccount}
                                                onReload={() => this.loadData(this.state.saleType, this.state.currentPage)}
                                                onClose={() => {
                                                    this.setState({editClientAccount: null})
                                                }}/>
                    }
                </div>
            </>
        );
    }
}
