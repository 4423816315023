import React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {isLoading, isSuccess} from "../../common";
import {API} from "../../api";
import {API_CALL} from "../../types";



export class UserNameComponent extends React.Component {

    render() {
        return (
            <>
                {
                    !API.user &&
                    <div className="spinner-border" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                }
                {
                    API.user &&
                    <>
                        <FontAwesomeIcon icon="user" size="sm"/> <strong>{API.user.name}</strong>
                    </>
                }
            </>
        );
    }

}
