import React from "react";
import {API_CALL, PARAMETERS, PropertyValue, REPAIR_TYPE} from "../types";
import {isError, isLoading, isSuccess, mapToObject, showErrorsInToast, showMsgInToast, translateError} from "../common";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {API} from "../api";
import {Link} from "react-router-dom";
import {PATHS} from "../paths";
import {faArrowLeft} from "@fortawesome/free-solid-svg-icons";
import {BlockTitleComponent} from "../components/common/BlockTitleComponent";
import {ReadOnlyDatePickerComponent} from "../components/common/ReadOnlyDatePickerComponent";
import moment, {Moment} from "moment";
import * as queryString from "querystring";
import {RepairsReportPage} from "../components/reports/RepairsReportPage";
import {ProductionReportPage} from "../components/reports/ProductionReportPage";
import {BricksProducedReportPage} from "../components/reports/BricksProducedReportPage";
import {BricksWasteReportPage} from "../components/reports/BricksWasteReportPage";
import {FuelReportPage} from "../components/reports/FuelReportPage";
import {MaterialsReportPage} from "../components/reports/MaterialsReportPage";
import {CommentsReportPage} from "../components/reports/CommentsReportPage";
import {BricksSalesReportPage} from "../components/financial/BricksSalesReportPage";
import {PeletsSludgeSaldoReportPage} from "../components/financial/PeletsSludgeSaldoReportPage";
import {ClientsBalanceReportPage} from "../components/financial/ClientsBalanceReportPage";
import {IncomeOutgoingReportPage} from "../components/financial/IncomeOutgoingReportPage";
import {ClientsPaletsBalanceReportPage} from "../components/financial/ClientsPaletsBalanceReportPage";


export interface ReportsPageProps {
    report?: string,
    periodFrom: string,
    periodTo: string,
}

interface ReportsPageState {
    loading: number,
    formValues: Map<string, any>,
}


export class FinancialReportsPage extends React.Component<any, ReportsPageState> {

    constructor(props: any) {
        super(props);
        this.state = {
            loading: 0,
            formValues: new Map<string, any>(),
        }

        this.loadData = this.loadData.bind(this);
        this.handleFormChange = this.handleFormChange.bind(this);
        this.periodToday = this.periodToday.bind(this);
        this.selectDate = this.selectDate.bind(this);

        this.state.formValues.set('report', 'Продажби по вид тухли за период');
        this.periodToday();
    }

    async componentDidMount() {
        this.periodToday();
        // @ts-ignore
        let params = queryString.parse(this.props.location.search?.substr(1));
        console.log(params, this.props);
    }

    periodToday() {
        this.selectDate('periodFrom', new Date());
        this.selectDate('periodTo', new Date());
        this.loadData();
    }

    handleFormChange(event: any) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        const fv = this.state.formValues;
        fv.set(name, value);
        this.setState({formValues: fv});
    }

    selectDate(dateFieldName: string, newDate: Date) {
        this.setState({formValues: this.state.formValues.set(dateFieldName, moment(newDate).format('DD.MM.YYYY'))});
    }

    loadData() {
        const fv = this.state.formValues;

        fv.set('activeReport', fv.get('report'));
        fv.set('activePeriodFrom', fv.get('periodFrom'));
        fv.set('activePeriodTo', fv.get('periodTo'));

        this.setState({formValues: fv});
        console.log(fv);
    }


    render() {
        return (
            <div className="container" style={{maxWidth: '100%'}}>

                <div className="card shadow">

                    <div className="card-header text-center row">
                        <div className="col-2 text-left">
                            {
                                (this.state.loading > 0) ?
                                    <div className="spinner-border" role="status">
                                        <span className="sr-only">Loading...</span>
                                    </div>
                                    :
                                    <Link to={PATHS.home + API.getAPITokenAsQuery()}><span className="btn btn-secondary"><FontAwesomeIcon icon={faArrowLeft}/></span></Link>
                            }

                        </div>
                        <h2 className="col">Финансови справки</h2>
                        <div className="col-2">
                        </div>
                    </div>

                    <div className="card-body">

                        <div className="row">
                            <div className="col">
                                {
                                    this.state.loading > 0 &&
                                    <div className={"m-3"}>Зареждам данните ...</div>
                                }
                            </div>
                        </div>

                        <BlockTitleComponent title={"Параметри на справката"}/>
                        <div className={"row mb-5"}>
                            <div className={"col-auto readonly-date-picker ml-2 mr-2 pr-1"} style={{paddingTop: 7}}>
                                <label className={"justify-content-start"}>Изберете отчет:</label>
                                <select className="form-control w-100" name="report" required
                                        value={this.state.formValues?.get('report')}
                                        onChange={this.handleFormChange}>
                                    <option value="Продажби по вид тухли за период">Продажби по вид тухли за период</option>
                                    <option value="Приход и разход на шлам и пелети за период">Приход и разход на шлам и пелети за период</option>
                                    <option value="Длъжници с касов бон и банка към дата">Длъжници с касов бон и банка към дата</option>
                                    <option value="Клиенти с аванс към дата">Клиенти с аванс към дата</option>
                                    <option value="Баланс палети по клиенти към дата">Баланс палети по клиенти към дата</option>
                                    <option value="Приход и разход за период">Приход и разход за период</option>
                                </select>
                            </div>

                            {
                                this.state.formValues?.get('report') !== "Длъжници с касов бон и банка към дата" &&
                                this.state.formValues?.get('report') !== "Клиенти с аванс към дата" &&
                                this.state.formValues?.get('report') !== "Баланс палети по клиенти към дата" &&

                                <div className={"col-auto mr-2 pl-0"}>
                                    <form className="form-inline">
                                        <div className={"text-nowrap row lh-2em "}>
                                            <div className={"col-12 w-120px mb-2"}>От дата:&nbsp;</div>
                                            <div className={"col-auto w-120px"}>
                                                <ReadOnlyDatePickerComponent
                                                    onChange={(newDate: Date) => this.selectDate('periodFrom', newDate)}
                                                    value={this.state.formValues.get('periodFrom')}
                                                />
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            }

                            <div className={"col-auto readonly-date-picker ml-2 mr-2"}>
                                <form className="form-inline">
                                    <div className={"text-nowrap row lh-2em "}>
                                        <div className={"col-12 w-120px mb-2"}>До дата:&nbsp;</div>
                                        <div className={"col-auto w-120px"}>
                                            <ReadOnlyDatePickerComponent
                                                onChange={(newDate: Date) => this.selectDate('periodTo', newDate)}
                                                value={this.state.formValues.get('periodTo')}
                                            />
                                        </div>
                                    </div>
                                </form>
                            </div>
                            <div className={"col-auto readonly-date-picker"}>
                                {
                                    this.state.loading ?
                                        <>
                                            <label className={"col-form-label w-100"}>&nbsp; </label>
                                            <div className="spinner-border" role="status">
                                                <span className="sr-only">Loading...</span>
                                            </div>
                                        </>
                                        :
                                        <>
                                            <label className={"col-form-label w-100"}>&nbsp; </label>
                                            <button className="btn btn-primary" onClick={this.loadData}>Калкулирай</button>
                                        </>
                                }
                            </div>
                        </div>

                        <ReportViewer
                            report={this.state.formValues.get('activeReport')}
                            periodFrom={this.state.formValues.get('activePeriodFrom')}
                            periodTo={this.state.formValues.get('activePeriodTo')}  />

                    </div>

                    <div className="card-footer">
                        {
                            <>

                            </>
                        }
                    </div>
                </div>

            </div>
        );
    }

}

const ReportViewer = function (props: ReportsPageProps) {
    switch (props.report) {
        case 'Продажби по вид тухли за период' : return <BricksSalesReportPage
            periodFrom={props.periodFrom}
            periodTo={props.periodTo} />
        case 'Приход и разход на шлам и пелети за период' : return <PeletsSludgeSaldoReportPage
            periodFrom={props.periodFrom}
            periodTo={props.periodTo} />
        case 'Длъжници с касов бон и банка към дата' : return <ClientsBalanceReportPage
            showDuty={true} showAvance={false}
            periodTo={props.periodTo} />
        case 'Клиенти с аванс към дата' : return <ClientsBalanceReportPage
            showDuty={false} showAvance={true}
            periodTo={props.periodTo} />
        case 'Баланс палети по клиенти към дата' : return <ClientsPaletsBalanceReportPage
            periodTo={props.periodTo} />
        case 'Приход и разход за период' : return <IncomeOutgoingReportPage
            periodFrom={props.periodFrom}
            periodTo={props.periodTo} />



    }

    return <></>
}
