import React, {useEffect, useState} from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import './App.css';
import {library} from '@fortawesome/fontawesome-svg-core'
import {
    faKey, faUserLock, faCog, faSearch, faAngleRight, faAngleLeft, faArrowLeft, faHome, faHamburger,
    faCalendarAlt, faTimesCircle, faCheckCircle, faPlus, faMinus, faTrashAlt, faPen, faWindowClose, faPrint, faCheck, faTimes, faUser, faSignOutAlt, faEye, faHandHoldingUsd
} from '@fortawesome/free-solid-svg-icons'
import {Route, BrowserRouter as Router, Switch} from 'react-router-dom';
import {LayoutComponent} from './common/LayoutComponent';
import {LoginPage} from "./pages/LoginPage";
import HomePage from "./pages/HomePage";
import {AdminPage} from './pages/AdminPage';
import {AccountsPage} from "./pages/AccountsPage";
import {AccountPage} from './pages/AccountPage';
import {PATHS} from './paths';
import {HeaterPage} from './pages/HeaterPage';
import {toast} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import {GrindingPage} from './pages/GrindingPage';
import {OrderingPage} from './pages/OrderingPage';
import {UnloadingPage} from './pages/UnloadingPage';
import {ExtruderPage} from './pages/ExtruderPage';
import {PropertiesPage} from "./pages/PropertiesPage";
import {OvenPage} from './pages/OvenPage';
import {SalesPage} from './pages/SalesPage';
import {DailyReportsPage} from './pages/DailyReportsPage';
import {RepairsPage} from './pages/RepairsPage';
import {RefuelingPage} from "./pages/RefuelingPage";
import {StandartsPage} from "./pages/StandartsPage";
import {API} from "./api";
import {API_CALL} from "./types";
import {isSuccess} from "./common";
import {ReportsPage} from "./pages/ReportsPage";
import {ClientsPage} from "./pages/ClientsPage";
import {LogisticsPage} from "./pages/LogisticsPage";
import {DailyFullReportPage} from "./pages/DailyFullReportPage";
import {DailyProductionReportsPage} from "./pages/DailyProductionReportsPage";
import {FinancialReportsPage} from "./pages/FinancialReportsPage";
import {CommentsReportPage} from "./components/reports/CommentsReportPage";
import {BankExpensesPage} from "./pages/BankExpensesPage";
import {Form76Page} from "./pages/Form76Page";

library.add(faKey, faUserLock, faCog, faSearch, faAngleRight, faAngleLeft, faArrowLeft, faHome, faHamburger, faCalendarAlt, faTimesCircle, faCheckCircle, faPlus,
    faTrashAlt, faMinus, faPen, faWindowClose, faHamburger, faPrint, faCheck, faTimes, faUser, faSignOutAlt, faEye, faHandHoldingUsd);

toast.configure({
    position: toast.POSITION.BOTTOM_CENTER
});

const App = () => {
    const [user, setUser] = useState(null);

    const isLogin = window.location.href.indexOf(PATHS.login) > -1

    useEffect(() => {
        if (!API.user && !isLogin) {
            API.getUserData((apiCall: API_CALL) => {
                if (isSuccess(apiCall)) {
                    API.user = apiCall.data.data.user;
                    setUser(apiCall.data.data.user);
                }
            })
        }
    })

    if (user || isLogin) return (
        <Router>
            <LayoutComponent>
                <Switch>
                    <Route exact path={PATHS.home}>
                        <HomePage/>
                    </Route>
                    <Route exact path="/home">
                        <HomePage/>
                    </Route>
                    <Route exact path={PATHS.login}>
                        <LoginPage/>
                    </Route>
                    <Route exact path={PATHS.admin}>
                        <AdminPage/>
                    </Route>
                    <Route exact path={PATHS.accounts}>
                        <AccountsPage/>
                    </Route>
                    <Route exact path={PATHS.properties}>
                        <PropertiesPage/>
                    </Route>
                    <Route path={PATHS.account + ":accountId"} component={AccountPage}/>
                    <Route exact path={PATHS.heater}>
                        <HeaterPage/>
                    </Route>
                    <Route exact path={PATHS.grinding}>
                        <GrindingPage/>
                    </Route>
                    <Route exact path={PATHS.ordering}>
                        <OrderingPage/>
                    </Route>
                    <Route exact path={PATHS.unloading}>
                        <UnloadingPage/>
                    </Route>
                    <Route exact path={PATHS.extruder}>
                        <ExtruderPage/>
                    </Route>
                    <Route exact path={PATHS.oven}>
                        <OvenPage/>
                    </Route>
                    <Route exact path={PATHS.sales}>
                        <SalesPage/>
                    </Route>
                    <Route exact path={PATHS.daily_reports}>
                        <DailyReportsPage/>
                    </Route>
                    <Route exact path={PATHS.repairs}>
                        <RepairsPage/>
                    </Route>
                    <Route exact path={PATHS.refueling}>
                        <RefuelingPage/>
                    </Route>
                    <Route exact path={PATHS.comments}>
                        <CommentsReportPage/>
                    </Route>
                    <Route exact path={PATHS.standarts}>
                        <StandartsPage/>
                    </Route>
                    <Route exact path={PATHS.clients}>
                        <ClientsPage/>
                    </Route>
                    <Route exact path={PATHS.logistics}>
                        <LogisticsPage/>
                    </Route>
                    <Route exact path={PATHS.bank_expenses}>
                        <BankExpensesPage/>
                    </Route>
                    <Route exact path={PATHS.form76}>
                        <Form76Page/>
                    </Route>
                    <Route path="/reports/production" component={ReportsPage}/>
                    <Route path="/reports/daily" component={DailyProductionReportsPage}/>
                    <Route path="/reports/financial" component={FinancialReportsPage}/>
                    <Route path="/reports/dailyfullreport" component={DailyFullReportPage}/>
                </Switch>
            </LayoutComponent>
        </Router>
    ); else return <></>;
}

export default App;

//TODO: Актуализация на репорта след корекция на изходните данни
