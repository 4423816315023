import React from "react";
import {Link} from "react-router-dom";
import {AppModule} from "../../types";
import {API} from "../../api";

export interface ModuleProps {
    module: AppModule
    onClick: any
}

export function ModuleComponent(props: ModuleProps) {
    if(props.module)
    return (
        <div className={"row"}>
            <div className="col text-center">
                <Link to={props.module?.url + API.getAPITokenAsQuery()}>
                    <a className="btn btn-outline-primary w-480px mb-2">
                        <span className="text-nowrap">{props.module?.name}</span>
                    </a>
                </Link>
            </div>
        </div>
    );

    return null;
}
