import React from "react";
import {FACTORY} from "../factories";
import {API} from "../api";
import {API_CALL} from "../types";
import {isLoading, isSuccess} from "../common";
import {Link, Redirect } from "react-router-dom";
import {PATHS} from "../paths";
import {ModulesComponent} from "../components/homepage/ModulesComponent";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowLeft} from "@fortawesome/free-solid-svg-icons";

interface AdminPageState {
    api: API_CALL
}

export class AdminPage extends React.Component<any, AdminPageState> {

    constructor(props: any) {
        super(props);
        this.state = {
            api: FACTORY.createApiCall()
        }
    }

    render() {
        return (
                <div className="container">
                    <div className="card shadow">
                        <div className="card-header text-center row">
                            <div className="col-2 text-left">
                                <Link to={PATHS.home + API.getAPITokenAsQuery()}><span className="btn btn-secondary"><FontAwesomeIcon icon={faArrowLeft} /></span></Link>
                            </div>
                            <h2 className="col">Администрация</h2>
                            <div className="col-2">
                            </div>
                        </div>

                        <div className="card-body">
                            <div className="row p-5">
                                <div className="col">
                                    <Link to={PATHS.properties + API.getAPITokenAsQuery()}><a className="btn btn-outline-primary w-100">Номенклатури</a></Link>
                                </div>
                                <div className="col">
                                    <Link to={PATHS.accounts + API.getAPITokenAsQuery()}><a className="btn btn-outline-primary w-100">Персонал</a></Link>
                                </div>
                                <div className="col">
                                    <Link to={PATHS.accounts + API.getAPITokenAsQuery()}><a className="btn btn-outline-primary w-100">Клиенти</a></Link>
                                </div>
                                <div className="col">
                                    <Link to={PATHS.accounts + API.getAPITokenAsQuery()}><a className="btn btn-outline-primary w-100">Транспортни фирми</a></Link>
                                </div>
                            </div>
                        </div>

                        <div className="card-footer">
                            <span className="text-info">Моля, направете Вашият избор...</span>
                        </div>

                    </div>
                </div>
        );
    }
}
