import React from "react";
import moment, {Moment} from "moment";
import {API} from "../../api";
import {API_CALL, PropertyValue} from "../../types";
import {isError, isLoading, isSuccess, showErrorsInToast, translateError, zeroToEmptyString, zeroToEmptyStringAsCurrency} from "../../common";
import {ReadOnlyDatePickerComponent} from "../common/ReadOnlyDatePickerComponent";

interface Form76MonthProps {
}

interface Form76MonthState {
    loading: boolean,
    fromDate: Date;
    toDate: Date;
    editMode: boolean,
    editTypeId: number,
    editDate: Date,
    editValue: number,
    form76Data: any,
    formValues: Map<string, any>,
    selections: Map<string, boolean>,
    otherSelection: number,
}

export class Form76Month extends React.Component<Form76MonthProps, Form76MonthState> {
    yearsList: number[] = [];
    monthsList: string[] = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'];

    constructor(props: any) {
        super(props);

        for (let i = new Date().getFullYear(); i > 2018; i--) this.yearsList.push(i);

        const fv = new Map<string, any>();
        fv.set('fromDate', moment().startOf('month').toDate());
        fv.set('toDate', moment().endOf('month').toDate());

        this.state = {
            loading: true,
            fromDate: fv.get('fromDate'),
            toDate: fv.get('toDate'),
            editMode: false,
            editDate: new Date(),
            editTypeId: 0,
            editValue: 0,
            form76Data: {},
            formValues: fv,
            selections: new Map<string, boolean>(),
            otherSelection: 1,
        }

        this.loadReport = this.loadReport.bind(this);
        this.renderHeader = this.renderHeader.bind(this);
        this.renderRows = this.renderRows.bind(this);
        this.selectAll = this.selectAll.bind(this);
        this.deselectAll = this.deselectAll.bind(this);
        this.selectAllInDivision = this.selectAllInDivision.bind(this);
        this.selectEmpl = this.selectEmpl.bind(this);
    }

    componentDidMount() {
        this.loadReport();
    }

    loadReport() {
        this.setState({fromDate: this.state.formValues.get('fromDate'), toDate: this.state.formValues.get('toDate')});

        API.getForm76Month(
            (apiCall: API_CALL) => {
                this.setState({loading: isLoading(apiCall)});

                if (isError(apiCall)) showErrorsInToast(apiCall, 'Грешка', translateError);

                if (isSuccess(apiCall)) {
                    console.log(apiCall.data.data);
                    this.setState({form76Data: apiCall.data.data.form76});
                }
            },
            moment(this.state.formValues.get('fromDate')).format('DD.MM.YYYY'),
            moment(this.state.formValues.get('toDate')).format('DD.MM.YYYY')
        );
    }

    renderHeader() {
        const days: string[] = [];
        const firstDay = moment(this.state.fromDate);
        const lastDay = moment(this.state.toDate);

        while(firstDay <= lastDay) {
            days.push(firstDay.format('DD.MM.YYYY'));
            firstDay.add('1', "day");
        }

        return (
            <thead>
            <tr>
                <th>Служител</th>
                <th className={"text-right"}>Ставка<br/>(лв/ч)</th>
                {
                    days.map(
                        d => <th className={"cursor-pointer text-center "} key={d} onClick={() => this.selectAll(d)}>
                            {d.slice(0, 2)}<br/>{d.slice(3, 5)}
                        </th>
                    )
                }
                <th className={"text-right"}>Всичко<br/>часове</th>
                <th className={"text-right"}>Всичко<br/>лева</th>
            </tr>
            </thead>
        )
    }

    renderRows() {
        const days: string[] = [];
        let currentDivision = '';
        const firstDay = moment(this.state.fromDate);
        const lastDay = moment(this.state.toDate);

        while(firstDay <= lastDay) {
            days.push(firstDay.format('DD.MM.YYYY'));
            firstDay.add('1', "day");
        }

        const renderDivisionRow = function (division: string, divisionId: number, days: string[], me: Form76Month) {
            return (
                <tr className="cursor-pointer bg-secondary-light font-weight-bold">
                    <td colSpan={2}>{division}</td>
                    {
                        days.map(
                            d => <td key={"e_" + d} onClick={() => me.selectAllInDivision(d, divisionId)}>&nbsp;</td>
                        )
                    }
                    <td colSpan={2}>&nbsp;</td>
                </tr>
            )
        }

        const renderEmplRow = function (item: any, days: string[], me: Form76Month) {
            let totalH = 0;
            let total$ = 0;

            days.forEach(
                d => {
                    if (item[d]) {
                        const day = item[d];

                        const h = Number(day['code']);
                        const r = day['rate'];

                        if(!isNaN(h)) {
                            totalH += h;
                            total$ += (h * r);
                        }
                    }
                }
            );

            return (
                <tr className="">
                    <td>{item.name}</td>
                    <td className={"text-right"}>{item.rate}</td>
                    {
                        days.map(
                            d => <td key={d} onClick={() => me.selectEmpl(d, item.id)}
                                     className={"cursor-pointer text-center " + (me.state.selections.get(item.id + "_" + d) ? "bg-primary" : "")}>
                                {
                                    item[d] ? item[d]['code'] : ''
                                }
                            </td>
                        )
                    }
                    <td className={"text-right font-weight-bold"}>{zeroToEmptyString(totalH.toString())}</td>
                    <td className={"text-right font-weight-bold"}>{zeroToEmptyStringAsCurrency(total$.toString())}</td>
                </tr>
            )
        }

        const rows: any[] = [];

        Object.keys(this.state.form76Data).forEach(
            (key: string) => {
                const item = this.state.form76Data[key];
                if (currentDivision != item.division.name) {
                    currentDivision = item.division.name;
                    rows.push(renderDivisionRow(currentDivision, item.division_id, days, this));
                }
                rows.push(renderEmplRow(item, days, this));
            }
        );

        return rows;
    }

    selectAll(day: string) {
        Object.keys(this.state.form76Data).forEach(
            (k: string) => {
                const item = this.state.form76Data[k];
                const key = item.id + "_" + day;
                this.setState({selections: this.state.selections.set(key, this.state.selections.has(key) ? !this.state.selections.get(key) : true)});
            }
        )
    }

    deselectAll() {
        this.setState({selections: new Map<string, boolean>()});
    }

    setTo(code: string) {
        const positions: string[] = [];
        this.state.selections.forEach(
            (v: boolean, k: string) => {
                if (v) positions.push(k);
            }
        )

        const data = {
            dates: positions,
            code: code
        };

        API.updateForm76(
            (apiCall: API_CALL) => {
                this.setState({loading: isLoading(apiCall)});

                if (isError(apiCall)) showErrorsInToast(apiCall, 'Грешка', translateError);

                if (isSuccess(apiCall)) {
                    this.loadReport();
                    this.deselectAll();
                }
            },
            data
        );
    }

    selectAllInDivision(day: string, divisionId: number) {
        Object.keys(this.state.form76Data).forEach(
            (k: string) => {
                const item = this.state.form76Data[k];
                if (item.division_id === divisionId) {
                    const key = item.id + "_" + day;
                    this.setState({selections: this.state.selections.set(key, this.state.selections.has(key) ? !this.state.selections.get(key) : true)});
                }
            }
        )
    }

    selectEmpl(day: string, emplId: number) {
        const key = emplId + "_" + day;
        this.setState({selections: this.state.selections.set(key, this.state.selections.has(key) ? !this.state.selections.get(key) : true)});
    }

    selectDate(dateFieldName: string, newDate: Date) {
        this.setState({formValues: this.state.formValues.set(dateFieldName,newDate)});
    }

    render() {
        return (
            <>
                <div className="card-body">
                    <div className={"row mb-3"}>
                        <div className={"col-auto ml-0 mr-0"}>
                            <form className="form-inline">
                                <div className={"text-nowrap row lh-2em "}>
                                    <div className={"col-12 w-120px"}>От дата:&nbsp;</div>
                                    <div className={"col-auto w-160px"}>
                                        <ReadOnlyDatePickerComponent
                                            onChange={(newDate: Date) => this.selectDate('fromDate', newDate)}
                                            value={moment(this.state.formValues.get('fromDate')).format('DD.MM.YYYY')}
                                        />
                                    </div>
                                </div>
                            </form>
                        </div>

                        <div className={"col-auto mr-0"}>
                            <form className="form-inline">
                                <div className={"text-nowrap row lh-2em "}>
                                    <div className={"col-12 w-120px"}>До дата:&nbsp;</div>
                                    <div className={"col-auto w-160px"}>
                                        <ReadOnlyDatePickerComponent
                                            value={moment(this.state.formValues.get('toDate')).format('DD.MM.YYYY')}
                                            onChange={(newDate: Date) => this.selectDate('toDate', newDate)}
                                        />
                                    </div>
                                </div>
                            </form>
                        </div>

                        <div className={"col-auto "}>
                            {
                                this.state.loading ?
                                    <>
                                        <label className={"col-form-label w-100 pb-0"}>&nbsp;</label>
                                        <div className="spinner-border" role="status">
                                            <span className="sr-only">Loading...</span>
                                        </div>
                                    </>
                                    :
                                    <>
                                        <label className={"col-form-label w-100 pb-0"}>&nbsp;</label>
                                        <button className="btn btn-primary" onClick={() => this.loadReport()}>Калкулирай</button>
                                    </>
                            }
                        </div>
                    </div>



                    <div className="row">
                        <div className="col">

                            <div className={"w-100 overflow-auto"} style={{ maxHeight: "50vh"}}>
                                <table className={"table table-bordered w-100 table-sm "} id="tableForm76"
                                       style={{opacity: (this.state.loading ? 0.3 : 1)}}
                                >
                                    {
                                        this.renderHeader()
                                    }

                                    <tbody>
                                    {
                                        this.renderRows()
                                    }
                                    </tbody>
                                </table>
                            </div>

                        </div>

                    </div>

                </div>

                <div className="card-footer">
                    <button type={"button"} className="btn btn-primary" onClick={() => this.setTo("8")}>8 часа</button>
                    &nbsp;
                    <button type={"button"} className="btn btn-primary" onClick={() => this.setTo("12")}>12 часа</button>
                    &nbsp;
                    <button type={"button"} className="btn btn-primary" onClick={() => this.setTo("С")}>Самоотлъчка</button>
                    &nbsp;
                    <button type={"button"} className="btn btn-primary" onClick={() => this.setTo("Б")}>Болничен</button>
                    &nbsp;
                    <button type={"button"} className="btn btn-primary" onClick={() => this.setTo("О")}>Отпуска</button>
                    &nbsp;
                    <button type={"button"} className="btn btn-primary" onClick={() => this.setTo("")}>Празно</button>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;
                    Друго:&nbsp;
                    <select className="form-control-sm" onChange={(e: any) => this.setState({otherSelection: e.target.value})} >
                        <option value={1}>1 час</option>
                        <option value={2}>2 часа</option>
                        <option value={3}>3 часа</option>
                        <option value={4}>4 часа</option>
                        <option value={5}>5 часа</option>
                        <option value={6}>6 часа</option>
                        <option value={7}>7 часа</option>
                        <option value={9}>9 часа</option>
                        <option value={10}>10 часа</option>
                        <option value={11}>11 часа</option>
                    </select>&nbsp;
                    <button type={"button"} className="btn btn-primary" onClick={() => this.setTo(this.state.otherSelection.toString())}>Приложи</button>
                    <button type={"button"} className="btn btn-secondary float-right" onClick={() => this.deselectAll()}>Изчисти избора</button>

                </div>
            </>
        )
    }
}
