import React from "react";
import {$enum} from "ts-enum-util";
import {API_CALL, Client, PropertyValue, REPAIR_TYPE} from "../../types";
import {BlockTitleComponent} from "../common/BlockTitleComponent";
import {ReportsPageProps} from "../../pages/ReportsPage";
import {API} from "../../api";
import {isLoading, isSuccess, minutesToHours, numberWithCommas, zeroToEmptyString, zeroToEmptyStringAsCurrency} from "../../common";
import moment, {Moment} from "moment";
import {EditClientAccountModal} from "../clients/EditClientAccountModal";


interface ClientsBalanceReportPageState {
    loading: boolean,
    periodTo: string,
    data: any,
    editClientAccount: Client | null,
}

interface ClientsBalanceReportPageProps {
    periodTo: string,
    showDuty: boolean,
    showAvance: boolean,
}

export class ClientsBalanceReportPage extends React.Component<ClientsBalanceReportPageProps, ClientsBalanceReportPageState> {

    constructor(props: ClientsBalanceReportPageProps) {
        super(props);
        this.state = {
            periodTo: props.periodTo,
            loading: false,
            data: null,
            editClientAccount: null,
        }

        this.loadData = this.loadData.bind(this);
        this.processData = this.processData.bind(this);
    }

    async componentDidMount() {
        this.loadData();
    }

    loadData() {
        API.getAccountBalance(
            (apiCall: API_CALL) => {
                this.setState({loading: isLoading(apiCall)});

                if (isSuccess(apiCall)) {
                    console.log(apiCall.data.data);
                    this.processData(apiCall.data.data.list);
                }
            }, {
                periodTo: this.props.periodTo,
            }
        )
    }

    processData(data: any) {
        const _data: any = [];

        data.forEach(
            (item: any) => {
                let obj = _data.find((_i: any) => _i.id === item.id);
                if (!obj) {
                    obj = {
                        id: item.id,
                        client: item.name,
                        cities: JSON.parse(item.cities),
                        BANKA: {
                            DEPOSIT: 0,
                            WITHDRAW: 0
                        },
                        CASH: {
                            DEPOSIT: 0,
                            WITHDRAW: 0
                        }
                    }
                    _data.push(obj);
                }

                obj[item.account_type][item.operation] += item.amount * 1;
            }
        )

        this.setState({data: _data});
    }

    componentDidUpdate(prevProps: Readonly<ClientsBalanceReportPageProps>, prevState: Readonly<ClientsBalanceReportPageState>, snapshot?: any) {
        if (
            (this.state.periodTo !== this.props.periodTo)
        ) {
            this.setState({
                periodTo: this.props.periodTo
            });
            this.loadData();
        }
    }


    renderReport(data: any) {
        return '';
    }

    renderReports() {
        const total = {
            BANKA: {
                DEPOSIT: 0,
                WITHDRAW: 0
            },
            CASH: {
                DEPOSIT: 0,
                WITHDRAW: 0
            }
        }

        const getValue = function (v: number, showAvance: boolean) {

            if(showAvance && (v >= 0) ) return zeroToEmptyStringAsCurrency(v.toString());
            if(!showAvance && (v < 0) ) return zeroToEmptyStringAsCurrency(v.toString());

            return '';
        }

        return <table className={"table table-sm align-top text-center table-bordered"}>
            <thead>
            <tr>
                <th rowSpan={2}>№</th>
                <th rowSpan={2} className={"text-left"}>Клиент</th>
                <th rowSpan={2} className={"text-left"}>Градове</th>
                <th colSpan={2}>Баланс (лв)</th>
            </tr>
            <tr>
                <th className={"text-right"}>Банка</th>
                <th className={"text-right"}>КЕШ</th>
            </tr>
            </thead>
            <tbody>
            {
                this.state.data
                    .filter(
                        (item: any) => {
                            if(this.props.showDuty) {
                                return ((item.BANKA.DEPOSIT - item.BANKA.WITHDRAW) < 0) ||
                                    ((item.CASH.DEPOSIT - item.CASH.WITHDRAW) < 0);
                            }

                            if(this.props.showAvance) {
                                return ((item.BANKA.DEPOSIT - item.BANKA.WITHDRAW) > 0) ||
                                    ((item.CASH.DEPOSIT - item.CASH.WITHDRAW) > 0);
                            }

                            return false;
                        }
                    ).map(
                    (item: any, idx: number) => {
                        if(getValue((item.BANKA.DEPOSIT - item.BANKA.WITHDRAW), this.props.showAvance) !== '') {
                            total.BANKA.DEPOSIT += item.BANKA.DEPOSIT;
                            total.BANKA.WITHDRAW += item.BANKA.WITHDRAW;
                        }

                        if(getValue((item.CASH.DEPOSIT - item.CASH.WITHDRAW), this.props.showAvance) !== '') {
                            total.CASH.DEPOSIT += item.CASH.DEPOSIT;
                            total.CASH.WITHDRAW += item.CASH.WITHDRAW;
                        }

                        return <tr>
                            <td>{(idx + 1)}</td>
                            <td className={"text-left"}>
                                <a href={"#"}
                                   onClick={(e) =>
                                        {e.preventDefault(); this.setState({editClientAccount: {id: item.id, name: item.client} as Client})}}
                                >
                                    {item.client}</a></td>
                            <td className={"text-left"}>{item.cities.map((c: any) => c.city + (c.phone ? ' (' + c.phone + ')' : '')).join('; ')}</td>
                            <td className={"text-right"}>{ getValue((item.BANKA.DEPOSIT - item.BANKA.WITHDRAW), this.props.showAvance) }</td>
                            <td className={"text-right"}>{ getValue((item.CASH.DEPOSIT - item.CASH.WITHDRAW), this.props.showAvance) }</td>
                        </tr>
                    }
                )
            }
            <tr className={"bg-secondary text-light font-weight-bold"}>
                <td colSpan={3}>Всичко</td>
                <td className={"text-right"}>{zeroToEmptyStringAsCurrency((total.BANKA.DEPOSIT - total.BANKA.WITHDRAW).toString())}</td>
                <td className={"text-right"}>{zeroToEmptyStringAsCurrency((total.CASH.DEPOSIT - total.CASH.WITHDRAW).toString())}</td>
            </tr>
            </tbody>
        </table>;
    }

    render() {
        return (
            <>
                <div className="row">
                    <div className="col">
                        {
                            this.state.loading &&
                            <div className={"m-3"}>Зареждам данните ...</div>
                        }
                    </div>
                </div>

                {
                    !this.state.loading && this.state.data && this.renderReports()
                }
                {
                    this.state.editClientAccount &&
                    <EditClientAccountModal client={this.state.editClientAccount}
                                            onReload={() => {}}
                                            onClose={() => {this.setState({editClientAccount: null})}}/>
                }
            </>
        );
    }
}
