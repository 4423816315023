import React from "react";
import {AccountOperation, API_CALL, Client, OPERATION_TYPE, PAY_TYPE} from "../../types";
import moment from "moment";
import {API} from "../../api";
import {isError, isLoading, isSuccess, showErrorsInToast, translateError} from "../../common";
import {Modal} from "react-bootstrap";
import {EditAccountOperation} from "./EditAccountOperation";

export interface EditClientAccountDetailsProps {
    client: Client,
    onReload: any,
    onCancel: any;
}

export interface EditClientAccountDetailsState {
    loading: boolean,
    operations: AccountOperation[],
    editOperation: AccountOperation | null,
    isNew: boolean,
    balance_bank: number,
    balance_cash: number,
    formValues: Map<string, any>,
}

export class EditClientAccountDetails extends React.Component<EditClientAccountDetailsProps, EditClientAccountDetailsState> {
    constructor(props: EditClientAccountDetailsProps) {
        super(props);
        this.state = {
            loading: false,
            operations: [],
            editOperation: null,
            isNew: false,
            balance_bank: 0,
            balance_cash: 0,
            formValues: new Map<string, any>(),
        }
        this.setState({operations: []});

        this.handleFormChange = this.handleFormChange.bind(this);
        this.saveItem = this.saveItem.bind(this);
        this.addItem = this.addItem.bind(this);
        this.editItem = this.editItem.bind(this);
        // this.saveCityItem = this.saveCityItem.bind(this);
        // this.removeCityItem = this.removeCityItem.bind(this);
        // this.renderCityRows = this.renderCityRows.bind(this);
        // this.saveClient = this.saveClient.bind(this);
        this.cancelClient = this.cancelClient.bind(this);
    }

    componentDidMount() {
        this.loadData();
    }

    handleFormChange(event: any) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({formValues: this.state.formValues.set(name, value)});
    }

    loadData() {

        API.getClientAccounts(
            (apiCall: API_CALL) => {
                this.setState({loading: isLoading(apiCall)});
                if(isError(apiCall)) {
                    showErrorsInToast(apiCall, 'Грешка');
                }
                if(isSuccess(apiCall)) {
                    this.setState({
                        operations: apiCall.data.data.operations,
                        balance_bank: apiCall.data.data.balance.bank,
                        balance_cash: apiCall.data.data.balance.cash,
                    });
                }
            }, this.props.client.id
        )
    }

    renderAccountRows(accType: PAY_TYPE) {

        const operations = this.state.operations.filter((item: AccountOperation) => item.account_type === accType);

        return operations.length == 0 ?
            <tr>
                <td colSpan={3}><strong>Няма записи</strong></td>
            </tr>
            :
            operations.map(
                (item: AccountOperation, idx: number) => <tr key={idx} onClick={()=>this.editItem(item)}>
                    <td>{item.timestamp}</td>
                    <td>{item.description}</td>
                    <td className={"text-right " + (item.operation === OPERATION_TYPE.WITHDRAW ? 'text-danger' : 'text-primary')}>
                        {(item.amount * (item.operation === OPERATION_TYPE.WITHDRAW ? -1 : 1)).toFixed(2)} лв</td>
                </tr>
            );
    }

    addItem(accType: PAY_TYPE) {
        this.setState(
            {editOperation:
                    {id: -1,
                        operation: OPERATION_TYPE.DEPOSIT,
                        account_type: accType,
                        amount: 0,
                        timestamp: moment().format('DD.MM.YYYY'),
                    } as AccountOperation, isNew: true});
    }

    editItem(item: AccountOperation) {
        this.setState({editOperation: item, isNew: false});
    }

    saveItem(operation: AccountOperation, isNew: boolean) {
        API.saveClientOperation(
            (apiCall: API_CALL) => {
                this.setState({loading: isLoading(apiCall)});

                if(isError(apiCall)) showErrorsInToast(apiCall, 'Грешка', translateError);

                if(isSuccess(apiCall)) {
                    this.setState({editOperation: null});
                    this.loadData();
                    this.props.onReload();
                }
            }, operation, this.props.client.id, operation.id
        )
    }

    removeOperation(operation: AccountOperation | null) {
        if(operation)
        API.deleteClientOperation(
            (apiCall: API_CALL) => {
                this.setState({loading: isLoading(apiCall)});

                if(isError(apiCall)) showErrorsInToast(apiCall, 'Грешка', translateError);

                if(isSuccess(apiCall)) {
                    this.setState({editOperation: null});
                    this.loadData();
                    this.props.onReload();
                }
            }, operation.id
        )
    }

    cancelClient() {
        this.props.onCancel();
    }


    render() {
        return ( this.state.loading ?
            <>
                <div className="spinner-border" role="status">
                    <span className="sr-only">Loading...</span>
                </div>
            </>
            :
            <div>
                <div className="row">
                    <div className="col-6">
                        <div className={"row"}>
                            <div className="col-6"><label>БАНКА:</label></div>
                            <div className={"col-6 text-right font-weight-bold text-light pt-1 " + (this.state.balance_bank < 0 ? "bg-danger" : "bg-success") }>
                                {Number(this.state.balance_bank).toFixed(2)} лв</div>
                        </div>

                        <table className={"table table-sm"}>
                            <thead>
                            <tr>
                                <th>Дата</th>
                                <th>Описание</th>
                                <th className={"text-right"}>Сума</th>
                            </tr>
                            </thead>
                            <tbody>
                            {this.renderAccountRows(PAY_TYPE.BANKA)}
                            <tr>
                                <td colSpan={3}>
                                    <button className={"btn btn-outline-primary w-100 font-weight-bold"}
                                            onClick={() => this.addItem(PAY_TYPE.BANKA)}>
                                        Добави нов запис
                                    </button>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>

                    <div className="col-6">
                        <div className={"row"}>
                            <div className="col-6"><label>КЕШ:</label></div>
                            <div className={"col-6 text-right font-weight-bold text-light pt-1 " + (this.state.balance_cash < 0 ? "bg-danger" : "bg-success") }>
                                {Number(this.state.balance_cash).toFixed(2)} лв </div>
                        </div>

                        <table className={"table table-sm"}>
                            <thead>
                            <tr>
                                <th>Дата</th>
                                <th>Описание</th>
                                <th className={"text-right"}>Сума</th>
                            </tr>
                            </thead>
                            <tbody>
                            {this.renderAccountRows(PAY_TYPE.CASH)}
                            <tr>
                                <td colSpan={3}>
                                    <button className={"btn btn-outline-primary w-100 font-weight-bold"}
                                            onClick={() => this.addItem(PAY_TYPE.CASH)}>
                                        Добави нов запис
                                    </button>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>

                <div className="row">
                    <div className="col">
                        <button type={"button"} className={"btn btn-primary"} onClick={this.cancelClient}>Затвори</button>
                    </div>
                </div>

                {
                    this.state.editOperation &&
                    <Modal
                        show={true}
                        onHide={() => {
                            this.setState({editOperation: null})
                        }}
                    >
                        <Modal.Header closeButton>
                            <Modal.Title>{(this.state.isNew ? 'Добавяне' : 'Корекция')} на операция</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div className={"modal-90 m-3"} style={{maxWidth: '30em'}}>
                                <EditAccountOperation
                                    operation={this.state.editOperation}
                                    onSave={operation => this.saveItem(operation, this.state.isNew)}
                                    onRemove={() => this.removeOperation(this.state.editOperation)}
                                    onCancel={() => this.setState({editOperation: null})}
                                />
                            </div>
                        </Modal.Body>
                    </Modal>
                }
            </div>
        );
    }

}
