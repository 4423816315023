import React, {useState} from "react";
import {FACTORY} from "../factories";
import {API} from "../api";
import {API_CALL, UserModule} from "../types";
import {isLoading, isSuccess} from "../common";
import {Link, Redirect} from "react-router-dom";
import {ModulesComponent} from "../components/homepage/ModulesComponent";
import {PATHS} from "../paths";
import {faAngleRight, faArrowLeft} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Modal} from "react-bootstrap";
import moment from "moment";

interface AccountsPageState {
    api: API_CALL
}

function AccountImage(props: {account_id: number}) {
    const [showPopup, setShowPopup] = useState(false);
    const uploadPhoto = function (e: any) {
        if (e && typeof e.preventDefault === 'function') {
            e.preventDefault();
        }
        setShowPopup(true);
    }

    return (
        <>
            <img src={API.site + "user/" + props.account_id + "/photo?rnd=" + moment().milliseconds()} style={{width: 120}} onClick={uploadPhoto}></img>
            <Modal
                show={showPopup}
                onHide={() => setShowPopup(false)}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Качване на снимка</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <div>
                        <div>
                            <img src={API.site + "user/" + props.account_id + "/photo"} style={{width: 300, textAlign: "center"}}></img>
                        </div>
                        <div>
                            <form className={"form"} method={"post"} action={API.site + "user/" + props.account_id + "/photo"} encType={"multipart/form-data"}>
                                <div className="form-group">
                                    <label>Изберете снимка:</label>
                                    <input type="file" className="form-control-file" name={"user_photo"} />
                                </div>
                                <div>
                                    <button type={"submit"} name={"upload_photo"}>Изпрати</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </Modal.Body>

                <Modal.Footer>
                    {
                            <>
                                <button type={"button"} className={"btn btn-secondary"} onClick={() => setShowPopup(false)}>Откажи</button>
                            </>
                    }
                </Modal.Footer>
            </Modal>
        </>
    );
}

export class AccountsPage extends React.Component<any, AccountsPageState> {

    constructor(props: any) {
        super(props);
        this.state = {
            api: FACTORY.createApiCall()
        }
    }

    componentDidMount() {
        this.loadAccounts();
    }

    loadAccounts() {
        API.getAllAccounts(
            (apiCall: API_CALL) => {
                this.setState({api: apiCall});
            }
        )
    }

    render() {
        return (
                <div className="container">
                    <div className="card shadow">
                        <div className="card-header text-center row">
                            <div className="col-2 text-left">
                                {
                                    isLoading(this.state.api) ?
                                        <div className="spinner-border" role="status">
                                            <span className="sr-only">Loading...</span>
                                        </div>
                                        :
                                        <Link to={PATHS.home + API.getAPITokenAsQuery()}>
                                            <span className="btn btn-secondary"><FontAwesomeIcon icon={faArrowLeft} /></span></Link>
                                }

                            </div>
                            <h2 className="col">Служители</h2>
                            <div className="col-2">
                            </div>
                        </div>

                        <div className="card-body">
                            {
                                isSuccess(this.state.api) &&
                                <>
                                    <table className="table table-hover">
                                        <thead>
                                        <tr >
                                            <th>Снимка</th>
                                            <th>Име на служителя</th>
                                            <th>Длъжност</th>
                                            <th>Модули</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {
                                            this.state.api.data.data.users.map(
                                                (item: any, idx: number) => <tr key={idx}>
                                                    <td><AccountImage account_id={item.id}></AccountImage></td>
                                                    <td><a href={PATHS.account+item.id+'?t='+API.getToken()}>{item.name}</a></td>
                                                    <td>{item.position}</td>
                                                    <td>{item.modules.map((item: UserModule)=>item.module.name).join(', ')}</td>
                                                </tr>
                                            )
                                        }
                                        </tbody>
                                    </table>
                                    <div>

                                    </div>
                                </>
                            }
                        </div>

                        <div className="card-footer">
                            <Link to={PATHS.aaccount_new + API.getAPITokenAsQuery()}><button className="btn btn-primary">Добави нов</button></Link>
                        </div>

                    </div>
                </div>
        );
    }
}
