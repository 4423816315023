import React from "react";
import {FACTORY} from "../factories";
import {API} from "../api";
import {API_CALL, PARAMETERS, Property, PropertyValue} from "../types";
import {isError, isLoading, isSuccess, mapToObject} from "../common";
import {Link} from "react-router-dom";
import {PATHS} from "../paths";
import {faArrowLeft} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {ValueEditModalComponent} from "../components/properties/ValueEditModalComponent";

interface PropertiesPageState {
    api: API_CALL,
    selectedProperty: Property | null,
    editValue: PropertyValue | null,
    data: Property[],
    loading: number,
    working: boolean,
}

export class PropertiesPage extends React.Component<any, PropertiesPageState> {

    constructor(props: any) {
        super(props);
        this.state = {
            api: FACTORY.createApiCall(),
            selectedProperty: null,
            editValue: null,
            data: [],
            loading: 0,
            working: false,
        }

        this.saveValue = this.saveValue.bind(this);
    }

    componentDidMount() {
        this.loadData();
    }

    loadData() {
        const me = this;
        const values = Object.values(PARAMETERS).filter(k => !isNaN(Number(k)));
        this.setState({loading: values.length});
        values.forEach(
            (k: any, idx: number) => {
                API.getParameterValues(
                    (apiCall: API_CALL) => {
                        if (isSuccess(apiCall)) {
                            me.state.data.push(apiCall.data.data.items);
                            me.setState({loading: this.state.loading - 1});
                        }
                        if(isError(apiCall)) {
                            me.setState({loading: this.state.loading - 1});
                        }
                    }, values[idx] as PARAMETERS
                );
            }
        )
    }

    updateData(newProp: Property) {
        const me = this;
        const newData:Property[] = [];

        this.state.data.forEach(
            p => {
                newData.push((p.id === newProp.id) ? newProp : {...p});
            }
        );
        me.setState({data: newData});
        me.setState({'selectedProperty': newProp});
    }

    saveValue(result: boolean) {
        if (result) {
            const me = this;
            API.getParameterValues(
                (apiCall: API_CALL) => {
                    me.setState({'working': isLoading(apiCall)});
                    if (isSuccess(apiCall)) {
                        me.updateData(apiCall.data.data.items);
                    }
                }, this.state.selectedProperty ? this.state.selectedProperty.id : -1
            );
        }
        this.setState({editValue: null});
    }

    addNewValue() {
        const newValue: PropertyValue = {
            id: -1,
            value: '',
            description: (this.state.selectedProperty?.descriptor && Array.isArray(this.state.selectedProperty?.descriptor))
                ?
                this.state.selectedProperty.descriptor.map(i => {
                    const obj: any = {};
                    obj[i.name] = null;
                    return obj
                })
                : []
        }

        this.setState({editValue: newValue});
    }

    deleteItem(item: PropertyValue) {
        const me = this;
        API.deleteParameterValues(
            (apiCall: API_CALL) => {
                me.setState({working: isLoading(apiCall)});
                if(isSuccess(apiCall)) {
                    API.getParameterValues(
                        (apiCall: API_CALL) => {
                            me.setState({'working': isLoading(apiCall)});
                            if (isSuccess(apiCall)) {
                                me.updateData(apiCall.data.data.items);
                            }
                        }, this.state.selectedProperty ? this.state.selectedProperty.id : -1
                    );
                }
            }, this.state.selectedProperty?.id || -1, item.id
        );
    }

    render() {
        return (
            <div className="container">
                <div className="card shadow">
                    <div className="card-header text-center row">
                        <div className="col-2 text-left">
                            {
                                this.state.loading > 0 ?
                                    <div className="spinner-border" role="status">
                                        <span className="sr-only">Loading...</span>
                                    </div>
                                    :
                                    <Link to={PATHS.home + API.getAPITokenAsQuery()}>
                                        <span className="btn btn-secondary"><FontAwesomeIcon icon={faArrowLeft}/></span></Link>
                            }

                        </div>
                        <h2 className="col">Номенклатури</h2>
                        <div className="col-2">
                        </div>
                    </div>

                    <div className="card-body">
                        <div className={"row"}>

                            <table className="table table-hover col-3 table-bordered table-secondary">
                                <thead>
                                <tr>
                                    <th style={{height: 57}}>Показател</th>
                                </tr>
                                </thead>
                                {
                                    this.state.loading === 0 ?
                                        <>
                                            <tbody>
                                            {
                                                this.state.data.map(
                                                    (item: any, idx: number) => <tr key={idx} onClick={() => {
                                                        this.setState({selectedProperty: item})
                                                    }} className={item.id === this.state.selectedProperty?.id ? "bg-dark text-light" : ""}>
                                                        <td>{item.name}</td>
                                                    </tr>
                                                )
                                            }
                                            </tbody>
                                        </>
                                        :
                                        <>
                                            <tbody>
                                            <tr>
                                                <td>Зареждам ...</td>
                                            </tr>
                                            </tbody>
                                        </>
                                }
                            </table>


                            {
                                this.state.loading === 0 &&
                                <table className="table table-hover col-9">
                                    <thead>
                                    <tr>
                                        <th>Стойности</th>
                                        <th className={"text-right"}>
                                            {
                                                this.state.working ?
                                                    <div className="spinner-border" role="status">
                                                        <span className="sr-only">Loading...</span>
                                                    </div>
                                                    :
                                                    <>
                                                        <button type={"button"} className={"btn btn-outline-primary btn-sm"}
                                                                disabled={this.state.selectedProperty ? false : true}
                                                                onClick={this.addNewValue.bind(this)}
                                                        >
                                                            <FontAwesomeIcon icon={"plus"}/> Добави
                                                        </button>
                                                    </>
                                            }
                                        </th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {
                                        (this.state.selectedProperty && !this.state.working) ?
                                            <>
                                                {
                                                    this.state.selectedProperty.values.length === 0 &&
                                                    <tr>
                                                        <td colSpan={2}>Няма въведени стойности за параметър.</td>
                                                    </tr>
                                                }
                                                {
                                                    this.state.selectedProperty.values.map(
                                                        (item: any, idx: number) => <tr key={idx} onClick={() => {
                                                        }}>
                                                            <td>
                                                                {item.value}
                                                            </td>
                                                            <td align={"right"}>
                                                            <span className="btn btn-primary btn-light mr-1"
                                                                  onClick={() => {
                                                                      this.setState({editValue: item})
                                                                  }}>
                                                                <FontAwesomeIcon icon={"pen"}/>
                                                            </span>
                                                                <span className="btn btn-primary btn-light"
                                                                      onClick={() => {
                                                                          this.deleteItem(item)
                                                                      }}
                                                                ><FontAwesomeIcon icon={"trash-alt"}/></span>
                                                            </td>
                                                        </tr>
                                                    )
                                                }

                                            </>
                                            :
                                            <>
                                                <tr>
                                                    {
                                                        this.state.working ?
                                                            <td colSpan={2}>Моля, изчакайте. Зареждам...</td>
                                                            :
                                                            <td colSpan={2}>Моля, изберете показател...</td>
                                                    }
                                                </tr>
                                            </>
                                    }
                                    </tbody>
                                </table>
                            }
                        </div>
                    </div>
                </div>
                {
                    this.state.editValue &&
                    <ValueEditModalComponent
                        value={this.state.editValue}
                        onSave={this.saveValue}
                        property={this.state.selectedProperty || ({} as Property)}
                    />
                }
            </div>
        );
    }
}
