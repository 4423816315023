import React from "react";
import {API_CALL, OrderingReport, PARAMETERS, PAY_TYPE, PropertyValue, Sale, SaleItem} from "../types";
import {bagTypeTitle, isError, isLoading, isSuccess, showErrorsInToast, showMsgInToast, translateError, zeroToEmptyString, zeroToEmptyStringAsCurrency} from "../common";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import moment, {Moment} from "moment";
import {API} from "../api";
import {Link} from "react-router-dom";
import {PATHS} from "../paths";
import {faArrowLeft} from "@fortawesome/free-solid-svg-icons";
import {ReadOnlyDatePickerComponent} from "../components/common/ReadOnlyDatePickerComponent";
import {BlockTitleComponent} from "../components/common/BlockTitleComponent";
import {ExpNotesList} from "../components/daily-reports/ExpNotesList";
import {BankExpensesEditModal} from "../components/bank-exp/BankExpensesEditModal";
import {Form76Month} from "../components/form76/form76Month";
import {Form76Divisions} from "../components/form76/Form76Divisions";
import {Form76Employees} from "../components/form76/Form76Employees";

interface Form76PageProps {
    selectedDate?: Moment;
}

interface Form76PageState {
    loading: boolean,
    activeTab: string,
    editMode: boolean,
    editTypeId: number,
    editDate: Date,
    editValue: number,
    form76Data: any,
}

export class Form76Page extends React.Component<Form76PageProps, Form76PageState> {

    constructor(props: any) {
        super(props);

        this.state = {
            loading: true,
            activeTab: 'tab0',
            editMode: false,
            editDate: new Date(),
            editTypeId: 0,
            editValue: 0,
            form76Data: {},
        }

        this.handleFormChange = this.handleFormChange.bind(this);
        this.loadReport = this.loadReport.bind(this);
        this.editValue = this.editValue.bind(this);
        this.saveValue = this.saveValue.bind(this);
        this.renderTabPanel = this.renderTabPanel.bind(this);
    }

    componentDidMount() {
        this.loadReport();
    }

    loadReport() {
        API.getForm76Day(
            (apiCall: API_CALL) => {
                this.setState({loading: isLoading(apiCall)});

                if (isError(apiCall)) showErrorsInToast(apiCall, 'Грешка', translateError);

                if (isSuccess(apiCall)) {
                    console.log(apiCall.data.data);
                    // this.setState({expenses: apiCall.data.data.expenses, types: apiCall.data.data.types});
                }
            },
            moment(this.state.editDate).format('YYYY-MM-DD')
        );
    }

    handleFormChange(event: any) {
        // const target = event.target;
        // const value = target.type === 'checkbox' ? target.checked : target.value;
        // const name = target.name;
        //
        // this.setState(
        //     prevState => {
        //         return {formValues: prevState.formValues.set(name, value)}
        //     }
        // )
    }

    editValue(expName: string, y: string, m: string, value: string) {
        // this.setState({
        //     editMode: true,
        //     editYear: Number(y),
        //     editMonth: Number(m),
        //     editTypeId: Number(expName),
        //     editValue: Number(value)
        // })
    }

    saveValue(newValue: number) {
        // this.setState({editMode: false});
        // API.setBankExpenses(
        //     (apiCall: API_CALL) => {
        //         this.setState({loading: isLoading(apiCall)});
        //
        //         if (isSuccess(apiCall)) {
        //             this.loadReports();
        //         }
        //     },
        //     {
        //         'exp_type': this.state.editTypeId,
        //         'exp_year': this.state.editYear,
        //         'exp_month': this.state.editMonth,
        //         'exp_value': newValue
        //     }
        // );
    }

    renderTabPanel() {
        return (
            <ul className="nav nav-pills">
                <li className="nav-item">
                    <a className={"nav-link " + (this.state.activeTab === 'tab0' ? "active" : "")}
                       onClick={() => this.setState({activeTab: "tab0"})}
                       href="#">Форма 76</a>
                </li>
                <li className="nav-item">
                    <a className={"nav-link " + (this.state.activeTab === 'tab1' ? "active" : "")}
                       onClick={() => this.setState({activeTab: "tab1"})}
                       href="#">Служители</a>
                </li>
                <li className="nav-item">
                    <a className={"nav-link " + (this.state.activeTab === 'tab2' ? "active" : "")}
                       onClick={() => this.setState({activeTab: "tab2"})}
                       href="#">Отдели</a>
                </li>
            </ul>
        );
    }

    render() {

        const renderHeader = function (expenses: any) {
            return '';
            if (!expenses || Object.keys(expenses).length === 0) return '';

            const types = Object.keys(expenses);
            const years = Object.keys(expenses[types[0]]);
            const firstType = expenses[types[0]];

            return (
                <>
                    <thead className={"text-center"}>
                    <tr>
                        <th rowSpan={2}>Група</th>
                        {
                            years.map(
                                (y: any) => <th key={y} colSpan={Object.keys(firstType[y]).length}>{y}</th>
                            )
                        }

                        <th rowSpan={2}>Всичко</th>
                    </tr>
                    <tr>
                        {
                            years.map(
                                (y: any) => {
                                    return Object.keys(firstType[y]).sort((a, b) => {
                                        return a.localeCompare(b);
                                    }).map(
                                        (m: any) => <th key={y + '.' + m}>{m}</th>
                                    )
                                }
                            )
                        }
                    </tr>
                    </thead>
                </>
            )
        }

        const renderExpYears = function (expenses: any, type: any, editValue: any, me: Form76Page) {
            return '';


            // let expTotal = 0;
            // const cells: any = [];
            // const typeId = type.id;;
            // const exp = expenses[typeId];
            // const years = Object.keys(exp);
            // const parent = type.parent_id === 0 ? null : me.state.types[type.parent_id];
            //
            // years.forEach(
            //     (y: any) => {
            //         const months = Object.keys(exp[y]);
            //         months.sort((a, b) => a.localeCompare(b))
            //             .forEach(
            //                 (m: any) => {
            //                     cells.push(
            //                         <td className={"text-right"} key={'cell_' + y + '.' + m}
            //                             onClick={() => (parent === null ? {} : editValue(type.id, y, m, exp[y][m]))}>
            //                             {zeroToEmptyStringAsCurrency(exp[y][m])}
            //                         </td>);
            //                     expTotal += exp[y][m] * 1;
            //                 }
            //             )
            //     }
            // );
            // cells.push(<td className={"font-weight-bold text-right"}>{zeroToEmptyStringAsCurrency(expTotal.toString())}</td>)
            // return cells;
        }

        const getRowClasses = function (_type: any) {
            if (_type.id === 999) return "bg-secondary text-light font-weight-bold";

            if (_type.parent_id == 0) return "bg-secondary-light font-weight-bold";

            return "";
        }

        return (
            <div className="container" style={{maxWidth: "100%"}}>

                <div className="card shadow">

                    <div className="card-header text-center row">
                        <div className="col-2 text-left">
                            {
                                this.state.loading ?
                                    <div className="spinner-border" role="status">
                                        <span className="sr-only">Loading...</span>
                                    </div>
                                    :
                                    <Link to={PATHS.home + API.getAPITokenAsQuery()}>
                                        <span className="btn btn-secondary"><FontAwesomeIcon icon={faArrowLeft}/></span></Link>
                            }

                        </div>
                        <h2 className="col">Форма 76</h2>
                        <div className="col-auto">
                            {
                                this.renderTabPanel()
                            }
                        </div>
                    </div>

                    { this.state.activeTab === 'tab0' && <Form76Month></Form76Month> }
                    { this.state.activeTab === 'tab1' && <Form76Employees></Form76Employees> }
                    { this.state.activeTab === 'tab2' && <Form76Divisions></Form76Divisions> }



                </div>

            </div>
        );
    }

}
