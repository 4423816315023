import React, {ChangeEvent} from "react";
import {ExtruderMushtukCorrection, ExtruderReport} from "../../types";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {TimePickerComponent} from "../common/TimePickerComponent";
import {Modal} from "react-bootstrap";

export interface ExtruderQualityVacuumProps {
    items: ExtruderMushtukCorrection[],
    onChange: any,
}

export interface ExtruderQualityVacuumState {
    formValues: Map<string, any>,
    showTimePicker: boolean,
    items: ExtruderMushtukCorrection[],
    editorVisible: boolean,
    editIndex: number,
}

export class ExtruderMushtukCorrectionComponent extends React.Component<ExtruderQualityVacuumProps, ExtruderQualityVacuumState> {
    constructor(props: ExtruderQualityVacuumProps) {
        super(props);
        this.state = {
            formValues: new Map<string, any>(),
            showTimePicker: false,
            items: this.props.items || [],
            editorVisible: false,
            editIndex: -1,
        }

        this.handleFormChange = this.handleFormChange.bind(this);
        this.addNewItem = this.addNewItem.bind(this);
        this.saveItem = this.saveItem.bind(this);
        this.editItem = this.editItem.bind(this);
        this.renderFormRow = this.renderFormRow.bind(this);
        this.renderDataRow = this.renderDataRow.bind(this);
    }

    handleFormChange(event: any) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState(
            prevState => {
                return {formValues: prevState.formValues.set(name, value)}
            }
        )
    }

    notifyParent(items: ExtruderMushtukCorrection[]) {
        if(this.props.onChange) {
            this.props.onChange('mushtuk', items);
        }
    }

    saveItem(event: any) {
        const newRec: ExtruderMushtukCorrection = {
            time: this.state.formValues.get('time'),
            description: this.state.formValues.get('description'),
        }
        this.state.formValues.set('time', '');
        this.state.formValues.set('description', '');

        let newItems: ExtruderMushtukCorrection[];

        if(this.state.editIndex === -1) newItems = [...this.state.items, newRec];
        else {
            newItems = [...this.state.items];
            newItems[this.state.editIndex] = newRec;
        }

        this.setState({editorVisible: false, formValues: this.state.formValues, items: newItems});
        this.notifyParent(newItems);
    }


    addNewItem() {
        this.setState({editorVisible: true, formValues: new Map<string, any>(), editIndex: -1});
    }

    editItem(idx: number) {
        const formValues: Map<string, any> = new Map<string, any>(Object.entries(this.state.items[idx]));

        this.setState({editorVisible: true, formValues: formValues, editIndex: idx});
    }

    renderNoDataRow = function () {
        return (
            <tr>
                <td colSpan={4}>Няма въведени данни</td>
            </tr>
        )
    }

    renderDataRow(idx: number) {
        const cq: ExtruderMushtukCorrection = this.state.items[idx];
        return  cq ?
        (
            <tr key={idx}>
                <td>{cq.time}</td>
                <td>{cq.description}</td>
                <td className={"text-nowrap text-right"}>
                    <span className="btn btn-primary btn-light" onClick={() => this.editItem(idx)}><FontAwesomeIcon icon="pen"/></span> &nbsp;
                    <span className="btn btn-primary btn-light" onClick={() => this.removeItem(idx)}><FontAwesomeIcon icon="trash-alt"/></span>
                </td>
            </tr>
        ) : null;
    }

    renderFormRow() {
        return (
            <tr>
                <td style={{width: "100px"}}><input className="form-control" type="text" readOnly={true} name="time" required
                           value={this.state.formValues.get('time')}
                           onClick={() => this.setState({showTimePicker: !this.state.showTimePicker})}
                />
                    {
                        this.state.showTimePicker && <TimePickerComponent
                            onSelect={(time: string) => this.setState({showTimePicker: false, formValues: this.state.formValues.set('time', time)})}
                            value={this.state.formValues?.get('time')}/>
                    }
                </td>
                <td>
                    <input name={'description'} value={this.state.formValues.get('description')} onChange={this.handleFormChange}
                           type="text" className={"form-control p-0 m-0"}/>
                </td>
            </tr>
        )
    }

    removeItem(idx: number) {
        const newItems = [...this.state.items];
        newItems.splice(idx, 1);
        this.setState({ items: newItems });
        this.notifyParent(newItems);
    }

    renderData() {
        const rows = [];
        const cnt = this.state.items.length;
        for(let idx = 0; idx < cnt; idx++) {
            rows.push(this.renderDataRow(idx))
        }
        return rows;
    }

    render() {
        return (
            <>
                <table className="table table-hover table-sm">
                    <thead>
                    <tr>
                        <th style={{maxWidth: "12rem"}}>Час</th>
                        <th>Обяснение</th>
                        <th style={{width: "3rem"}}></th>
                    </tr>
                    </thead>
                    <tbody>
                    {
                        this.state.items.length === 0 && this.renderNoDataRow()
                    }
                    {
                        this.renderData()
                    }
                    <td colSpan={3}>
                        <button className={"btn btn-outline-primary w-100 font-weight-bold"} onClick={this.addNewItem}>
                            Добави нов запис
                        </button>
                    </td>
                    </tbody>
                </table>


                <Modal
                    className={"modal-90 m-3"} centered
                    show={this.state.editorVisible}
                    onHide={() => this.setState({editorVisible: false})}
                >
                    <Modal.Header closeButton>
                        <Modal.Title>Добавяне/корекция</Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                        <table className="table table-hover table-sm">
                            <thead>
                            <tr>
                                <th style={{maxWidth: "12rem"}}>Час</th>
                                <th>Обяснение</th>
                            </tr>
                            </thead>
                            <tbody>
                            {
                                this.renderFormRow()
                            }
                            </tbody>
                        </table>

                    </Modal.Body>

                    <Modal.Footer>
                        <button type={"button"} className={"btn btn-secondary"} onClick={this.saveItem}>Запази</button>
                        <button type={"button"} className={"btn btn-primary"} onClick={() => this.setState({editorVisible: false})}>Откажи</button>
                    </Modal.Footer>
                </Modal>
            </>
        );
    }
}
