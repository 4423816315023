import {UserModule} from "../../types";
import {ModuleComponent} from "./ModuleComponent";


export interface ModulesComponentProps {
    modules: UserModule[];
}

export function ModulesComponent(props: ModulesComponentProps) {

    const onSelectModule = function (modul: UserModule) {
    }

    const getModules = function():UserModule[] {
        return props.modules;
    }

    const blocks: any = [];
    let lastGroup = '';
    getModules().map(
        (item: UserModule, idx: number) => {
            if(lastGroup !== item.module.group_name) {
                lastGroup = item.module.group_name;
                blocks.push(<div className={"row text-center "}><div className={"col mt-3"}><h5>{lastGroup}</h5></div></div>)
            }
            blocks.push(<ModuleComponent key={idx} module={item.module} onClick={onSelectModule} />);
        }
    )

    return ( <>{ blocks }</> )
}
