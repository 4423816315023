import React, {useState} from "react";
import {Client, Driver, Logistic} from "../../types";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

export interface LogisticsListProps {
    filter: any,
    loading: boolean;
    logistics: Logistic[];
    onEditLogistic: (logistic: Logistic) => any;
    onRemoveLogistic: (logistic: Logistic) => any;
    onSelect?: (logistic: Logistic, driver: Driver) => any;
    onApplyFilter: (filter: Map<string, any>) => any;
}

export function LogisticsList(props: LogisticsListProps) {
    // let searchTimeOut: any;
    const [formValues, setFormValues] = useState(new Map<string, any>(props.filter));

    const handleFormChange = function (event: any) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        formValues.set(name, value);
        setFormValues(new Map<string, any>(formValues));

        // if(searchTimeOut) window.clearTimeout(searchTimeOut);
        // searchTimeOut = window.setTimeout(() => {
        //     props.onApplyFilter(formValues);
        // }, 2000);

    }

    const clearFilter = function () {
        formValues.set('name', '');
        formValues.set('driver', '');
        setFormValues(new Map<string, any>(formValues));
        props.onApplyFilter(formValues);
    }

    const filter = <tr>
        <td>
            <input className={"w-100"} name={"name"} value={formValues.get("name")} onChange={handleFormChange}/>
        </td>
        <td>
            <input className={"w-100"} name={"driver"} value={formValues.get("driver")} onChange={handleFormChange}/>
        </td>
        <td className={"text-right"}>
            <button type={"button"} className={"btn btn-secondary btn-sm"} onClick={() => {
                props.onApplyFilter(formValues)
            }}>
                <FontAwesomeIcon icon={"search"}/>
            </button>
            &nbsp;
            <button type={"button"} className={"btn btn-secondary btn-sm"} onClick={() => clearFilter()}>
                <FontAwesomeIcon icon={"times"}/>
            </button>
        </td>
    </tr>;

    const renderData = function() {
        if(props.logistics.length == 0) return [filter].concat([<tr><td colSpan={3}><strong>Няма намерени транспортни фирми.</strong></td></tr>])

        return [filter].concat(props.logistics.map(
            item => <tr>
                <td>{ item.name }</td>
                <td>
                    <ul className={"nav nav-pills nav-stacked"}>
                        {item.drivers.map((i, idx) => <li key={idx}>
                            <a className={"btn btn-outline-secondary btn-sm"}
                               onClick={() => {
                                   if (props.onSelect) props.onSelect(item, i);
                               }}>
                                {i.name} ({i.reg_nomer})
                            </a>
                        </li>)}
                    </ul>
                </td>

                <td className={"text-right"}>
                    <span className="btn btn-primary btn-sm btn-light" onClick={() => props.onEditLogistic(item)}><FontAwesomeIcon icon="pen"/></span> &nbsp;
                    <span className="btn btn-primary btn-sm btn-light" onClick={() => props.onRemoveLogistic(item)}><FontAwesomeIcon icon="trash-alt"/></span>
                </td>
            </tr>)
        );
    }

    return (
        <table className={"table table-sm table-hover"}>
            <thead>
            <tr className={"bg-secondary text-light"}>
                <th>Транспортна фирма</th>
                <th>Шофьори</th>
                <th className={"w-120px"}></th>
            </tr>
            </thead>
            <tbody>
            {
                props.loading ?
                    <tr>
                        <td colSpan={5}>
                            <div className="spinner-border" role="status">
                                <span className="sr-only">Loading...</span>
                            </div>
                        </td>
                    </tr>
                    :
                    renderData()
            }
            </tbody>
        </table>
    );
}
