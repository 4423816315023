import {Modal} from "react-bootstrap";
import React from "react";

export interface ConfirmDeleteModalProps {
    working: boolean;
    onConfirm: () => any;
    onReject: () => any;
}

export function ConfirmDeleteModal(props: ConfirmDeleteModalProps) {
    return (
        <Modal
        show={true}
        onHide={() => props.onReject()}>

        <Modal.Header closeButton>
            <Modal.Title>Внимание</Modal.Title>
        </Modal.Header>

        <Modal.Body>
            <p>Моля, потвърдете изтриването!</p>
        </Modal.Body>

        <Modal.Footer>
            {
                props.working ?
                    <div className="spinner-border" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                    :
                    <>
                        <button type={"button"} className={"btn btn-secondary"} onClick={() => props.onReject()}>Откажи</button>
                        <button type={"button"} className={"btn btn-primary"} onClick={() => props.onConfirm()}>Изтрий</button>
                    </>
            }
        </Modal.Footer>
    </Modal>
    )
}
