import React from "react";
import moment, {Moment} from "moment";
import {API} from "../../api";
import {API_CALL} from "../../types";
import {isError, isLoading, isSuccess, showErrorsInToast, translateError} from "../../common";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Form76DivisionEditModal} from "./Form76DivisionEditModal";
import {ConfirmModal} from "../common/ConfirmModal";

interface Form76DivisionsProps {
}

interface Form76DivisionsState {
    loading: boolean,
    addDivision: boolean,
    deleteDivision: Division|null,
    editDivision: Division|null,
    divisions: Division[];
}

export interface Division {
    id: number;
    name: string;
}

export class Form76Divisions extends React.Component<Form76DivisionsProps, Form76DivisionsState> {

    constructor(props: any) {
        super(props);

        this.state = {
            loading: true,
            addDivision: false,
            deleteDivision: null,
            editDivision: null,
            divisions: [],
        }

        this.loadReport = this.loadReport.bind(this);
        this.editDivision = this.editDivision.bind(this);
        this.deleteDivision = this.deleteDivision.bind(this);
        this.saveDivision = this.saveDivision.bind(this);
    }

    componentDidMount() {
        this.loadReport();
    }

    loadReport() {
        API.getForm76Divisions(
            (apiCall: API_CALL) => {
                this.setState({loading: isLoading(apiCall)});

                if (isError(apiCall)) showErrorsInToast(apiCall, 'Грешка', translateError);

                if (isSuccess(apiCall)) {
                    this.setState({divisions: apiCall.data.data.divisions, addDivision: false, editDivision: null});
                }
            }
        );
    }

    editDivision(d: Division) {
        this.setState({editDivision: d})
    }

    deleteDivision(d: Division) {
        this.setState({deleteDivision: d})
    }

    doDeleteDivision(btn: string) {
        this.setState({deleteDivision: null});
        if(btn === "Изтрий")
        API.deleteForm76Division(
            (apiCall: API_CALL) => {
                this.setState({loading: isLoading(apiCall)});

                if (isError(apiCall)) showErrorsInToast(apiCall, 'Грешка', translateError);

                if (isSuccess(apiCall)) {
                    this.loadReport()
                }
            }, this.state.deleteDivision as Division)
    }

    saveDivision(newValue: string) {
        this.setState({addDivision: false, editDivision: null});

        if(this.state.addDivision) {
            API.addForm76Division(
                (apiCall: API_CALL) => {
                    this.setState({loading: isLoading(apiCall)});

                    if (isError(apiCall)) showErrorsInToast(apiCall, 'Грешка', translateError);

                    if (isSuccess(apiCall)) {
                        this.loadReport()
                    }
                }, newValue
            );
        } else {
            API.updateForm76Division(
                (apiCall: API_CALL) => {
                    this.setState({loading: isLoading(apiCall)});

                    if (isError(apiCall)) showErrorsInToast(apiCall, 'Грешка', translateError);

                    if (isSuccess(apiCall)) {
                        this.loadReport()
                    }
                }, {...this.state.editDivision, name: newValue} as Division
            );
        }
    }


    render() {
        return (
            <>
                <div className="card-body">
                    <div className="row justify-content-center">
                        <div className="col-8">


                            <div className={"w-100 overflow-auto"}>
                                <table className={"table table-bordered w-100 "} style={{opacity: (this.state.loading ? 0.3 : 1)}}>
                                    <thead>
                                    <tr>
                                        <th>Отдел</th>
                                        <th className={"w-100px text-right"}></th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {
                                        this.state.divisions.map(
                                            d =>
                                                <tr>
                                                    <td>{d.name}</td>
                                                    <td>
                                                        <button type={"button"} className={"btn btn-secondary btn-sm"} onClick={() => {this.editDivision(d);}}>
                                                            <FontAwesomeIcon icon={"pen"}/>
                                                        </button>
                                                        &nbsp;
                                                        <button type={"button"} className={"btn btn-secondary btn-sm"} onClick={() => {this.deleteDivision(d)}}>
                                                            <FontAwesomeIcon icon={"trash-alt"}/>
                                                        </button>
                                                    </td>
                                                </tr>
                                        )
                                    }
                                    </tbody>
                                </table>
                            </div>

                        </div>

                    </div>

                </div>

                <div className="card-footer">
                    <button type={"button"} className={"btn btn-primary"}  onClick={() => {this.setState({addDivision: true})}}>Добави</button>
                </div>

                {
                    (this.state.editDivision || this.state.addDivision) &&
                    <Form76DivisionEditModal
    d={this.state.editDivision}
    onSave={this.saveDivision}
    onClose={() => this.setState({editDivision: null, addDivision: false})} />
                }

                {
                    this.state.deleteDivision &&
                        <ConfirmModal
                            title={"Внимание"}
                            message={"Моля, потвърдете изтриването на отдела."}
                            buttons={["Изтрий", "Откажи"]}
                            primaryBtn={"Откажи"}
                            onClose={() => this.setState({deleteDivision: null})}
                            onButtonClick={(btn) => this.doDeleteDivision(btn)}
                            ></ConfirmModal>
                }
            </>
        )
    }
}
