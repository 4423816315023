export enum PATHS {
    home= "/",
    login="/login",
    admin="/admin",
    accounts="/admin/accounts",
    account="/admin/accounts/",
    aaccount_new="/admin/accounts/new",

    heater="/heater",
    grinding="/grinding",
    ordering="/ordering",
    unloading="/unloading",
    extruder="/extruder",
    oven="/oven",
    sales="/sales",
    daily_reports="/daily_reports",
    repairs="/repairs",
    refueling="/refueling",
    comments="/reports/production/comments",
    standarts="/standarts",
    clients="/admin/clients",
    logistics="/admin/logistics",
    properties="/admin/properties",
    production_report="/reports/production",
    bank_expenses="/bank/expenses",
    form76="/form76",

}

export enum API_PATHS {
    login="login",
    logout="user/logout",
    allUsersNotSigned="user/all",
    accounts="admin/accounts",
    appModules="admin/app-modules",
    user="user",
    user_login="user/login",
    properties="admin/properties",
    expeditions_notes="admin/expedition_notes",
    clients="admin/clients",
    client="admin/client",
    logistics="admin/logistics",
    grinding="admin/grinding",
    heater="admin/heater",
    ordering="admin/ordering",
    oven="admin/oven",
    unloading="admin/unloading",
    daily_report="admin/daily_reports",
    full_daily_report="admin/dailyfullreport",
    repairs="admin/repairs",
    extruder="admin/extruder",
    refueling="admin/refueling",
    standarts="admin/standarts",
    production_report="admin/reports/production",
    repairs_report="admin/reports/repairs",
    bricks_produced="admin/reports/bricks-produced",
    bricks_waste="admin/reports/bricks-waste",
    bricks_waste_by_type="admin/reports/bricks-waste-by-type",
    fuel_report="admin/reports/fuel",
    materials_report="admin/reports/materials",
    comments_report="admin/reports/comments",

    bricks_sales_report="admin/financial_reports/bricks-sales",
    sludge_pelets_saldo_report="admin/financial_reports/sludge-pelets-saldo",
    income_outgoing_report="admin/financial_reports/income-outgoing",
    account_balance="admin/financial_reports/accounts-balance",
    account_palets_balance="admin/financial_reports/accounts-palets-balance",

    bank_expenses="admin/bank/expenses",

    form76="admin/form76",
}
