import React from "react";
import {AccountOperation, API_CALL, Client, OPERATION_TYPE, PAY_TYPE} from "../../types";
import moment from "moment";
import {API} from "../../api";
import {isError, isLoading, isSuccess, showErrorsInToast, translateError} from "../../common";
import {Modal} from "react-bootstrap";
import {EditAccountOperation} from "./EditAccountOperation";
import {forEach} from "react-bootstrap/ElementChildren";

export interface EditClientPaletDetailsProps {
    client: Client,
    onReload: any,
    onCancel: any;
}

export interface EditClientPaletDetailsState {
    loading: boolean,
    report: any[],
}

export class EditClientPaletDetails extends React.Component<EditClientPaletDetailsProps, EditClientPaletDetailsState> {
    constructor(props: EditClientPaletDetailsProps) {
        super(props);
        this.state = {
            loading: false,
            report: [],
        }
        this.cancelClient = this.cancelClient.bind(this);
    }

    componentDidMount() {
        this.loadData();
    }

    loadData() {
        API.getClientPaletList(
            (apiCall: API_CALL) => {
                this.setState({loading: isLoading(apiCall)});
                if (isError(apiCall)) {
                    showErrorsInToast(apiCall, 'Грешка');
                }
                if (isSuccess(apiCall)) {
                    this.setState({ report: apiCall.data.data.report });
                }
            }, this.props.client.id
        )
    }

    cancelClient() {
        this.props.onCancel();
    }

    renderReport() {
        const html: any[] = [];
        const dates = Object.keys(this.state.report);
        const datesContent = Object.values(this.state.report);

        console.log(dates, datesContent);

        dates.forEach(
            (date: string, idx: number) => {
                const notesNumbers = Object.keys(datesContent[idx]);

                notesNumbers.forEach(
                    (number: string, idx1: number) =>
                    {
                        const noteVals: any = Object.values(datesContent[idx]);
                        if(idx1 === 0) {
                            html.push(
                                <tr>
                                    <td rowSpan={notesNumbers.length}>{ date }</td>
                                    <td>{ number }</td>
                                    <td>{ noteVals[idx1].type }</td>
                                    <td style={{textAlign: 'right'}}>{ noteVals[idx1].getted }</td>
                                    <td style={{textAlign: 'right'}}>{ noteVals[idx1].returned }</td>
                                    <td style={{textAlign: 'right', fontWeight: 'bold', color: (noteVals[idx1].balance < 0 ? 'red': 'green')}}>{ noteVals[idx1].balance }</td>
                                </tr>);
                        } else {
                            html.push(
                                <tr>
                                    <td>{ number }</td>
                                    <td>{ noteVals[idx1].type }</td>
                                    <td style={{textAlign: 'right'}}>{ noteVals[idx1].getted }</td>
                                    <td style={{textAlign: 'right'}}>{ noteVals[idx1].returned }</td>
                                    <td style={{textAlign: 'right', fontWeight: 'bold', color: (noteVals[idx1].balance < 0 ? 'red': 'green')}}>{ noteVals[idx1].balance }</td>
                                </tr>);
                        }
                    }
                )
            }
        )

        console.log(html);

        return html;
    }

    render() {
        return (
            <>
                <table className={"table table-sm"}>
                    <thead>
                    <th>Дата</th>
                    <th>Номер</th>
                    <th>Тип</th>
                    <th className={"text-right"}>Взети</th>
                    <th className={"text-right"}>Върнати</th>
                    <th className={"text-right"}>Баланс</th>
                    </thead>
                    <tbody>
                    {
                        this.state.report.length == 0 &&
                            <tr>
                                <td colSpan={6}>
                                    { this.state.loading ? 'Зареждам ...' : 'Няма данни' }
                                </td>
                            </tr>
                    }
                    {
                        this.renderReport()
                    }
                    </tbody>
                </table>
            </>
        );
    }

    // render() {
    //     return ( this.state.loading ?
    //         <>
    //             <div className="spinner-border" role="status">
    //                 <span className="sr-only">Loading...</span>
    //             </div>
    //         </>
    //         :
    //         <div>
    //             <div className="row">
    //                 <div className="col-6">
    //                     <div className={"row"}>
    //                         <div className="col-6"><label>БАНКА:</label></div>
    //                         <div className={"col-6 text-right font-weight-bold text-light pt-1 " + (this.state.balance_bank < 0 ? "bg-danger" : "bg-success") }>
    //                             {Number(this.state.balance_bank).toFixed(2)} лв</div>
    //                     </div>
    //
    //                     <table className={"table table-sm"}>
    //                         <thead>
    //                         <tr>
    //                             <th>Дата</th>
    //                             <th>Описание</th>
    //                             <th className={"text-right"}>Сума</th>
    //                         </tr>
    //                         </thead>
    //                         <tbody>
    //                         {this.renderAccountRows(PAY_TYPE.BANKA)}
    //                         <tr>
    //                             <td colSpan={3}>
    //                                 <button className={"btn btn-outline-primary w-100 font-weight-bold"}
    //                                         onClick={() => this.addItem(PAY_TYPE.BANKA)}>
    //                                     Добави нов запис
    //                                 </button>
    //                             </td>
    //                         </tr>
    //                         </tbody>
    //                     </table>
    //                 </div>
    //
    //                 <div className="col-6">
    //                     <div className={"row"}>
    //                         <div className="col-6"><label>КЕШ:</label></div>
    //                         <div className={"col-6 text-right font-weight-bold text-light pt-1 " + (this.state.balance_cash < 0 ? "bg-danger" : "bg-success") }>
    //                             {Number(this.state.balance_cash).toFixed(2)} лв </div>
    //                     </div>
    //
    //                     <table className={"table table-sm"}>
    //                         <thead>
    //                         <tr>
    //                             <th>Дата</th>
    //                             <th>Описание</th>
    //                             <th className={"text-right"}>Сума</th>
    //                         </tr>
    //                         </thead>
    //                         <tbody>
    //                         {this.renderAccountRows(PAY_TYPE.CASH)}
    //                         <tr>
    //                             <td colSpan={3}>
    //                                 <button className={"btn btn-outline-primary w-100 font-weight-bold"}
    //                                         onClick={() => this.addItem(PAY_TYPE.CASH)}>
    //                                     Добави нов запис
    //                                 </button>
    //                             </td>
    //                         </tr>
    //                         </tbody>
    //                     </table>
    //                 </div>
    //             </div>
    //
    //             <div className="row">
    //                 <div className="col">
    //                     <button type={"button"} className={"btn btn-primary"} onClick={this.cancelClient}>Затвори</button>
    //                 </div>
    //             </div>
    //
    //             {
    //                 this.state.editOperation &&
    //                 <Modal
    //                     show={true}
    //                     onHide={() => {
    //                         this.setState({editOperation: null})
    //                     }}
    //                 >
    //                     <Modal.Header closeButton>
    //                         <Modal.Title>{(this.state.isNew ? 'Добавяне' : 'Корекция')} на операция</Modal.Title>
    //                     </Modal.Header>
    //                     <Modal.Body>
    //                         <div className={"modal-90 m-3"} style={{maxWidth: '30em'}}>
    //                             <EditAccountOperation
    //                                 operation={this.state.editOperation}
    //                                 onSave={operation => this.saveItem(operation, this.state.isNew)}
    //                                 onRemove={() => this.removeOperation(this.state.editOperation)}
    //                                 onCancel={() => this.setState({editOperation: null})}
    //                             />
    //                         </div>
    //                     </Modal.Body>
    //                 </Modal>
    //             }
    //         </div>
    //     );
    // }

}
