import React from "react";
import {API} from "../api";
import {API_CALL} from "../types";
import {isLoading, isSuccess} from "../common";
import {ModulesComponent} from "../components/homepage/ModulesComponent";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

interface HomePageState {
    loading: boolean,
    user: any
}

export default class HomePage extends React.Component<any, HomePageState> {

    constructor(props: any) {
        super(props);

        this.state = {
            loading: false,
            user: null
        }

        console.log('HomePage : Constructor');
    }

    componentDidMount() {
        console.log('HomePage : componentDidMount');
        API.getUserData(
            (apiCall: API_CALL) => {
                this.setState({ loading: isLoading(apiCall) });

                if (isSuccess(apiCall)) {
                    console.log("User data loaded");
                    console.log(apiCall.data.data);
                    this.setState({user: apiCall.data.data.user});
                    if(apiCall.data.data.user.sorted.length === 1) {
                        window.location.href = apiCall.data.data.user.sorted[0].module.url + API.getAPITokenAsQuery();
                    }
                }
            }
        );
    }

    render() {
        console.log('HomePage : render');

        return (
                <div className="container">
                    <div className="card shadow">
                        <div className="card-header text-center row">
                            <div className="col-3 text-left">
                                {
                                    !this.state.user &&
                                    <div className="spinner-border" role="status">
                                        <span className="sr-only">Loading...</span>
                                    </div>
                                }
                            </div>
                            <h2 className="col">Начален екран</h2>
                            <div className="col-3">

                            </div>
                        </div>

                        <div className="card-body">
                            <div className="row">
                                <div className="col p-2">
                                    {
                                        this.state.user &&
                                        <ModulesComponent modules={this.state.user.sorted} />
                                    }
                                </div>
                            </div>
                        </div>

                        <div className="card-footer">
                            <span className="text-info">Моля, изберете модул, за да продължите...</span>
                        </div>

                    </div>
                </div>
        );
    }

}
