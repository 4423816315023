import React, {ChangeEvent} from "react";
import {API_CALL, Charging, ExtruderProduction, HeaterReport, PropertyValue} from "../../types";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {TimePickerComponent} from "../common/TimePickerComponent";
import {Modal} from "react-bootstrap";
import {showMsgInToast} from "../../common";

export interface HeaterChargingProps {
    report: Charging[],
    pelet_types: PropertyValue[],
    bag_types: PropertyValue[],
    onChange: any,
}

export interface HeaterChargingState {
    items: Charging[],
    formValues: Map<string, any>,
    pelet_types: PropertyValue[],
    bag_types: PropertyValue[],
    showTimePicker: boolean,
    editorVisible: boolean,
    editIndex: number,
}

export class HeaterChargingComponent extends React.Component<HeaterChargingProps, HeaterChargingState> {
    constructor(props: HeaterChargingProps) {
        super(props);
        this.state = {
            formValues: new Map<string, any>(),
            items: props.report || [],
            pelet_types: [...props.pelet_types],
            bag_types: [...props.bag_types],
            showTimePicker: false,
            editorVisible: false,
            editIndex: -1,
        }

        this.handleFormChange = this.handleFormChange.bind(this);
        this.addNewItem = this.addNewItem.bind(this);
        this.saveItem = this.saveItem.bind(this);
        this.editItem = this.editItem.bind(this);
        this.renderFormRow = this.renderFormRow.bind(this);
        this.renderDataRow = this.renderDataRow.bind(this);
    }

    handleFormChange(event: any) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState(
            prevState => {
                return {formValues: prevState.formValues.set(name, value)}
            }
        );

        this.notifyParent(this.state.items);
    }

    notifyParent(items: any) {
        if(this.props.onChange) {
            this.props.onChange('chargings', items);
        }
    }

    saveItem(event: any) {
        const charging: Charging = {
            chargeTime: this.state.formValues.get('chargeTime'),
            pelet: this.state.formValues.get('pelet'),
            bagType: this.state.formValues.get('bagType'),
            bagQty: this.state.formValues.get('bagQty'),
            notes: this.state.formValues.get('notes'),
        }

        if((!charging.chargeTime) || (!charging.pelet) ||(!charging.bagType) ||(!charging.bagQty)) {
            showMsgInToast("Всички полета са задължителни. Моля, попълнете ги и опитайте пак...");
            return;
        }


        const fv = this.state.formValues;
        fv.forEach((v,k) => fv.set(k, ""));

        let newItems: Charging[] = [];
        if(this.state.editIndex === -1) newItems = [...this.state.items, charging];
        else {
            newItems = [...this.state.items];
            newItems[this.state.editIndex] = charging;
        }


        this.setState({editorVisible: false, items: newItems, formValues: fv});

        this.notifyParent(newItems);
    }


    addNewItem() {
        const fv = new Map<string, any>();
        fv.set('bagQty', 1);
        this.setState({editorVisible: true, formValues: fv, editIndex: -1});
    }

    editItem(idx: number) {
        const formValues: Map<string, any> = new Map<string, any>(Object.entries(this.state.items[idx]));

        this.setState({editorVisible: true, formValues: formValues, editIndex: idx});
    }

    renderNoDataRow = function () {
        return (
            <tr>
                <td colSpan={6}>Няма въведени данни</td>
            </tr>
        )
    }

    renderDataRow(item: Charging, idx: number) {
        return (
            <tr key={idx}>
                <td>{item.chargeTime}</td>
                <td>{this.props.pelet_types[item.pelet]?.value}</td>
                <td>{this.props.bag_types[item.bagType]?.value}</td>
                <td>{item.bagQty}</td>
                <td>{item.notes}</td>
                <td className={"text-nowrap text-right"}>
                    <span className="btn btn-primary btn-light" onClick={() => this.editItem(idx)}><FontAwesomeIcon icon="pen"/></span> &nbsp;
                    <span className="btn btn-primary btn-light" onClick={() => this.removeItem(idx)}><FontAwesomeIcon icon="trash-alt"/></span>
                </td>
            </tr>
        )
    }

    renderFormRow() {
        return (
            <tr>
                <td className={"w-100px"}>
                    <input className="form-control" type="text" readOnly={true} name="chargeTime" required
                           value={this.state.formValues.get('chargeTime')}
                           onClick={() => this.setState({showTimePicker: !this.state.showTimePicker})}
                    />
                    {
                        this.state.showTimePicker && <TimePickerComponent
                            onSelect={(time: string) => this.setState({showTimePicker: false, formValues: this.state.formValues.set('chargeTime', time)})}
                            value={this.state.formValues?.get('chargeTime')}/>
                    }
                </td>
                <td>
                    <select className="form-control" name="pelet"
                            value={this.state.formValues.get('pelet')} onChange={this.handleFormChange} required>
                        <option value={-1}></option>
                        {  this.state.pelet_types.map((i: PropertyValue, idx: number) => <option value={idx}  key={idx}>{i.value}</option>) }
                    </select>
                </td>
                <td>
                    <select className="form-control" name="bagType"
                            value={this.state.formValues.get('bagType')} onChange={this.handleFormChange} required>
                        <option value={-1}></option>
                        {  this.state.bag_types.map((i: PropertyValue, idx: number) => <option value={idx} key={idx}>{i.value}</option>)}
                    </select>
                </td>
                <td>
                    <input className="form-control" type="number" name="bagQty" required
                           value={this.state.formValues.get('bagQty') || '1'}
                           onChange={this.handleFormChange}/>
                </td>
                <td>
                    <input className="form-control" type="text" name="notes" required
                           value={this.state.formValues.get('notes')}
                           onChange={this.handleFormChange}/>
                </td>
            </tr>
        )
    }

    removeItem(idx: number) {
        const _items = [...this.state.items];
        _items.splice(idx, 1);
        this.setState({items: _items});
        this.notifyParent(_items);
    }

    render() {
        return (
            <>
                <table className="table table-hover table-sm">
                    <thead>
                    <tr>
                        <th className={"w-120px"}>Час</th>
                        <th className={"w-120px"}>Вид пелети</th>
                        <th className={"w-240px"}>Вид баг</th>
                        <th className={"w-120px"}>Брой багове</th>
                        <th>Забележки</th>
                        <th style={{width: "7rem"}}></th>
                    </tr>
                    </thead>
                    <tbody>
                    {
                        this.state.items.length === 0 && this.renderNoDataRow()
                    }
                    {
                        this.state.items.map(
                            (i: Charging, idx: number) => this.renderDataRow(i, idx)
                        )
                    }
                    <td colSpan={6}>
                        <button className={"btn btn-outline-primary w-100 font-weight-bold"} onClick={this.addNewItem}>
                            Добави нов запис
                        </button>
                    </td>
                    </tbody>
                </table>


                <Modal
                    className={"modal-90 m-3"} centered
                    show={this.state.editorVisible}
                    onHide={() => this.setState({editorVisible: false})}
                >
                    <Modal.Header closeButton>
                        <Modal.Title>Добавяне/корекция</Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                        <table className="table table-hover table-sm">
                            <thead>
                            <tr>
                                <th className={"w-120px"}>Час</th>
                                <th className={"w-120px"}>Вид пелети</th>
                                <th className={"w-240px"}>Вид баг</th>
                                <th className={"w-120px"}>Брой багове</th>
                                <th>Забележки</th>
                            </tr>
                            </thead>
                            <tbody>
                            {
                                this.renderFormRow()
                            }
                            </tbody>
                        </table>

                    </Modal.Body>

                    <Modal.Footer>
                        <button type={"button"} className={"btn btn-secondary"} onClick={this.saveItem}>Запази</button>
                        <button type={"button"} className={"btn btn-primary"} onClick={() => this.setState({editorVisible: false})}>Откажи</button>
                    </Modal.Footer>
                </Modal>
            </>
        );
    }
}
