import React, {forwardRef} from "react";
import DatePicker, {registerLocale} from "react-datepicker";
import bg from "date-fns/locale/bg";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import moment, {Moment} from "moment";

export interface ReadOnlyDatePickerProps {
    classes?: string
    onChange: any;
    value: string;
    hideIcon?: boolean;
    hideDayControls?: boolean;
}

// @ts-ignore
const ExampleCustomInput = forwardRef(
// @ts-ignore
    ({ value, onClick }, ref: any) => (
        <button className="form-control w-100 text-left" onClick={onClick} ref={ref} type={"button"} >
            {value}
        </button>
    ),
);


export const ReadOnlyDatePickerComponent = function (props: ReadOnlyDatePickerProps) {
    const handleChange = (date: any, e: any) => {
        if (e && typeof e.preventDefault === 'function') {
            e.preventDefault();
        }

        const {onChange} = props;

        if (onChange) {
            onChange(date);
        }
    };

    const addDay = () => {
      handleChange(moment(props.value, 'DD.MM.YYYY').add(1, 'day'), null);
    }
    const subDay = () => {
        handleChange(moment(props.value, 'DD.MM.YYYY').subtract(1, 'day'), null);
    }

    registerLocale("bg", bg);
    return (
        <div className={"row flex-nowrap m-0 p-0"}>
            <label>
            <div className={"col row mr-0 pr-0 " + (props.classes || "")}>
                    <DatePicker
                        locale="bg"
                        className="form-control w-100px float-left"
                        id="selectedDate"
                        value={props.value}
                        onChange={handleChange}
                        customInput={<ExampleCustomInput />}
                        shouldCloseOnSelect={true}
                        todayButton={true}
                    />
            </div>

            {
                !props.hideIcon &&
                <span className="input-group-text col-auto"><FontAwesomeIcon icon="calendar-alt" size="lg"/></span>
            }
            </label>
                {
                    !props.hideDayControls &&
                    <div className={"col-auto"}>
                            <a className={'btn btn-secondary btn-sm'} onClick={() => subDay()}>&#8882;</a>
                            <a className={'btn btn-secondary btn-sm'} onClick={() => addDay()}>&#8883;</a>
                    </div>
                }
        </div>
    )
}
