import React from "react";
import {$enum} from "ts-enum-util";
import {API_CALL, Client, PropertyValue, REPAIR_TYPE} from "../../types";
import {BlockTitleComponent} from "../common/BlockTitleComponent";
import {ReportsPageProps} from "../../pages/ReportsPage";
import {API} from "../../api";
import {isLoading, isSuccess, minutesToHours, numberWithCommas, zeroToEmptyString, zeroToEmptyStringAsCurrency} from "../../common";
import moment, {Moment} from "moment";
import {EditClientAccountModal} from "../clients/EditClientAccountModal";


interface ClientsPaletsBalanceReportPageState {
    loading: boolean,
    periodTo: string,
    data: any,
    editClientAccount: Client | null,
}

interface ClientsPaletsBalanceReportPageProps {
    periodTo: string
}

export class ClientsPaletsBalanceReportPage extends React.Component<ClientsPaletsBalanceReportPageProps, ClientsPaletsBalanceReportPageState> {

    constructor(props: ClientsPaletsBalanceReportPageProps) {
        super(props);
        this.state = {
            periodTo: props.periodTo,
            loading: false,
            data: null,
            editClientAccount: null,
        }

        this.loadData = this.loadData.bind(this);
    }

    async componentDidMount() {
        this.loadData();
    }

    loadData() {
        API.getAccountPaletsBalance(
            (apiCall: API_CALL) => {
                this.setState({loading: isLoading(apiCall)});

                if (isSuccess(apiCall)) {
                    // console.log(apiCall.data.data);
                    this.setState({data: apiCall.data.data.list});
                }
            }, {
                periodTo: this.props.periodTo,
            }
        )
    }

    componentDidUpdate(prevProps: Readonly<ClientsPaletsBalanceReportPageProps>, prevState: Readonly<ClientsPaletsBalanceReportPageState>, snapshot?: any) {
        if (
            (this.state.periodTo !== this.props.periodTo)
        ) {
            this.setState({
                periodTo: this.props.periodTo
            });
            this.loadData();
        }
    }

    renderReports() {

        return <table className={"table table-sm align-top text-center table-bordered"}>
            <thead>
            <tr>
                <th>№</th>
                <th className={"text-left"}>Клиент</th>
                <th className={"text-left"}>Градове</th>
                <th>Взети палети</th>
                <th>Върнати палети</th>
                <th>Баланс палети</th>
            </tr>
            </thead>
            <tbody>
            {
                this.state.data
                    .map(
                    (item: any, idx: number) => {
                        return <tr>
                            <td>{(idx + 1)}</td>
                            <td className={"text-left"}>
                                <a href={"#"}
                                   onClick={(e) =>
                                        {e.preventDefault(); this.setState({editClientAccount: {id: item.id, name: item.client} as Client})}}
                                >
                                    {item.name}</a></td>
                            <td className={"text-left"}>{JSON.parse(item.cities).map((c: any) => c.city + (c.phone ? ' (' + c.phone + ')' : '')).join('; ')}</td>
                            <td className={"text-right"}>{ item.getted }</td>
                            <td className={"text-right"}>{ item.returned }</td>
                            <td className={"text-right font-weight-bold text-light " + (item.balance > 0 ? 'bg-success' : 'bg-danger')}>{ item.balance }</td>
                        </tr>
                    }
                )
            }
            </tbody>
        </table>;
    }

    render() {
        return (
            <>
                <div className="row">
                    <div className="col">
                        {
                            this.state.loading &&
                            <div className={"m-3"}>Зареждам данните ...</div>
                        }
                    </div>
                </div>

                {
                    !this.state.loading && this.state.data && this.renderReports()
                }
                {
                    this.state.editClientAccount &&
                    <EditClientAccountModal client={this.state.editClientAccount}
                                            onReload={() => {}}
                                            onClose={() => {this.setState({editClientAccount: null})}}/>
                }
            </>
        );
    }
}
