import React from "react";
import {API_CALL, BaseReportInfo, ExtruderReport, HeaterReport, OrderingReport, OvenReport, PARAMETERS, PropertyValue, RepairsReport, Report} from "../types";
import {isLoading, isSuccess} from "../common";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {API} from "../api";
import {Link} from "react-router-dom";
import {PATHS} from "../paths";
import {faArrowLeft} from "@fortawesome/free-solid-svg-icons";
import {BlockTitleComponent} from "../components/common/BlockTitleComponent";
import {ReadOnlyDatePickerComponent} from "../components/common/ReadOnlyDatePickerComponent";
import moment, {Moment} from "moment";
import {ViewGrindingReportComponent} from "../components/grinding/ViewGrindingReportComponent";
import {ViewExtruderReportComponent} from "../components/extruder/ViewExtruderReportComponent";
import {ViewHeaterReportComponent} from "../components/heater/ViewHeaterReportComponent";
import {ViewRefuelingReportComponent} from "../components/refueling/ViewRefuelingReportComponent";
import {ViewRepairsReportComponent} from "../components/repairs/ViewRepairsReportComponent";
import {ViewUnloadingReportComponent} from "../components/unloading/ViewUnloadingReportComponent";
import {ViewOvenReportComponent} from "../components/oven/ViewOvenReportComponent";
import {ViewOrderingReportComponent} from "../components/ordering/ViewOrderingReportComponent";


export interface DailyProductionReportsPageProps {
}

interface DailyProductionReportsPageState {
    loading: number,
    formValues: Map<string, any>,
    shifts: PropertyValue[],
    brick_types: PropertyValue[],
    clay_reasons: PropertyValue[],
    pelet_types: PropertyValue[],
    bag_types: PropertyValue[],
    fadromi: PropertyValue[],
    motocari: PropertyValue[],


    loadGrinding: boolean,
    reports_grinding: Report[],

    loadExtruder: boolean,
    reports_extruder: ExtruderReport[],

    loadHeater: boolean,
    reports_heater: HeaterReport[],

    loadOrdering: boolean,
    reports_ordering: OrderingReport[],

    loadOven: boolean,
    reports_oven: OvenReport[],

    loadUnloading: boolean,
    reports_unloading: Report[],

    loadRepairs: boolean,
    reports_repairs: RepairsReport[],

    loadRefueling: boolean,
    reports_refueling: Report[],
}

export class DailyProductionReportsPage extends React.Component<any, DailyProductionReportsPageState> {

    constructor(props: any) {
        super(props);
        this.state = {
            loading: 0,
            formValues: new Map<string, any>(),
            shifts: [],
            brick_types: [],
            clay_reasons: [],
            bag_types: [],
            pelet_types: [],
            fadromi: [],
            motocari: [],

            loadGrinding: false,
            reports_grinding: [],
            loadExtruder: false,
            reports_extruder: [],
            loadHeater: false,
            reports_heater: [],
            loadOrdering: false,
            reports_ordering: [],
            loadOven: false,
            reports_oven: [],
            loadUnloading: false,
            reports_unloading: [],
            loadRepairs: false,
            reports_repairs: [],
            loadRefueling: false,
            reports_refueling: [],
        }

        this.loadData = this.loadData.bind(this);
        this.handleFormChange = this.handleFormChange.bind(this);
        this.selectDate = this.selectDate.bind(this);
        this.filterReports = this.filterReports.bind(this);
    }

    async componentDidMount() {
        this.selectDate('report_date', new Date());
        this.loadParameters();
    }

    handleFormChange(event: any) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        const fv = this.state.formValues;
        fv.set(name, value);
        this.setState({formValues: fv});
    }

    selectDate(dateFieldName: string, newDate: Date) {
        this.setState({formValues: this.state.formValues.set(dateFieldName, moment(newDate).format('DD.MM.YYYY'))});
    }

    loadParameters() {
        this.setState({loading: this.state.loading + 7});
        API.getParameterValues(
            (apiCall: API_CALL) => {
                if (isSuccess(apiCall)) {
                    this.setState({
                        loading: this.state.loading - 1,
                        shifts: apiCall.data.data.items?.values
                    });

                    this.loadData();
                }
            }, PARAMETERS.SHIFTS
        );

        API.getParameterValues(
            (apiCall: API_CALL) => {
                if (isSuccess(apiCall)) {
                    this.setState({
                        loading: this.state.loading - 1,
                        brick_types: apiCall.data.data.items?.values
                    });
                }
            }, PARAMETERS.BRICKS
        );

        API.getParameterValues(
            (apiCall: API_CALL) => {
                if (isSuccess(apiCall)) {
                    this.setState({
                        loading: this.state.loading - 1,
                        clay_reasons: apiCall.data.data.items?.values
                    });
                }
            }, PARAMETERS.CLAY_REASONS
        )

        API.getParameterValues(
            (apiCall: API_CALL) => {
                if (isSuccess(apiCall)) {
                    this.setState({
                        loading: this.state.loading - 1,
                        pelet_types: apiCall.data.data.items?.values
                    });
                }
            }, PARAMETERS.PELET_TYPE
        );

        API.getParameterValues(
            (apiCall: API_CALL) => {
                if (isSuccess(apiCall)) {
                    this.setState({
                        loading: this.state.loading - 1,
                        bag_types: apiCall.data.data.items?.values.filter(
                            (i: PropertyValue) => {
                                return i.description.usedInProduction;
                            }
                        )
                    });
                }
            }, PARAMETERS.BAG_TYPE
        );

        API.getParameterValues(
            (apiCall: API_CALL) => {
                if (isSuccess(apiCall)) {
                    this.setState({
                        loading: this.state.loading - 1,
                        fadromi: apiCall.data.data.items?.values
                    });
                }
            }, PARAMETERS.FADROMI
        );

        API.getParameterValues(
            (apiCall: API_CALL) => {
                if (isSuccess(apiCall)) {
                    this.setState({
                        loading: this.state.loading - 1,
                        motocari: apiCall.data.data.items?.values
                    });
                }
            }, PARAMETERS.MOTOCARI
        )
    }

    loadData() {
        this.loadGrinding();
        this.loadExtruder();
        this.loadHeater();
        this.loadOrdering();
        this.loadOven();
        this.loadUnloading();
        this.loadRepairs();
        this.loadRefueling();
    }

    getReportDate() {
        return moment(this.state.formValues.get('report_date'), 'DD.MM.YYYY')
    }

    filterReports(reports: Report[]) {
        if (this.state.formValues.get('shiftId') > -1) reports = reports.filter(
            (r: Report) => r.base_info.shift_id == this.state.formValues.get('shiftId')
        );
        return reports;
    }

    loadGrinding() {
        const fv = this.state.formValues;
        fv.delete('grinding_0');
        fv.delete('grinding');
        fv.delete('grinding_1');

        this.setState({formValues: fv});

        API.getGrinding(
            (apiCall: API_CALL) => {
                this.setState({loadGrinding: isLoading(apiCall)});

                if (isSuccess(apiCall)) {
                    let reports = apiCall.data.data.reports.map(
                        (r: any) => {
                            if(r.comments) {
                                this.setState({formValues: this.state.formValues.set('grinding', true)});
                            }
                            this.setState({formValues: this.state.formValues.set('grinding_' + r.shift_id, true)});
                            return {
                                ...r,
                                base_info: {
                                    report_date: r.report_date,
                                    shift_id: r.shift_id,
                                    from_time: r.from_time,
                                    to_time: r.to_time,
                                    man_cnt: r.man_cnt,
                                }
                            } as Report;
                        }
                    );

                    this.setState({reports_grinding: this.filterReports(reports)});
                }
            }, this.getReportDate()
        )
    }

    loadExtruder() {
        const fv = this.state.formValues;
        fv.delete('extruder');
        fv.delete('extruder_0');
        fv.delete('extruder_1');
        this.setState({formValues: fv});

        API.getExtruder(
            (apiCall: API_CALL) => {
                this.setState({loadExtruder: isLoading(apiCall)});

                if (isSuccess(apiCall)) {
                    const reports = apiCall.data.data.reports.map(
                        (r: any) => {
                            const rd = JSON.parse(r.request_data);
                            if(rd.comments) this.setState({formValues: this.state.formValues.set('extruder', true)});
                            this.setState({formValues: this.state.formValues.set('extruder_' + r.shift_id, true)});
                            return {
                                ...r,
                                base_info: {
                                    report_date: r.report_date,
                                    shift_id: r.shift_id,
                                    from_time: r.from_time,
                                    to_time: r.to_time,
                                    man_cnt: r.man_cnt,
                                }
                            } as ExtruderReport;
                        }
                    );
                    this.setState({reports_extruder: this.filterReports(reports) as ExtruderReport[]})
                }

            }, this.getReportDate()
        )

    }

    loadHeater() {
        const fv = this.state.formValues;
        fv.delete('heater');
        fv.delete('heater_0');
        fv.delete('heater_1');
        this.setState({formValues: fv});

        API.getHeater(
            (apiCall: API_CALL) => {
                this.setState({loadHeater: isLoading(apiCall)});

                if (isSuccess(apiCall)) {
                    const reports = apiCall.data.data.reports.map(
                        (r: any) => {
                            const rd = JSON.parse(r.request_data);
                            if(rd.comments) this.setState({formValues: this.state.formValues.set('heater', true)});
                            this.setState({formValues: this.state.formValues.set('heater_' + r.shift_id, true)});
                            return {
                                ...r,
                                base_info: {
                                    report_date: r.report_date,
                                    shift_id: r.shift_id,
                                    from_time: r.from_time,
                                    to_time: r.to_time,
                                    man_cnt: r.man_cnt,
                                } as BaseReportInfo
                            } as HeaterReport;
                        }
                    );
                    this.setState({reports_heater: this.filterReports(reports) as HeaterReport[]})
                }

            }, this.getReportDate()
        )
    }

    loadOrdering() {
        const fv = this.state.formValues;
        fv.delete('ordering');
        fv.delete('ordering_0');
        fv.delete('ordering_1');
        this.setState({formValues: fv});

        API.getOrdering(
            (apiCall: API_CALL) => {
                this.setState({loadOrdering: isLoading(apiCall)});

                if (isSuccess(apiCall)) {
                    const reports = apiCall.data.data.reports.map(
                        (r: any) => {
                            const rd = JSON.parse(r.request_data);
                            if(rd.comments) this.setState({formValues: this.state.formValues.set('ordering', true)});
                            this.setState({formValues: this.state.formValues.set('ordering_' + r.shift_id, true)});
                            return {
                                ...r,
                                base_info: {
                                    report_date: r.report_date,
                                    shift_id: r.shift_id,
                                    from_time: r.from_time,
                                    to_time: r.to_time,
                                    man_cnt: r.man_cnt,
                                }
                            } as OrderingReport;
                        }
                    );
                    this.setState({reports_ordering: this.filterReports(reports) as OrderingReport[]})
                }
            }, this.getReportDate()
        )
    }

    loadOven() {
        const fv = this.state.formValues;
        fv.delete('oven');
        fv.delete('oven_0');
        fv.delete('oven_1');
        this.setState({formValues: fv});

        API.getOven(
            (apiCall: API_CALL) => {
                this.setState({loadOven: isLoading(apiCall)});

                if (isSuccess(apiCall)) {
                    const reports = apiCall.data.data.reports.map(
                        (r: any) => {
                            const rd = JSON.parse(r.request_data);
                            if(rd.comments) this.setState({formValues: this.state.formValues.set('oven', true)});
                            this.setState({formValues: this.state.formValues.set('oven_' + r.shift_id, true)});
                            return {
                                ...r,
                                base_info: {
                                    report_date: r.report_date,
                                    shift_id: r.shift_id,
                                    from_time: r.from_time,
                                    to_time: r.to_time,
                                    man_cnt: r.man_cnt,
                                }
                            } as OvenReport;
                        }
                    );
                    this.setState({reports_oven: this.filterReports(reports) as OvenReport[]})
                }
            }, this.getReportDate()
        )
    }

    loadUnloading() {
        const fv = this.state.formValues;
        fv.delete('unloading');
        fv.delete('unloading_0');
        fv.delete('unloading_1');
        fv.delete('unloading_99');
        this.setState({formValues: fv});

        API.getUnloading(
            (apiCall: API_CALL) => {
                this.setState({loadUnloading: isLoading(apiCall)});

                if (isSuccess(apiCall)) {
                    const reports = apiCall.data.data.reports.map(
                        (r: any) => {
                            const rd = JSON.parse(r.request_data);
                            if(rd.comments) this.setState({formValues: this.state.formValues.set('unloading', true)});
                            this.setState({formValues: this.state.formValues.set('unloading_' + r.shift_id, true)});
                            return {
                                ...r,
                                base_info: {
                                    report_date: r.report_date,
                                    shift_id: r.shift_id,
                                    from_time: r.from_time,
                                    to_time: r.to_time,
                                    man_cnt: r.man_cnt,
                                }
                            } as Report;
                        }
                    );
                    this.setState({reports_unloading: this.filterReports(reports)})
                }
            }, this.getReportDate()
        )
    }

    loadRepairs() {
        const fv = this.state.formValues;
        fv.delete('repairs');
        fv.delete('repairs_0');
        fv.delete('repairs_1');
        this.setState({formValues: fv});

        API.getRepairs(
            (apiCall: API_CALL) => {
                this.setState({loadRepairs: isLoading(apiCall)});

                if (isSuccess(apiCall)) {
                    const reports = apiCall.data.data.reports.map(
                        (r: any) => {
                            const rd = JSON.parse(r.request_data);
                            if(rd.comments) this.setState({formValues: this.state.formValues.set('repairs', true)});
                            this.setState({formValues: this.state.formValues.set('repairs_' + r.shift_id, true)});
                            return {
                                ...r,
                                base_info: {
                                    report_date: r.report_date,
                                    shift_id: r.shift_id,
                                    from_time: r.from_time,
                                    to_time: r.to_time,
                                    man_cnt: r.man_cnt,
                                }
                            } as Report;
                        }
                    );
                    this.setState({reports_repairs: this.filterReports(reports) as RepairsReport[]})
                }

            }, this.getReportDate()
        )
    }

    loadRefueling() {
        const fv = this.state.formValues;
        fv.delete('refueling');
        fv.delete('refueling_0');
        fv.delete('refueling_1');
        this.setState({formValues: fv});

        API.getRefueling(
            (apiCall: API_CALL) => {
                this.setState({loadRefueling: isLoading(apiCall)});

                if (isSuccess(apiCall)) {
                    const reports = apiCall.data.data.reports.map(
                        (r: any) => {
                            const rd = JSON.parse(r.request_data);
                            if(rd.comments) this.setState({formValues: this.state.formValues.set('refueling', true)});
                            this.setState({formValues: this.state.formValues.set('refueling_' + r.shift_id, true)});
                            return {
                                ...r,
                                base_info: {
                                    report_date: r.report_date,
                                    shift_id: r.shift_id,
                                    from_time: r.from_time,
                                    to_time: r.to_time,
                                    man_cnt: r.man_cnt,
                                }
                            } as Report;
                        }
                    );
                    this.setState({reports_refueling: this.filterReports(reports)})
                }
            }, this.getReportDate()
        )
    }


    render() {
        return (
            <div className="container" style={{maxWidth: '100%'}}>

                <div className="card shadow">

                    <div className="card-header text-center row">
                        <div className="col-2 text-left">
                            {
                                (this.state.loading > 0) ?
                                    <div className="spinner-border" role="status">
                                        <span className="sr-only">Loading...</span>
                                    </div>
                                    :
                                    <Link to={PATHS.home + API.getAPITokenAsQuery()}><span className="btn btn-secondary"><FontAwesomeIcon icon={faArrowLeft}/></span></Link>
                            }

                        </div>
                        <h2 className="col">Обобщен отчет производство</h2>
                        <div className="col-2">
                        </div>
                    </div>

                    <div className="card-body">

                        <div className="row">
                            <div className="col">
                                {
                                    this.state.loading > 0 &&
                                    <div className={"m-3"}>Зареждам данните ...</div>
                                }
                            </div>
                        </div>

                        <BlockTitleComponent title={"Параметри на справката"}/>
                        <div className={"row mb-5"}>

                            <div className={"col-auto mr-3 pl-4 ml-0"}>
                                <form className="form-inline">
                                    <div className={"text-nowrap row lh-2em "}>
                                        <div className={"col-12 w-120px mb-2"}>Отчети за дата:</div>
                                        <div className={"col-auto w-160px"}>
                                            <ReadOnlyDatePickerComponent
                                                classes={"ml-1 pl-0"}
                                                onChange={(newDate: Date) => this.selectDate('report_date', newDate)}
                                                value={this.state.formValues.get('report_date')}
                                            />
                                        </div>
                                    </div>
                                </form>
                            </div>
                            {
                                !this.state.loading &&
                                <div className={"col-auto"} style={{paddingTop: 7}}>
                                    <label className={"justify-content-start"}>Работна смяна:</label>
                                    <select className="form-control w-100" name="shiftId" required
                                            value={this.state.formValues?.get('shiftId')}
                                            onChange={this.handleFormChange}>
                                        <option value="-1">Всички смени</option>
                                        {
                                            this.state.shifts.map(
                                                (i: PropertyValue, idx: number) =>
                                                    <option value={idx} key={idx}>{i.value}</option>
                                            )
                                        }
                                        <option value="99">МЕЖДИННА СМЯНА</option>
                                    </select>
                                </div>
                            }
                            <div className={"col-auto readonly-date-picker"}>
                                {
                                    this.state.loading ?
                                        <>
                                            <label className={"col-form-label w-100"}>&nbsp; </label>
                                            <div className="spinner-border" role="status">
                                                <span className="sr-only">Loading...</span>
                                            </div>
                                        </>
                                        :
                                        <>
                                            <label className={"col-form-label w-100"}>&nbsp; </label>
                                            <button className="btn btn-primary" onClick={this.loadData}>Покажи</button>
                                        </>
                                }
                            </div>
                        </div>


                        <div id="grinding_anchor"></div>
                        <BlockTitleComponent title={"Отчети за МЕЛЕНЕ"} />
                        <div className={"row mb-6"}>
                            <div className={"col"}>
                                {
                                    this.state.loadGrinding ?
                                        <div className="spinner-border" role="status">
                                            <span className="sr-only">Loading...</span>
                                        </div>
                                        :
                                        this.state.reports_grinding.length === 0 ?
                                            <>
                                                Няма отчети за тази дата
                                            </>
                                            :
                                            this.state.reports_grinding.map(
                                                item =>
                                                    <ViewGrindingReportComponent
                                                        report={item}
                                                        shifts={this.state.shifts}
                                                    />
                                            )
                                }
                            </div>
                        </div>

                        <div style={{height: 100}}/>

                        <div id="extruder_anchor"></div>
                        <BlockTitleComponent title={"Отчети за ЕКСТРУДЕР"} />
                        <div className={"row mb-6"}>
                            <div className={"col"}>
                                {
                                    this.state.loadExtruder ?
                                        <div className="spinner-border" role="status">
                                            <span className="sr-only">Loading...</span>
                                        </div>
                                        :
                                        this.state.reports_extruder.length === 0 ?
                                            <>
                                                Няма отчети за тази дата
                                            </>
                                            :
                                            this.state.reports_extruder.map(
                                                item =>
                                                    <ViewExtruderReportComponent
                                                        report={item}
                                                        shifts={this.state.shifts}
                                                        bricks_types={this.state.brick_types}
                                                        clay_reasons={this.state.clay_reasons}
                                                    />
                                            )
                                }
                            </div>
                        </div>

                        <div style={{height: 100}}/>

                        <div id="heater_anchor"></div>
                        <BlockTitleComponent title={"Отчети за ПЕЧКА"}/>
                        <div className={"row mb-6"}>
                            <div className={"col"}>
                                {
                                    this.state.loadHeater ?
                                        <div className="spinner-border" role="status">
                                            <span className="sr-only">Loading...</span>
                                        </div>
                                        :
                                        this.state.reports_heater.length === 0 ?
                                            <>
                                                Няма отчети за тази дата
                                            </>
                                            :
                                            this.state.reports_heater.map(
                                                item =>
                                                    <ViewHeaterReportComponent
                                                        report={item}
                                                        shifts={this.state.shifts}
                                                        bag_types={this.state.bag_types}
                                                        pelet_types={this.state.pelet_types}
                                                    />
                                            )
                                }
                            </div>
                        </div>

                        <div style={{height: 100}}/>

                        <div id="ordering_anchor"></div>
                        <BlockTitleComponent title={"Отчети за РЕДЕНЕ"}/>
                        <div className={"row mb-6"}>
                            <div className={"col"}>
                                {
                                    this.state.loadOrdering ?
                                        <div className="spinner-border" role="status">
                                            <span className="sr-only">Loading...</span>
                                        </div>
                                        :
                                        this.state.reports_ordering.length === 0 ?
                                            <>
                                                Няма отчети за тази дата
                                            </>
                                            :
                                            this.state.reports_ordering.map(
                                                item =>
                                                    <ViewOrderingReportComponent
                                                        report={item}
                                                        shifts={this.state.shifts}
                                                        bricks_types={this.state.brick_types}
                                                    />
                                            )
                                }
                            </div>
                        </div>

                        <div style={{height: 100}}/>

                        <div id="oven_anchor"></div>
                        <BlockTitleComponent title={"Отчети за ПЕЩ"}/>
                        <div className={"row mb-6"}>
                            <div className={"col"}>
                                {
                                    this.state.loadOven ?
                                        <div className="spinner-border" role="status">
                                            <span className="sr-only">Loading...</span>
                                        </div>
                                        :
                                        this.state.reports_oven.length === 0 ?
                                            <>
                                                Няма отчети за тази дата
                                            </>
                                            :
                                            this.state.reports_oven.map(
                                                item =>
                                                    <ViewOvenReportComponent
                                                        report={item}
                                                        shifts={this.state.shifts}
                                                        bag_types={this.state.bag_types}
                                                        pelet_types={this.state.pelet_types}
                                                    />
                                            )
                                }
                            </div>
                        </div>

                        <div style={{height: 100}}/>

                        <div id="unloading_anchor"></div>
                        <BlockTitleComponent title={"Отчети за РАЗТОВАРВАНЕ"}/>
                        <div className={"row mb-6"}>
                            <div className={"col"}>
                                {
                                    this.state.loadUnloading ?
                                        <div className="spinner-border" role="status">
                                            <span className="sr-only">Loading...</span>
                                        </div>
                                        :
                                        this.state.reports_unloading.length === 0 ?
                                            <>
                                                Няма отчети за тази дата
                                            </>
                                            :
                                            this.state.reports_unloading.map(
                                                item =>
                                                    <ViewUnloadingReportComponent
                                                        report={item}
                                                        shifts={this.state.shifts}
                                                        bricks_types={this.state.brick_types}
                                                    />
                                            )
                                }
                            </div>
                        </div>

                        <div style={{height: 100}}/>

                        <div id="repairs_anchor"></div>
                        <BlockTitleComponent title={"Отчети за РЕМОНТИ"}/>
                        <div className={"row mb-6"}>
                            <div className={"col"}>
                                {
                                    this.state.loadRepairs ?
                                        <div className="spinner-border" role="status">
                                            <span className="sr-only">Loading...</span>
                                        </div>
                                        :
                                        this.state.reports_repairs.length === 0 ?
                                            <>
                                                Няма отчети за тази дата
                                            </>
                                            :
                                            this.state.reports_repairs.map(
                                                item =>
                                                    <ViewRepairsReportComponent
                                                        report={item}
                                                        shifts={this.state.shifts}
                                                    />
                                            )
                                }
                            </div>
                        </div>

                        <div style={{height: 100}}/>

                        <div id="refueling_anchor"></div>
                        <BlockTitleComponent title={"Отчети за РАЗХОД ГОРИВО"}/>
                        <div className={"row mb-6"}>
                            <div className={"col"}>
                                {
                                    this.state.loadRefueling ?
                                        <div className="spinner-border" role="status">
                                            <span className="sr-only">Loading...</span>
                                        </div>
                                        :
                                        this.state.reports_refueling.length === 0 ?
                                            <>
                                                Няма отчети за тази дата
                                            </>
                                            :
                                            this.state.reports_refueling.map(
                                                item =>
                                                    <ViewRefuelingReportComponent
                                                        report={item}
                                                        shifts={this.state.shifts}
                                                        fadromi={this.state.fadromi}
                                                        motocari={this.state.motocari}
                                                    />
                                            )
                                }
                            </div>
                        </div>

                    </div>

                </div>

                <div className={"production-report-legenda"}>
                    <table className={"table table-sm table-bordered"}>
                        <thead>
                        <tr>
                            <th>Отдел</th>
                            <th>ПС</th>
                            <th>МС</th>
                            <th>НС</th>
                            <th>БЕ</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td><a href={"#grinding_anchor"}>Мелене</a></td>
                            <td className={this.state.formValues.has('grinding_0') ? "green" : "red"}>&nbsp;</td>
                            <td className={"silver"}>&nbsp;</td>
                            <td className={this.state.formValues.has('grinding_1') ? "green" : "red"}>&nbsp;</td>
                            <td className={this.state.formValues.has('grinding') ? "green" : "silver"}>&nbsp;</td>
                        </tr>
                        <tr>
                            <td><a href={"#extruder_anchor"}>Екструдер</a></td>
                            <td className={this.state.formValues.has('extruder_0') ? "green" : "red"}>&nbsp;</td>
                            <td className={"silver"}>&nbsp;</td>
                            <td className={this.state.formValues.has('extruder_1') ? "green" : "red"}>&nbsp;</td>
                            <td className={this.state.formValues.has('extruder') ? "green" : "silver"}>&nbsp;</td>
                        </tr>
                        <tr>
                            <td><a href={"#heater_anchor"}>Печка</a></td>
                            <td className={this.state.formValues.has('heater_0') ? "green" : "red"}>&nbsp;</td>
                            <td className={"silver"}>&nbsp;</td>
                            <td className={this.state.formValues.has('heater_1') ? "green" : "red"}>&nbsp;</td>
                            <td className={this.state.formValues.has('heater') ? "green" : "silver"}>&nbsp;</td>
                        </tr>
                        <tr>
                            <td><a href={"#ordering_anchor"}>Редене</a></td>
                            <td className={this.state.formValues.has('ordering_0') ? "green" : "red"}>&nbsp;</td>
                            <td className={"silver"}>&nbsp;</td>
                            <td className={this.state.formValues.has('ordering_1') ? "green" : "red"}>&nbsp;</td>
                            <td className={this.state.formValues.has('ordering') ? "green" : "silver"}>&nbsp;</td>
                        </tr>
                        <tr>
                            <td><a href={"#oven_anchor"}>Пещ</a></td>
                            <td className={this.state.formValues.has('oven_0') ? "green" : "red"}>&nbsp;</td>
                            <td className={"silver"}>&nbsp;</td>
                            <td className={this.state.formValues.has('oven_1') ? "green" : "red"}>&nbsp;</td>
                            <td className={this.state.formValues.has('oven') ? "green" : "silver"}>&nbsp;</td>
                        </tr>
                        <tr>
                            <td><a href={"#unloading_anchor"}>Разтоварване</a></td>
                            <td className={this.state.formValues.has('unloading_0') ? "green" : "red"}>&nbsp;</td>
                            <td className={this.state.formValues.has('unloading_99') ? "green" : "red"}>&nbsp;</td>
                            <td className={this.state.formValues.has('unloading_1') ? "green" : "red"}>&nbsp;</td>
                            <td className={this.state.formValues.has('unloading') ? "green" : "silver"}>&nbsp;</td>
                        </tr>
                        <tr>
                            <td><a href={"#repairs_anchor"}>Ремонти</a></td>
                            <td className={this.state.formValues.has('repairs_0') ? "green" : "red"}>&nbsp;</td>
                            <td className={"silver"}>&nbsp;</td>
                            <td className={this.state.formValues.has('repairs_1') ? "green" : "red"}>&nbsp;</td>
                            <td className={this.state.formValues.has('repairs') ? "green" : "silver"}>&nbsp;</td>
                        </tr>
                        <tr>
                            <td><a href={"#refueling_anchor"}>Разход г-во</a></td>
                            <td className={this.state.formValues.has('refueling_0') ? "green" : "red"}>&nbsp;</td>
                            <td className={"silver"}>&nbsp;</td>
                            <td className={"silver"}>&nbsp;</td>
                            <td className={this.state.formValues.has('refueling') ? "green" : "silver"}>&nbsp;</td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        );
    }

}
