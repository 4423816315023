import React from "react";
import {$enum} from "ts-enum-util";
import {API_CALL, PropertyValue, REPAIR_TYPE} from "../../types";
import {BlockTitleComponent} from "../common/BlockTitleComponent";
import {ReportsPageProps} from "../../pages/ReportsPage";
import {API} from "../../api";
import {isLoading, isSuccess, minutesToHours, numberWithCommas, zeroToEmptyString, zeroToEmptyStringAsCurrency} from "../../common";
import moment, {Moment} from "moment";


interface PeletsSludgeSaldoReportPageState {
    loading: boolean,
    periodFrom: string,
    periodTo: string,
    data: any,
}

export class PeletsSludgeSaldoReportPage extends React.Component<ReportsPageProps, PeletsSludgeSaldoReportPageState> {

    constructor(props: ReportsPageProps) {
        super(props);
        this.state = {
            periodFrom: props.periodFrom,
            periodTo: props.periodTo,
            loading: false,
            data: null,
        }

        this.loadData = this.loadData.bind(this);
        this.processData = this.processData.bind(this);
    }

    async componentDidMount() {
        this.loadData();
    }

    loadData() {
        API.calcSludgePeletsSaldoReport(
            (apiCall: API_CALL) => {
                this.setState({loading: isLoading(apiCall)});

                if (isSuccess(apiCall)) {
                    console.log(apiCall.data.data);
                    this.processData(apiCall.data.data.report);
                }
            }, {
                periodFrom: this.props.periodFrom,
                periodTo: this.props.periodTo,
            }
        )
    }

    processData(data: any) {
        this.setState({data: data});
    }

    componentDidUpdate(prevProps: Readonly<ReportsPageProps>, prevState: Readonly<PeletsSludgeSaldoReportPageState>, snapshot?: any) {
        if (
            (this.state.periodFrom !== this.props.periodFrom) ||
            (this.state.periodTo !== this.props.periodTo)
        ) {
            this.setState({
                periodFrom: this.props.periodFrom,
                periodTo: this.props.periodTo
            });
            this.loadData();
        }
    }


    renderReport(data: any) {
        return '';
        // let ftotalPalets = 0;
        // let ftotalBricks = 0;
        // let ftotalLogistics = 0;
        // let ftotalPrice = 0;
        // let totalPalets = 0;
        // let totalBricks = 0;
        // let totalLogistics = 0;
        // let totalPrice = 0;
        //
        // console.log(data);
        //
        // return (
        //     <>
        //         <BlockTitleComponent title={data.brick}/>
        //         {
        //             data.sales.length == 0 ?
        //                 <div><strong>Няма продажби за този период</strong></div>
        //                 :
        //                 <table className={"table table-sm"}>
        //                     <thead>
        //                     <tr>
        //                         <th>Дата</th>
        //                         <th>Фирма</th>
        //                         <th>Тип</th>
        //                         <th>Вид</th>
        //                         <th className={"text-right"}>Палети (бр)</th>
        //                         <th className={"text-right"}>Тухли (бр)</th>
        //                         <th className={"text-right"}>Транспорт</th>
        //                         <th className={"text-right"}>Сума</th>
        //                         <th className={"text-right"}>СЦТ</th>
        //                     </tr>
        //                     </thead>
        //                     <tbody>
        //                     {
        //                         data.sales.map((sale: any) => {
        //
        //                                 sale.bricksQty = sale.bricksQty * 1;
        //                                 sale.paletsQty = sale.paletsQty * 1;
        //                                 sale.logistics = sale.logistics * 1;
        //                                 sale.price = sale.price * 1;
        //
        //                                 totalPalets += sale.paletsQty;
        //                                 totalBricks += sale.bricksQty;
        //                                 totalLogistics += sale.logistics;
        //                                 totalPrice += sale.price;
        //
        //                                 if (sale.saleType === "bricks_w_env") {
        //                                     ftotalPalets += sale.paletsQty;
        //                                     ftotalBricks += sale.bricksQty;
        //                                     ftotalLogistics += sale.logistics;
        //                                     ftotalPrice += sale.price;
        //                                 }
        //
        //                                 return <tr className={sale.isPayed ? "" : "bg-light text-danger"}>
        //                                     <td>{sale.date}</td>
        //                                     <td>{sale.client}</td>
        //                                     <td>{sale.payType === "BANKA" ? 'Банка' : 'Кеш'}</td>
        //                                     <td>{sale.saleType === "bricks_w_env" ? 'Ф' : 'КБ'}</td>
        //                                     <td className={"text-right"}>{sale.paletsQty}</td>
        //                                     <td className={"text-right"}>{sale.bricksQty}</td>
        //                                     <td className={"text-right"}>{zeroToEmptyStringAsCurrency(sale.logistics)}</td>
        //                                     <td className={"text-right"}>{zeroToEmptyStringAsCurrency(sale.price)}</td>
        //                                     <td className={"text-right"}>{zeroToEmptyStringAsCurrency((sale.price / sale.bricksQty).toString())}</td>
        //                                 </tr>
        //                             }
        //                         )
        //                     }
        //
        //                     <tr className={"bg-secondary text-light font-weight-bold"}>
        //                         <td colSpan={5} className={"text-right"}>Всичко с фактура</td>
        //                         <td className={"text-right"}>{ftotalPalets}</td>
        //                         <td className={"text-right"}>{ftotalBricks}</td>
        //                         <td className={"text-right"}>{zeroToEmptyStringAsCurrency(ftotalLogistics.toString())}</td>
        //                         <td className={"text-right"}>{zeroToEmptyStringAsCurrency(ftotalPrice.toString())}</td>
        //                     </tr>
        //                     <tr className={"bg-secondary text-light font-weight-bold"}>
        //                         <td colSpan={5} className={"text-right"}>Всичко с касов бон</td>
        //                         <td className={"text-right"}>{totalPalets - ftotalPalets}</td>
        //                         <td className={"text-right"}>{totalBricks - ftotalBricks}</td>
        //                         <td className={"text-right"}>{zeroToEmptyStringAsCurrency((totalLogistics - ftotalLogistics).toString())}</td>
        //                         <td className={"text-right"}>{zeroToEmptyStringAsCurrency((totalPrice - ftotalPrice).toString())}</td>
        //                     </tr>
        //                     <tr className={"bg-secondary text-light font-weight-bold"}>
        //                         <td colSpan={5} className={"text-right"}>Всичко</td>
        //                         <td className={"text-right"}>{totalPalets}</td>
        //                         <td className={"text-right"}>{totalBricks}</td>
        //                         <td className={"text-right"}>{zeroToEmptyStringAsCurrency(totalLogistics.toString())}</td>
        //                         <td className={"text-right"}>{zeroToEmptyStringAsCurrency(totalPrice.toString())}</td>
        //                     </tr>
        //                     <tr className={"bg-secondary text-light font-weight-bold"}>
        //                         <td colSpan={8} className={"text-right"}>Средна цена на тухла за периода</td>
        //                         <td className={"text-right"}>{zeroToEmptyStringAsCurrency((totalPrice / totalBricks).toString())}</td>
        //                     </tr>
        //                     </tbody>
        //                 </table>
        //         }
        //         <div>&nbsp;</div>
        //     </>
        // )
    }

    renderReports() {
        let total6i = 0;
        let total6w = 0;
        let total6c = 0;
        let total8i = 0;
        let total8w = 0;
        let total8c = 0;
        let totalSludgei = 0;
        let totalSludgew = 0;

        return <table className={"table table-sm align-top text-center table-bordered"}>
            <thead>
            <tr>
                <th rowSpan={3}>Дата</th>
                <th colSpan={6}>Пелети</th>
                <th rowSpan={2} colSpan={2}>Шлам</th>
            </tr>
            <tr>
                <th colSpan={3}>6 mm</th>
                <th colSpan={3}>8 mm</th>
            </tr>
            <tr>
                <th>Приход</th>
                <th>Общ разход</th>
                <th>Разход произв.</th>
                <th>Приход</th>
                <th>Общ разход</th>
                <th>Разход произв.</th>
                <th>Приход</th>
                <th>Разход</th>
            </tr>
            </thead>
            <tbody>
            {
                this.state.data.map(
                    (item: any) => {
                        total6i += item.pelets_6_income * 1;
                        total6w += item.pelets_6_withdraw * 1;
                        total6c += item.pelets_6_consumption * 1;
                        total8i += item.pelets_8_income * 1;
                        total8w += item.pelets_8_withdraw * 1;
                        total8c += item.pelets_8_consumption * 1;
                        totalSludgei += item.sludge_income * 1;
                        totalSludgew += item.sludge_withdraw * 1;

                        return <tr>
                            <td>{item.date}</td>
                            <td>{zeroToEmptyString(numberWithCommas(item.pelets_6_income), ' кг')}</td>
                            <td>{zeroToEmptyString(numberWithCommas(item.pelets_6_withdraw), ' кг')}</td>
                            <td>{zeroToEmptyString(numberWithCommas(item.pelets_6_consumption), ' кг')}</td>
                            <td>{zeroToEmptyString(numberWithCommas(item.pelets_8_income), ' кг')}</td>
                            <td>{zeroToEmptyString(numberWithCommas(item.pelets_8_withdraw), ' кг')}</td>
                            <td>{zeroToEmptyString(numberWithCommas(item.pelets_8_consumption), ' кг')}</td>
                            <td>{zeroToEmptyString(numberWithCommas(item.sludge_income), ' кг')}</td>
                            <td>{zeroToEmptyString(numberWithCommas(item.sludge_withdraw), ' кг')}</td>
                        </tr>
                    }
                )
            }
            <tr className={"bg-secondary text-light font-weight-bold"}>
                <td>Всичко</td>
                <td>{zeroToEmptyString(numberWithCommas(total6i), ' кг')}</td>
                <td>{zeroToEmptyString(numberWithCommas(total6w), ' кг')}</td>
                <td>{zeroToEmptyString(numberWithCommas(total6c), ' кг')}</td>
                <td>{zeroToEmptyString(numberWithCommas(total8i), ' кг')}</td>
                <td>{zeroToEmptyString(numberWithCommas(total8w), ' кг')}</td>
                <td>{zeroToEmptyString(numberWithCommas(total8c), ' кг')}</td>
                <td>{zeroToEmptyString(numberWithCommas(totalSludgei), ' кг')}</td>
                <td>{zeroToEmptyString(numberWithCommas(totalSludgew), ' кг')}</td>
            </tr>
            </tbody>
        </table>;
    }

    render() {
        return (
            <>
                <div className="row">
                    <div className="col">
                        {
                            this.state.loading &&
                            <div className={"m-3"}>Зареждам данните ...</div>
                        }
                    </div>
                </div>

                {
                    !this.state.loading && this.state.data && this.renderReports()
                }
            </>
        );
    }
}
