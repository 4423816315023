import {API_CALL, NET_STATUS, PropertyValue} from "./types";
import {toast} from "react-toastify";
import React from "react";

export function isSuccess(apiCall: API_CALL) {
    return (apiCall.status === NET_STATUS.SUCCESS) && (apiCall.data.data?.status === "ok");
}

export function isError(apiCall: API_CALL) {
    return ((apiCall.status === NET_STATUS.ERROR) || (apiCall.data.data?.status === "error"));
}

export function isAuthError(apiCall: API_CALL) {
    return apiCall.error?.response?.status === 401;
}

export function isLoading(apiCall: API_CALL) {
    return apiCall.status === NET_STATUS.LOADING;
}

export function mapToObject(map: any) {
    const obj: any = [];

    map.forEach(
        (k: string, v: any) => {
            obj[v] = k;
        }
    )
    return {...obj};
}

export function showErrorsInToast(apiCall: API_CALL, title: string, translateError?: any) {
    const errors = apiCall.error?.response?.data?.errors || null;
    const msgs: any = [];

    const toastMsg = function (title: string, errors: string[]) {
        return <>
            <div className={"font-weight-bold text-center w-100 border-bottom p-1 m-1"}>{title}</div>
            <ul className={"pt-3"}>
                {errors.map(e => e)}
            </ul>
        </>
    }

    if (apiCall.error?.response?.data?.errors) {
        Object.keys(errors).forEach(
            (k: string, kIdx: number) =>
                errors[k].forEach(
                    (m: string, mIdx: number) => msgs.push(<li key={kIdx * 100 + mIdx}>{translateError ? translateError(m) : m}</li>)
                )
        );
    } else if (apiCall.error) {
        msgs.push(<li key={0}>{apiCall.error.message}</li>);
    } else if (apiCall.data?.data?.message) {
        msgs.push(<li key={0}>{apiCall.data?.data?.message}</li>);
    }

    toast.error(toastMsg(title, msgs), {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: false,
    });

}

export function showErrorsListInToast(title: string, errors: string[]) {
    const msgs: any = [];

    const toastMsg = function (title: string, errors: string[]) {
        return <>
            <div className={"font-weight-bold text-center w-100 border-bottom p-1 m-1"}>{title}</div>
            <ul className={"pt-3"}>
                {errors.map(e => e)}
            </ul>
        </>
    }

    errors.forEach(
        (m: string, mIdx: number) => msgs.push(<li key={mIdx}>{m}</li>)
    );

    toast.error(toastMsg(title, msgs), {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 5000,
    });

}

export function showMsgInToast(msg: string) {
    const toastMsg = function (msg: string) {
        return <>
            <div className={"font-weight-bold text-center w-100 p-1 m-1 text-center"}>{msg}</div>
        </>
    }

    toast.success(toastMsg(msg), {
        position: toast.POSITION.BOTTOM_CENTER
    });

}

export function getShiftName(shifts: PropertyValue[], shift_id: number) {
    if (shift_id * 1 === 99) {
        return 'МЕЖДИННА СМЯНА'
    } else {
        if (shifts && shifts[shift_id]) {
            return shifts[shift_id].value;
        } else {
            return '-'
        }
    }
}

export function getTypeName(types: PropertyValue[], typeId: number) {
    if (types && types[typeId]) {
        return types[typeId].value;
    } else {
        return '-'
    }
}

export const ARRAY22 = Array.of(1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22);

export const DICT = new Map<string, string>(
    [
        ['name', '< Име на служителя >'],
        ['period from', '< Час начало >'],
        ['period to', '< Час край >'],
        ['base info.report date', '< Отчет за дата >'],
        ['base info.shift id', '< Работна смяна >'],
        ['base info.man cnt', '< Брой служители >'],
        ['clay qty', '< Глина (кофи) >'],
        ['sludge qty', '< Шлам (кофи) >'],
        ["returned", "< Върнати палети >"],
        ["saleId", "< ID на експ.бележка >"],
        ["sales_date", "< Дата >"],
        ["client city", "< Град >"],
        ["client", "< Клиент >"],
        ["driver", "< Шофьор >"],
        ["repair type", "< Отдел >"],
        ["The name has already been taken.", "Това име вече съществува."],
        ["The selected month count is invalid.", "Избрания брой месеци е невалиден."],
    ]
);

export function translateError(e: string) {
    // debugger
    let err: string = e;
    // @ts-ignore
    for (const [key, value] of DICT.entries()) {
        err = err.replace(key, value);
        console.log(err);
    }

    return err;
}

export function minutesToHours(m: number, suffix: string = ""): string {
    if (m === 0) return '';

    const _h = Math.floor(m / 60);
    const _m = m % 60;

    return ("00" + _h).substr(-2) + ":" + ("00" + _m).substr(-2) + suffix;
}

export function bagTypeTitle(bagTypeIdx: number) {
    switch (bagTypeIdx * 1) {
        case 0 :
            return 'малък баг';
        case 1 :
            return 'голям баг';
        case 2 :
            return 'палет';
        case 3 :
            return 'чувалче';
    }
}

export function zeroToEmptyString(text: string|number, suff: string = '') {
    return (!text || (text == "0") || (text == 0) || (text == "NaN")) ? "" : text + suff;
}

export function zeroToEmptyStringAsCurrency(text: string, suff: string = '') {
    let newVal: string = zeroToEmptyString(text).toString();
    return (newVal && newVal !== "") ? numberWithCommas(Number.parseFloat(newVal).toFixed(2)) + suff : '';
}

export function numberWithCommas(x: any) {
    return x.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
}

export function nullsToEmptyString(obj: any) {
    Object.keys(obj).forEach(
        $key => {
            if(obj[$key] === "null" || obj[$key] === null) obj[$key] = '';
        }
    );

    return obj;
}
