import React from "react";
import {$enum} from "ts-enum-util";
import {API_CALL, PropertyValue, REPAIR_TYPE} from "../../types";
import {BlockTitleComponent} from "../common/BlockTitleComponent";
import {ReportsPageProps} from "../../pages/ReportsPage";
import {API} from "../../api";
import {isLoading, isSuccess, minutesToHours, numberWithCommas, zeroToEmptyString, zeroToEmptyStringAsCurrency} from "../../common";
import moment, {Moment} from "moment";


interface IncomeOutgoingReportPageState {
    loading: boolean,
    periodFrom: string,
    periodTo: string,
    data: any,
}

export class IncomeOutgoingReportPage extends React.Component<ReportsPageProps, IncomeOutgoingReportPageState> {

    constructor(props: ReportsPageProps) {
        super(props);
        this.state = {
            periodFrom: props.periodFrom,
            periodTo: props.periodTo,
            loading: false,
            data: null,
        }

        this.loadData = this.loadData.bind(this);
        this.processData = this.processData.bind(this);
    }

    async componentDidMount() {
        this.loadData();
    }

    loadData() {
        API.calcIncomeOutgoingReport(
            (apiCall: API_CALL) => {
                this.setState({loading: isLoading(apiCall)});

                if (isSuccess(apiCall)) {
                    console.log(apiCall.data.data);
                    this.processData(apiCall.data.data.report);
                }
            }, {
                periodFrom: this.props.periodFrom,
                periodTo: this.props.periodTo,
            }
        )
    }

    processData(data: any) {
        this.setState({data: data});
    }

    componentDidUpdate(prevProps: Readonly<ReportsPageProps>, prevState: Readonly<IncomeOutgoingReportPageState>, snapshot?: any) {
        if (
            (this.state.periodFrom !== this.props.periodFrom) ||
            (this.state.periodTo !== this.props.periodTo)
        ) {
            this.setState({
                periodFrom: this.props.periodFrom,
                periodTo: this.props.periodTo
            });
            this.loadData();
        }
    }

    renderReports() {
        let totalIB = 0;
        let totalIC = 0;
        let totalIT = 0;
        let totalOP = 0;
        let totalOS = 0;
        let totalOT = 0;

        return <table className={"table table-sm align-top text-center table-bordered"}>
            <thead>
            <tr>
                <th rowSpan={2}>Дата</th>
                <th colSpan={3}>Приход (лв)</th>
                <th colSpan={3}>Разход (лв)</th>
            </tr>
            <tr className={"text-right"}>
                <th>БАНКА</th>
                <th>КЕШ</th>
                <th>ОБЩО</th>
                <th>ПЕЛЕТИ</th>
                <th>ШЛАМ</th>
                <th>ОБЩО</th>
            </tr>
            </thead>
            <tbody>
            {
                Object.keys(this.state.data).map(
                    (key: any) => {
                        const item = this.state.data[key];

                        totalIB += item.income.BANKA * 1;
                        totalIC += item.income.CASH * 1;
                        totalIT += item.income.total * 1;
                        totalOP += item.outgoing.pelets * 1;
                        totalOS += item.outgoing.sludge * 1;
                        totalOT += item.outgoing.total * 1;

                        return <tr className={"text-right"}>
                            <td className={"text-center"}>{key}</td>
                            <td>{zeroToEmptyStringAsCurrency(item.income.BANKA)}</td>
                            <td>{zeroToEmptyStringAsCurrency(item.income.CASH)}</td>
                            <td>{zeroToEmptyStringAsCurrency(item.income.total)}</td>
                            <td>{zeroToEmptyStringAsCurrency(item.outgoing.pelets)}</td>
                            <td>{zeroToEmptyStringAsCurrency(item.outgoing.sludge)}</td>
                            <td>{zeroToEmptyStringAsCurrency(item.outgoing.total)}</td>
                        </tr>
                    }
                )
            }
            <tr className={"bg-secondary text-light font-weight-bold text-right"}>
                <td>Всичко</td>
                <td>{zeroToEmptyStringAsCurrency(totalIB.toFixed(2))}</td>
                <td>{zeroToEmptyStringAsCurrency(totalIC.toFixed(2))}</td>
                <td>{zeroToEmptyStringAsCurrency(totalIT.toFixed(2))}</td>
                <td>{zeroToEmptyStringAsCurrency(totalOP.toFixed(2))}</td>
                <td>{zeroToEmptyStringAsCurrency(totalOS.toFixed(2))}</td>
                <td>{zeroToEmptyStringAsCurrency(totalOT.toFixed(2))}</td>
            </tr>

            <tr className={"bg-secondary text-light font-weight-bold text-right"}>
                <td colSpan={6}>Разлика (Приход - Разход): </td>
                <td>{zeroToEmptyStringAsCurrency((totalIT-totalOT).toFixed(2))}</td>
            </tr>

            </tbody>
        </table>;
    }

    render() {
        return (
            <>
                <div className="row">
                    <div className="col">
                        {
                            this.state.loading &&
                            <div className={"m-3"}>Зареждам данните ...</div>
                        }
                    </div>
                </div>

                {
                    !this.state.loading && this.state.data && this.renderReports()
                }
            </>
        );
    }
}
