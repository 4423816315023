import {Modal, Tab, Tabs} from "react-bootstrap";
import React, {useState} from "react";
import {Client, SALE_TYPE} from "../../types";
import {EditClientDetails} from "./EditClientDetails";
import {EditClientAccountDetails} from "./EditClientAccountDetails";
import {EditClientPaletDetails} from "./EditClientPaletDetails";

export interface EditClientAccountModalProps {
    client: Client;
    onReload: () => any;
    onClose: any;
}


export function EditClientAccountModal(props: EditClientAccountModalProps) {

    const [viewMode, setViewMode] = useState("cash_balance");

    return (
        <Modal
            show={true}
            centered
            onHide={props.onClose}
            size={"xl"}
        >
            <Modal.Header closeButton>
                <div className={"row w-100"}>
                    <div className="col">
                        <Modal.Title>Баланс на клиент :: {props.client.name}</Modal.Title>
                    </div>
                    <div className="col-auto">
                        <Tabs
                            variant={"pills"}
                            activeKey={viewMode}
                            onSelect={(k: any) => setViewMode(k)}
                        >
                            <Tab eventKey={"cash_balance"} title="Финансов баланс"></Tab>
                            <Tab eventKey={"palet_balance"} title="Баланс палети"></Tab>
                        </Tabs>
                    </div>
                </div>
            </Modal.Header>

            <Modal.Body>
                <div className={"p-3 " + (viewMode === "cash_balance" ? '' : 'd-none')}>
                    <EditClientAccountDetails
                        client={props.client}
                        onReload={props.onReload}
                        onCancel={props.onClose}
                    />
                </div>
                <div className={"p-3 " + (viewMode === "palet_balance" ? '' : 'd-none')}>
                    <EditClientPaletDetails
                        client={props.client}
                        onReload={props.onReload}
                        onCancel={props.onClose}
                    />
                </div>
            </Modal.Body>
        </Modal>
    )
}
